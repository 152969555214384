const ActionTypes = {
  profile: {
    getOne: {
      START: `PROFILE_GET_ONE_API_START`,
      SUCCESS: `PROFILE_GET_ONE_API_SUCCESS`,
      ERROR: `PROFILE_GET_ONE_API_ERROR`,
    },
    search: {
      START: `PROFILE_SEARCH_API_START`,
      SUCCESS: `PROFILE_SEARCH_API_SUCCESS`,
      ERROR: `PROFILE_SEARCH_API_ERROR`,
    },
    getFeatured: {
      START: `PROFILE_GET_FEATURED_API_START`,
      SUCCESS: `PROFILE_GET_FEATURED_API_SUCCESS`,
      ERROR: `PROFILE_GET_FEATURED_API_ERROR`,
    },
    addReview: {
      START: `PROFILE_ADD_REVIEW_API_START`,
      SUCCESS:`PROFILE_ADD_REVIEW_API_SUCCESS`,
      ERROR: `PROFILE_ADD_REVIEW_API_ERROR`,
    }
  },
  settings: {
    get: {
      START: `SETTINGS_GET_API_START`,
      SUCCESS: `SETTINGS_GET_API_SUCCESS`,
      ERROR: `SETTINGS_GET_API_ERROR`,
    },
    put: {
      START: `SETTINGS_PUT_API_START`,
      SUCCESS: `SETTINGS_PUT_API_SUCCESS`,
      ERROR: `SETTINGS_PUT_API_ERROR`,
    }
  },
  entertainerContact: {
    sendEmail: {
      START: `ENT_EMAIL_PUT_API_START`,
      SUCCESS:`ENT_EMAIL_PUT_API_SUCCESS`,
      ERROR: `ENT_EMAIL_PUT_API_ERROR`,
    }
  },
  user: {
    putInit: {
      START: `USER_PUT_INIT_API_START`,
      SUCCESS:`USER_PUT_INIT_API_SUCCESS`,
      ERROR: `USER_PUT_INIT_API_ERROR`,
    },
    getInit: {
      START: `USER_GET_INIT_API_START`,
      SUCCESS:`USER_GET_INIT_API_SUCCESS`,
      ERROR: `USER_GET_INIT_API_ERROR`,
    },
    getSettings: {
      START: `USER_GET_SETTINGS_API_START`,
      SUCCESS:`USER_GET_SETTINGS_API_SUCCESS`,
      ERROR: `USER_GET_SETTINGS_API_ERROR`,
    },
    saveSettings: {
      START: `USER_SAVE_SETTINGS_API_START`,
      SUCCESS:`USER_SAVE_SETTINGS_API_SUCCESS`,
      ERROR: `USER_SAVE_SETTINGS_API_ERROR`,
    },
    setAvatar: {
      START: `USER_SET_AVATAR_API_START`,
      SUCCESS:`USER_SET_AVATAR_API_SUCCESS`,
      ERROR: `USER_SET_AVATAR_API_ERROR`,
    },
    saveImage: {
      START: `USER_SAVE_IMAGE_API_START`,
      SUCCESS:`USER_SAVE_IMAGE_API_SUCCESS`,
      ERROR: `USER_SAVE_IMAGE_API_ERROR`,
    },
    deleteImage: {
      START: `USER_DELETE_IMAGE_API_START`,
      SUCCESS:`USER_DELETE_IMAGE_API_SUCCESS`,
      ERROR: `USER_DELETE_IMAGE_API_ERROR`,
    },
    deleteUser: {
      START: `USER_DELETE_ACCOUNT_API_START`,
      SUCCESS:`USER_DELETE_ACCOUNT_API_SUCCESS`,
      ERROR: `USER_DELETE_ACCOUNT_API_ERROR`,
    },
  },
  auth: {
    login: {
      SUCCESS:`USER_LOGIN_SUCCESS`
    },
    logout: {
      SUCCESS:`USER_LOGOUT_SUCCESS`
    }, 
  },
}

export default ActionTypes;