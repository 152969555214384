import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { colors } from 'theme'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ImageSlot from './imageSlot'
import ActionTypes from 'redux/actionTypes';

const MAX_IMAGES = 3


const _getImages = (props) => {
  if(props.images.length === MAX_IMAGES) return props.images;
  const difference = MAX_IMAGES - props.images.length
  const placeholders = Array.from({length: difference},(v,i)=> {return {url: undefined}});
  const finalArr = props.images.concat(placeholders)
  return finalArr
}


const propTypes = {
  images: PropTypes.array
}

const defaultProps = {
  images: [
    {url: ""},
    {url: ""},
    {url: ""},
    // {url: "https://source.unsplash.com/700x700/?portrait"},
  ]
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex, props) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, index) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: index === 2 ? '0px' : `0 ${grid}px 0 0`,
  width: '200px',
  height: '100px',
  borderRadius: '10px',

  // change background color if dragging
  background: isDragging ? colors.pink : colors.transparent,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? colors.grayLight : colors.white,
  display: 'flex',
  padding: grid,
  overflow: 'auto',
  width: 'auto',
  height: '132px',
  borderRadius: '10px'
});


const ImageGallery = (props) => {
  const { images, onOrderChange } = props;
  const [ items, setItems  ] = useState(_getImages(props));
  const dispatch = useDispatch();


  useEffect(()=>{
    setItems(_getImages(props))
      //eslint-disable-next-line react-hooks/exhaustive-deps 
  },[images])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    onOrderChange && onOrderChange(newItems)

    setItems(newItems)
  }

  const deleteItem = (url) => {

    const cleanItems = items.filter(item=> item.url !== undefined)
    const body = {
      url: url,
      images: cleanItems
    };
    const at = localStorage.getItem("AUTH_USER_TOKEN_KEY")
    dispatch({ at: at, body: body, type: ActionTypes.user.deleteImage.START} )

  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            {items && items.map((item, index) => (
              <Draggable key={item.url || "noimage" + index} isDragDisabled={!item.url} draggableId={item.url || "noimage" + index} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                      index
                    )}
            
                  >
                    <ImageSlot clickClose={(url)=>{
                      const newArr = [];
                       items.map((item)=>{
                        if (item.url === url  || item.url === {} ) return null;
                        return newArr.push(item);
                      });
                      deleteItem(url)
                      newArr.push({})

                      setItems(newArr)
                    }} url={item.url}/>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );

  }


ImageGallery.defaultProps = defaultProps;
ImageGallery.propTypes = propTypes
export default ImageGallery
