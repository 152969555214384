import styled from "styled-components"
import { AspectRatio, TextBlock } from 'components';
import ReactPlayer from "react-player/lazy";
import { colors, gradients } from 'theme'
import { ComingSoonHero } from 'assets';
import { homeData } from './data';
import { withManager } from 'utilities';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import React from "react";

const 
	PADDING_TOP = '64px',
	BORDER_RADIUS = '10px';

const ComingSoonPage = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  max-width: 1160px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 99vw;
  box-sizing: border-box;
  height: 100%;

  @media only screen and (max-width: 780px) {
    padding: 16px;
  }
  @media only screen and (max-width: 500px) {
    padding: 8px;
  }
`;

const LogoWrapper = styled.img`
  max-width: 225px;
  margin: 0 auto;
  margin-bottom: 32px;
`;

const SectionWrapper = styled.div`
  display: flex;
  min-height: 400px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 64px;
`;

const HeroWrapper = styled.div`
  display: flex;
  min-height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const NewsletterWrapper = styled.div`
  padding: ${PADDING_TOP} 0;
  border-radius: ${BORDER_RADIUS};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: flex-end;
  align-items: center;
  background: ${gradients.triColorGradient};
  
  @media only screen and (max-width: 780px) {
    padding: 32px 8px;
  }
`;

const ComingSoonImage = styled.img`
  width: 70%;
  margin: 0 auto;
  margin-bottom: 32px;

  @media only screen and (max-width: 780px) {
    width: 100%;
  }
`;

const MailChimpWrapper = styled.div`
  margin-top: 32px;
  input {
    background: linear-gradient(280deg,rgba(255, 255, 255, .28) 0%, rgba(255, 255, 255, .5) 100%);
    border: none;
    color: white;
    font-size: 16px;
    padding: 12px;
    border-radius: 10px;
    margin-right: 8px;
    outline: none;
    min-width: 250px;
    opacity: .9;
    transition: all .25s ease-in-out;
    min-width: 250px;

    ::-webkit-input-placeholder {
      color: white;
    }
    :-ms-input-placeholder {
      color: white;
    }

    &:hover {
      opacity: 1;
      transform: scale(1.05);
    }
  }

  button {
    border: none;
    font-size: 14px;
    padding: 12px 28px;
    border-radius: 10px;
    background: linear-gradient(140deg,rgba(255, 255, 255, .28) 0%, rgba(255, 255, 255, .5) 100%);
    cursor: pointer;
    font-weight: bold;
    font-family: dunbar-text, Helvetica, sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: all .25s ease-in-out;
    color: ${colors.white};

    &:hover {
      background: linear-gradient(140deg,rgba(255, 255, 255, .38) 0%, rgba(255, 255, 255, .5) 100%);
      transform: scale(1.05);
    }
  }

  @media only screen and (max-width: 436px) {
    text-align: center;
    input {
      margin-bottom: 8px;
      margin-right: 0px;
    }
  }
`;

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <React.Fragment>
      {status === "sending" && <div style={{ color: "white" }}>sending...</div>}
      {status === "error" && (
        <div style={{ color: "white" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div style={{ color: "white" }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <input ref={node => (email = node)} type="email"
        placeholder="email address"
      />
      <button onClick={submit}>
        Submit
      </button>
    </React.Fragment>
  );
};

const ComingSoon = (props, onValidated) => {

  let { hero, newsletter } = homeData;
  let postUrl = `https://tombendermusic.us6.list-manage.com/subscribe/post?u=03515e1fc5452a2d66fbbd708&amp;id=5974820ecd`;

  return (
    <ComingSoonPage>
  
      <SectionWrapper>
        <HeroWrapper>
          <LogoWrapper src="https://images.carecenterstage.com/root/care-center-stage-logo-full-color.svg" alt={'Care Center Stage'}/>
          <ComingSoonImage src={ComingSoonHero} alt={'Care Center Stage'}/>
          <TextBlock
            align="center"
            maxWidth='572px'
            color={colors.navyBlue}
            primitive="h1"
            titleSize='xxlarge'
            title={hero.headerText}
            subtitle={hero.bodyText}/>
        </HeroWrapper>
        <div style={{height: '64px'}}></div>
        <VideoWrapper>
          <AspectRatio>
            <ReactPlayer height="100%" width="100%" url="https://youtu.be/2nrDi1T1hwE" />
          </AspectRatio>
        </VideoWrapper>
       
      </SectionWrapper>
        

      <NewsletterWrapper>
        <TextBlock
          align="center"
          maxWidth='450px'
          color={colors.white}
          primitive="h2"
          titleSize='large'
          title={newsletter.headerText}
          subtitle={newsletter.bodyText}/>
        <MailChimpWrapper>
          <MailchimpSubscribe 
            url={postUrl} 
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}/>
        </MailChimpWrapper>
      </NewsletterWrapper>
    </ComingSoonPage>
  )
}

export default withManager(ComingSoon);