export const constructBody = (inputs, user) => {
  const {
    displayName,
    email,
    travelDistance,
    websiteUrl,
    additionalLink,
    category,
    description,
    equipment,
    pricing,
    requirements,
    location,
    virtual,
    facebookUrl,
    instagramUrl,
    spotifyUrl,
    soundcloudUrl,
    youtubeUrl,
    youtubeVideoUrl,
    other,
    tags,
    twitterUrl,
    images
  } = inputs;

  const _prefixUrl = (url) => {
    if (!url || url.indexOf("http://") > -1 || url.indexOf("https://") > -1) return url;
    else return `https://${url}` ;
  }

  const bodySchema = {
    displayName: displayName && displayName,
    contactEmail: email,
    images: images,
    travelDistance: travelDistance,
    websiteUrl: _prefixUrl(websiteUrl),
    additionalLink: additionalLink,
    categories: category?.toLowerCase().split(),
    description: description,
    details: {
      equipment: equipment,
      pricing: pricing,
      requirements: requirements,
      other: other,
    },
    location: {
      ...location,
      virtual: virtual,
    },
    socialMedia: {
      facebookUrl: _prefixUrl(facebookUrl),
      instagramUrl: _prefixUrl(instagramUrl),
      twitterUrl: _prefixUrl(twitterUrl),
      soundcloudUrl: _prefixUrl(soundcloudUrl),
      youtubeUrl: _prefixUrl(youtubeUrl),
      spotifyUrl: _prefixUrl(spotifyUrl),
      additionalLink: _prefixUrl(additionalLink),
    },
    tags: tags?.split(','),
    youtubeVideoUrl:_prefixUrl(youtubeVideoUrl), 
  };

  const cleanEmpty = obj => {
    if (Array.isArray(obj)) {
      return obj
        .map(v => (v && typeof v === 'object') ? cleanEmpty(v) : v)
        .filter(v => !(v == null));
    } else {
      return Object.entries(obj)
        .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
        .reduce((a, [k, v]) => (v === null || JSON.stringify(v) === "{}" || v === undefined ? a : (a[k] = v, a)), {});
    }
  }

  let cleanedBody = cleanEmpty(bodySchema);

  /**
   * adds proper user attr back into object if its changing anything
   */
  if (cleanedBody.details ) cleanedBody.details = {...user.details, ...cleanedBody.details}
  if (cleanedBody.location ) cleanedBody.location = {...user.location, ...cleanedBody.location}
  if (cleanedBody.socialMedia ) cleanedBody.socialMedia = {...user.socialMedia, ...cleanedBody.socialMedia}

  return cleanedBody

}