import { 
  call, 
  put 
} from "redux-saga/effects";
import ActionTypes from "../actionTypes";

export function* sendEmailToEntertainer (action) {
  try {
    const Item = yield call(() => {
      const { at } = action;
      return global.API.post('ENTERTAINER_CONTACT', {
        headers: {
          accesstoken: at,
          "Content-Type": "application/json;charset=utf-8",
          "Accept": "application/json"
        },
        body: action.body
      })
    });

    yield put({
      type: ActionTypes.entertainerContact.sendEmail.SUCCESS,
      response: Item
    });

  } catch(error) {
      yield put({
      type: ActionTypes.entertainerContact.sendEmail.ERROR,
      error
    });
  }
};