import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyCGgraya3Br6GxH-2-PKgfCGdPivYS13Dg");
Geocode.setLanguage("en");
Geocode.setLocationType("ROOFTOP");



/**
 * 
 * This function takes in address components from the google api response and converts it into city, state and country.
 */

const convertPlaceToAddress = (place) => {
  let city, state, country;

  for (let i = 0; i < place.length; i++) {
    for (let j = 0; j < place[i].types.length; j++) {
      switch (place[i].types[j]) {
        case "locality":
          city = place[i].long_name;
          break;
        case "administrative_area_level_1":
          state = place[i].long_name;
          break;
        case "country":
          country = place[i].long_name;
          break;
          default: break;
      }
    }
  } 
  return {
    city,
    state,
    country,
  }
}


/**
 * 
 * This function takes in a lattitude and longitude number.
 * It then uses googles geocoding api and returns the city, state, country and a formatted address.
 * the formatted address aligns to that of the google places auto complete input so no matter which way the user 
 * chooses to enter their address, it looks the same.
 */

const getAddressFromCoordinates = (lat, long) => Geocode.fromLatLng(lat, long).then(
  (response) => {
    const place = response.results[0].address_components;
    const code = response.plus_code.compound_code
    let address = convertPlaceToAddress(place);
    address.formatted_address = code.slice(code.indexOf(' ') + 1);
    return address
  },
  (error) => {
    console.error(error);
  }
);


const getCoordinatesFromSearch = (search) => Geocode.fromAddress(search).then(
  (response) => {
    const { lat, lng } = response.results[0].geometry.location;
    return {lat, long: lng}
  },
  (error) => {
    console.error(error);
  }
);

export  {
  getCoordinatesFromSearch,
  getAddressFromCoordinates,
  convertPlaceToAddress
}