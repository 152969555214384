import { useState, useRef } from 'react';
import styled from "styled-components";
// import PropTypes from "prop-types";
import { PillButton } from "components";
import { colors } from "theme";

const propTypes = {
};

const defaultProps = {
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const ImagePreview = styled.div`
  height: 96px;
  width: 170px;
  border-radius: 10px;
  background-image: url(${({ src }) => src});
	background-color: ${colors.grayLight};
  background-size: cover;
	margin-bottom: 8px;
  transition: all 0.3s ease-in;
`;

const ImageUpload = (props) => {
	const { initialSrc } = props;
	const [upImg, setUpImg] = useState();
	const [previewImage, setPreviewImage] = useState();
	const hiddenFileInput = useRef(null);

	const _handleImageChange = (e) => {
		const { onChange } = props;
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
			setUpImg(e.target.files[0]);
			setPreviewImage(reader.result);
    }
    reader.readAsDataURL(file)
		if (onChange) onChange(upImg)
  }

	const _handleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
		<Wrapper>
			<ImagePreview
				{...props}
				src={previewImage ?? initialSrc }>
			</ImagePreview>
			<input 
				type="file" 
				id="fileItem" 
				accept="image/*" 
				style={{display: 'none'}}
				ref={hiddenFileInput}
				onChange={_handleImageChange} />
			<PillButton
				size='small'
				label="Upload an image"
				onClick={_handleClick}/>
		</Wrapper>
	);
};

ImageUpload.propTypes = propTypes;
ImageUpload.defaultProps = defaultProps;

export default ImageUpload;
