import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Avatar, Text, Body, RatingBadge, TextLinkArrow } from 'components';
import { colors } from 'theme';
import { abbrRegion } from './stateAbbreviations'
import { useHistory } from 'react-router';
import { withManager } from "utilities";

const propTypes = {
    imageSource: PropTypes.string,
    name: PropTypes.string,
    rating: PropTypes.number,
    onTextArrowClick: PropTypes.func
};

const defaultProps = {};

const ContentWrapper = styled.div`
    background-color: white;
    height: 323px;
    width: 376px;
    border-radius: 10px;
    box-shadow: 0px 12px 24px ${colors.navyBlue}30;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all .3s ease-in;
    cursor: pointer;
`;

const AvatarWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1;
    transition: all .3s ease-in;
    cursor: pointer;
`;

const HoverRevealBG = styled.div`
    position: absolute;
    width: 100%;
    height: 40px;
    z-index: -1;
    bottom: 0;
    border-radius: 10px;
    left: 0;
    background-color: ${colors.pink};
`;

const ComponentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 376px;
    height: 396px;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    &:hover {
        ${ContentWrapper} {
            transform: translateY(-6px);
            box-shadow: 0px 24px 48px ${colors.navyBlue}25;
        }
        ${AvatarWrapper} div {
            box-shadow: 0px 12px 24px ${colors.navyBlue}25;
            transform: translateY(-14px);
        }
    }
`;

const Wrapper = styled.div`
    position: relative;
    ${({ viewport }) => viewport === 'mobile' && 
        `transform: scale(.95);`}
`;

const BodyWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
`;

const TextWrapper = styled.div`
    padding-bottom: ${({ paddingBottom }) => paddingBottom ?? 4}px;
    padding-right: ${({ paddingRight }) => paddingRight}px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const CategoryWrapper = styled.span`
    display: flex;
    padding-left: ${({ paddingLeft }) => paddingLeft}px;
`;

const _renderTags = tags => <Body size="small" color={colors.gray}>{tags.join(", ")}</Body>;

const ProfileCard = props => {
    const {
        imageSource,
        name,
        displayName,
        categories,
        location,
        tags,
        rating,
        id,
        virtual,
        viewport
    } = props;
    const history = useHistory();

    const _goToProfile = () => {
        history.push(`./${id}`)
    }

    const _renderCategories = () => {
        categories?.length > 1 ? categories?.join(", ").toLowerCase() : categories?.[0].toLowerCase()

       let newCategories = categories.map((cat)=> cat.charAt(0).toUpperCase() + cat.slice(1))

       return newCategories.length === 1 ? newCategories[0] : newCategories.join(", ")
        
    }

    return (
        <Wrapper viewport={viewport} onClick={_goToProfile}>
            <ComponentWrapper>
                <AvatarWrapper>
                    <Avatar src={imageSource} />
                </AvatarWrapper>
                <ContentWrapper>
                    <BodyWrapper>
                        <TextWrapper>
                            <Text size="small" bold color={colors.navyBlue}>{displayName ?? name}</Text>
                        </TextWrapper>
                        <CategoryWrapper>
                            <Text size="xxsmall" bold color={colors.pink}>{_renderCategories()}</Text>
                        </CategoryWrapper>
                        <TextWrapper>
                        {location.city && <div style={{ marginRight: '4px' }}>
                                <Body size="small" color={colors.navyBlue}>{`${location.city}, ${abbrRegion(location.state, 'abbr') ?? location.state}`}</Body>
                            </div>}
                            {virtual &&
                                <div style={{ display: 'flex' }}>
                                    {location.city && 
                                        <div style={{ marginRight: '4px' }}>
                                            <Body size="small" color={colors.babyBlue}>+</Body>
                                        </div>
                                    }
                                    <Body size="small" color={colors.navyBlue}>Virtual Availability </Body>
                                    {!location.city && <Body size="small" color={colors.navyBlue}>&nbsp;Only</Body>}
                                </div>}
                        </TextWrapper>
                        {tags && <TextWrapper paddingBottom={16}>
                            {_renderTags(tags)}
                        </TextWrapper>}
                        <TextWrapper>
                            <RatingBadge rating={rating} />
                        </TextWrapper>
                    </BodyWrapper>
                    <TextLinkArrow
                        color={colors.pink}
                        label="VIEW PROFILE"
                    // onClick={_goToProfile}
                    />
                </ContentWrapper>
            </ComponentWrapper>
            <HoverRevealBG />
        </Wrapper>
    )
}

ProfileCard.propTypes = propTypes;
ProfileCard.defaultProps = defaultProps;

export default withManager(ProfileCard);