import styled from "styled-components";
import PropTypes from "prop-types";
import { TextBlock } from "components";
import { colors } from "theme";
import { withManager } from 'utilities';

const propTypes = {
  imageSource: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  price: PropTypes.string,
  onLinkClick: PropTypes.func,
  onButtonClick: PropTypes.func,
};

const defaultProps = {};

const ContentWrapper = styled.div`
  background-color: ${colors.white};
  height: ${({ viewport }) => viewport !== 'desktop' ? 'auto' : '386px'};
  border-radius: 10px;
  box-shadow: 0px 12px 24px ${colors.navyBlue}30;
  padding: ${({ viewport }) => viewport !== 'desktop' ? '16px' : '32px'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in;
	align-items: center;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100px;
  z-index: 1;
	border-radius: 50%;
  transition: all 0.3s ease-in;
`;

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
  max-width: ${({ viewport }) => viewport !== 'desktop' ? '100%' : '311px'};
`;

const TextWrapper = styled.div`
  padding-bottom: ${({ paddingBottom }) => paddingBottom ?? 4}px;
  display: flex;
  align-items: center;
	flex-direction: column;
	justify-content: center;
  padding: 16px 0 16px 0;
`;

const SignUpCTA = (props) => {
  const {
    careCenter,
    title,
    subtitle,
    viewport,
    icon
  } = props;

	const IconChange = icon;
	let ctaId = careCenter ? 'careCenterCta' : 'entertainerCta';

	return (
    <Wrapper viewport={viewport} id={ctaId}>
        <ContentWrapper viewport={viewport}>
          <IconWrapper>
            {icon && <IconChange/>}
          </IconWrapper>
					<TextWrapper viewport={viewport}>
						<TextBlock
						align="center"
						maxWidth='572px'
						color={careCenter ? colors.babyBlue : colors.pink}
						primitive="h2"
						titleSize='small'
						title={title}
						subtitle={subtitle}/>
					</TextWrapper>
        </ContentWrapper>
    </Wrapper>
  );
};

SignUpCTA.propTypes = propTypes;
SignUpCTA.defaultProps = defaultProps;

export default withManager(SignUpCTA);
