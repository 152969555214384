

const profileLinkData = [
    {
        label: "Photos",
        href: "#photos"
    },
    {
        label: "About",
        href: "#about"
    },
    {
        label: "Details",
        href: "#details"
    },
    {
        label: "Video",
        href: "#video"
    },
    {
        label: "Reviews",
        href: "#reviews"
    },
    {
        label: "Contact",
        href: "#contact"
    },
];

const user =
{
    name: 'Tyler Siskar',
    displayName: 'Tyler Siskar',
    avatarUrl: 'https://ca.slack-edge.com/TBUNR2G8P-UPJPD81C0-4a978aaa9924-512',
    categories: ['Musician'],
    location: 'Washington, D.C.',
    zipcode: '20002',
    miles: 20,
    tags: ['hip-hop', 'mumble rap'],
    email: "tyler.siskar@gmail.com",
    // phoneNumber: "7165231047",
    website: "www.tylersiskar.com",
    additionalLink: "www.linkedin.com/in/tylersiskar",
    images: [
        { url: "https://images.carecenterstage.com/root/care-center-stage-entertainer-gabriel-gurrola-unsplash.jpg" }, // was called DefaultHero
        { url: "https://images.carecenterstage.com/root/care-center-stage-entertainer-gabriel-gurrola-unsplash.jpg" }
    ],
    socialMedia: {
        facebook: 'http://www.facebook.com',
        instagram: ' http://www.instagram.com',
        twitter: 'http://www.twitter.com',
        youtube: 'http://www.youtube.com',
        soundcloud: 'http://www.soundcloud.com',
        spotify: 'http://www.spotify.com',
    },
    description: "Skateboard Tumblr wolf blog butcher live-edge ennui tattooed. Chambray lumbersexual meditation gluten-free, cred hashtag irony offal crucifix twee gastropub. Irony try-hard distillery, man braid man bun iceland kitsch post-ironic. Organic DIY Brooklyn actually, vexillologist listicle sriracha meggings. Air plant wayfarers squid biodiesel literally stumptown kinfolk cray. Kinfolk beard tilde raw denim intelligentsia poutine. Organic waistcoat paleo mumblecore mixtape, schlitz selfies quinoa plaid meh hammock. Tofu bicycle rights letterpress artisan kinfolk four dollar toast air plant prism butcher cornhole.Skateboard Tumblr wolf blog butcher live-edge ennui tattooed. Chambray lumbersexual meditation gluten-free, cred hashtag irony offal crucifix twee gastropub. Irony try-hard distillery, man braid man bun iceland kitsch post-ironic. Organic DIY Brooklyn actually, vexillologist listicle sriracha meggings.",
    details: {
        businessAttributes: "Air plant wayfarers squid biodiesel.",
        equipment: "Guitar, Amp, Microphone",
        requirements: "Power",
        pricing: "$130 / hour"
    },
    rating: {
        overall: 4.6,
        reviews: [
            {
                rating: 4.1,
                review: 'Tofu bicycle rights letterpress artisan kinfolk four dollar toast air plant prism butcher cornhole.',
                date: '9/08/2021',
                reviewer: {
                    name: 'Care Center Stage',
                    email: '',
                },

            },
            {
                rating: 5,
                review: 'Chambray lumbersexual meditation gluten-free, cred hashtag irony offal crucifix twee gastropub. Irony try-hard distillery, man braid man bun iceland kitsch post-ironic. Organic DIY Brooklyn actually, vexillologist listicle sriracha meggings.',
                date: '9/08/2021',
                reviewer: {
                    name: 'Care Center Stage',
                    email: '',
                },

            },
        ],
    }
};
export { profileLinkData, user };