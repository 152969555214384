import { createStore, applyMiddleware } from 'redux';
import rootReducer from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import { watcherSaga } from './sagas';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  {},
  applyMiddleware(sagaMiddleware)
);
const persistor = persistStore(store);

// persistor.purge();

sagaMiddleware.run(watcherSaga);

export { store, persistor };