import { useEffect } from "react";
import styled from "styled-components"
import { PillButton, Text, TextBlock, TextLink, Loader } from 'components';
import { colors, gradients } from 'theme'
import { SettingsNav, MembershipCard, BillingInfoCard } from 'modules';
import { entertainerSectionData, careCenterSectionData } from './data';
import { withManager } from 'utilities';
import { useSelector, 
  // useDispatch
 } from 'react-redux';
// import ActionTypes from 'redux/actionTypes'


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`;

const PageWrapper = styled.div`
  display: flex;
  width: ${({ viewport }) =>  viewport === 'desktop' ? 'calc(100vw - 32px)' : viewport === 'tablet' ? 'calc(100vw - 16px)' : 'calc(100vw - 8px)'};
  margin: 4px 0 8px 0;
  border-radius: 10px;
  align-items: flex-start;
  background: ${({ isEntertainer }) => isEntertainer ? gradients.blueGradient : gradients.lightBlueGradient};
  height: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: ${({ viewport }) => viewport !== 'desktop' ? '80px' : '64px'} 16px 64px 16px;
  flex-direction: ${({ viewport }) => viewport !== 'desktop' ? 'column' : 'row'};
  max-width: 1440px;
  margin: auto;
  min-height: 80vh;
`;

const NavColumn = styled.div`
  display: flex;
  align-items: flex-start;
  width: ${({ viewport }) => viewport !== 'desktop' ? '100%' : '25%'};
  margin-top: ${({ viewport }) => viewport !== 'desktop' ? '-64px' : '0'};
`;

const NavFixWrapper = styled.div`
  position: ${({ viewport }) => viewport !== 'desktop' ? 'fixed' : 'sticky'};
  top: ${({ viewport }) => viewport !== 'desktop' ? null : '157px'};
  z-index: 99;
  display: flex;
  width: ${({ viewport }) => viewport !== 'desktop' ? '100%' : null};
  flex-direction: ${({ viewport }) => viewport !== 'desktop' ? 'row' : 'column'};
`;

const ContentColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: ${({ viewport }) => viewport !== 'desktop' ? '100%' : '75%'};;
  padding-left: ${({ viewport }) => viewport !== 'desktop' ? '0' : '16px'};
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  background-color: ${colors.white};
  padding: ${({ viewport }) => viewport !== 'desktop' ? '16px' : '32px'};
  margin-bottom: 16px;
  border-radius: 10px;

  h5 {
    margin-bottom: 8px;
  }
`;

const Spacer = styled.div`
  display: flex;
  width: 100%;
  height: ${({ height }) => height ?? '32px'};
  border-top: ${({ border }) => border ? '1px solid ' + colors.grayLight : 'transparent'};
`;


const BillingSettings = (props) => {
  const { viewport, authenticated } = props;
  // const dispatch = useDispatch();
  // this is so we don't have to change max-width for Half-Wrapper

  const { user, fetching } = useSelector (({user})=> {
    return {
      user: user.data,
      fetching: user.fetching
    }
  })
  let isEntertainer = user?.group === "entertainers" || authenticated?.user?.attributes['custom:group'] === 'entertainers';

  const _scrollToTop = () => {
    window.scrollTo({
      top: 0,})
  }

  useEffect(_scrollToTop)

  const  subscriptionName = 'Entertainer (Yearly)', subscriptionPrice = '$99.99', subscriptionTerm = '/year';

  const _renderBillingSettings = () => (
    <ContentColumn viewport={viewport}>
      {viewport !== 'desktop' && <Spacer height='64px' />}
      <TextBlock
        maxWidth='572px'
        color={colors.white}
        primitive="h2"
        titleSize='xlarge'
        title='Billing & Membership'
        subtitle='Review your billing and membership information below.' />

      <Spacer height='32px' />

      <SectionWrapper viewport={viewport}>
        <Text bold primitive='h5' size="xsmall" color={colors.navyBlue}>Current Membership</Text>
        <MembershipCard
          subscriptionName={subscriptionName}
          subscriptionPrice={subscriptionPrice}
          subscriptionTerm={subscriptionTerm}/>
        <TextLink label="Cancel Membership" onClick={() => {}}/>
        <Spacer height="64px"/>

        <Text bold primitive='h5' size="xsmall" color={colors.navyBlue}>Billing Information</Text>
        <BillingInfoCard
          creditCardType="Visa"
          creditCardNumber="****7419"
          price="10.89"
          subscriptionTerm={subscriptionTerm}
          nextPaymentDate="Next payment scheduled on December 1, 2022"
        />
        <TextLink label="Edit" onClick={() => {}}/>

        <Spacer height="32px"/>
        <PillButton onClick={() => {}} color={colors.pink} size='large' label='Update' />
      </SectionWrapper>
    </ContentColumn>
  );



  let newData = isEntertainer ? entertainerSectionData : careCenterSectionData;

  return (
    <Wrapper>
      <Loader visible={fetching} />
      <PageWrapper viewport={viewport} isEntertainer={isEntertainer}>
        <InnerWrapper viewport={viewport}>

          <NavColumn viewport={viewport}>
            <NavFixWrapper viewport={viewport}>
              <SettingsNav entertainer={isEntertainer} data={newData} />
              <div style={{ marginLeft: viewport !== 'desktop' ? '16px' : null }}>
                <PillButton onClick={() => {}} color={colors.pink} size='large' label='Update' />
              </div>
            </NavFixWrapper>

          </NavColumn>
            {_renderBillingSettings()}
        </InnerWrapper>
      </PageWrapper>
    </Wrapper>

  )
}

export default withManager(BillingSettings);