import styled from "styled-components";
import PropTypes from "prop-types";
import { Text, Body } from "components";
import { colors } from "theme";
import { withManager } from 'utilities';

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
};

const defaultProps = {};

const ContentWrapper = styled.div`
  max-width: 464px;
  display: flex;
	align-items: center;
  box-shadow: 0px 5px 12px ${colors.navyBlue}30;
  padding: 16px;
  border-radius: 10px;
  background-color: ${colors.white};
  margin-bottom: 16px;
  transition: all .3s ease;
  background-color: ${colors.white};
  padding: 16px;
/* 
  &:hover {
    opacity: 1;
    box-shadow: 0px 12px 24px ${colors.navyBlue}15;
  } */
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  display: flex;
	flex-direction: column;
`;

const MembershipCard = (props) => {
  const {
    subscriptionName,
    subscriptionPrice,
    subscriptionTerm,
		viewport,
    onClick,
    selected
  } = props;


	return (
      <ContentWrapper 
        selected={selected}
        viewport={viewport} 
        onClick={onClick}>
        <TextWrapper>
          <Text
            bold
            color={colors.pink}
            primitive="h2"
            size='small'>{subscriptionName}
            </Text>
            <PriceWrapper>
              <Body color={selected ? colors.white: null}>
                {subscriptionPrice}
              </Body>
              <Body color={selected ? colors.white: null}>
                {subscriptionTerm}
              </Body> 
            </PriceWrapper>
            
        </TextWrapper>
      </ContentWrapper>
  );
};

MembershipCard.propTypes = propTypes;
MembershipCard.defaultProps = defaultProps;

export default withManager(MembershipCard);
