let navProfileEntertainerData = [
  {
    title: 'View Profile',
    href: '#'
  },
  {
    title: 'Profile Settings',
    href: '/settings'
  },
  {
    title: 'Account Settings',
    href: '/account-settings'
  },
  // {
  //   title: 'Billing & Membership',
  //   href: '/billing'
  // },
]

let navProfileCareCenterData = [
  {
    title: 'Profile Settings',
    href: '/settings'
  },
  {
    title: 'Account Settings',
    href: '/account-settings'
  }
]

export { navProfileEntertainerData, navProfileCareCenterData };
