import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom' 
import { Provider } from 'react-redux'
import { store } from './redux/store'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { 
  Http,
  API_ROUTE,
  API_CONFIG
} from 'api'

global.API = new Http();
global.API.initialize({
  baseURL: API_ROUTE,
  APIRoutes: API_CONFIG,
  // options: {
  //   authHeaders: { accesstoken: `${localStorage.getItem('AUTH_USER_TOKEN_KEY')}` }
  //   }
})

ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter getUserConfirmation={() => {
          /* Empty callback to block the default browser prompt */
        }}>
    <App />
  </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
