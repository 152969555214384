
import {
  Route,
  Redirect
} from 'react-router-dom';

export const AuthenticatedRoute = ({render, ...rest})=> {
  const authenticated = localStorage.getItem('AUTH_USER_TOKEN_KEY')
  const _render = () => authenticated ? render() : <Redirect to='/' />
  return (
    <Route
      {...rest}
      render={_render} />
  )
}

