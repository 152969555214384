import styled from "styled-components"
import { useEffect } from 'react'
import { TextBlock, Markdown } from 'components';
import { colors } from 'theme'
import { aboutData, markdownText } from './data';
import { withManager } from 'utilities';

const AboutPage = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  max-width: 1160px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 720px;
  box-sizing: border-box;
  height: 100%;
`;

const SectionWrapper = styled.div`
  display: flex;
  min-height: 400px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 64px;
`;

const HeroWrapper = styled.div`
  display: flex;
  min-height: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AboutImage = styled.img`
  width: 70%;
  margin: 0 auto;
  margin-bottom: 32px;
  border-radius: 10px;

  @media only screen and (max-width: 780px) {
    width: 90%;
  }
`;

const About = (props) => {
  let { hero } = aboutData;
   // start page at top
   const _scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(_scrollToTop);

  return (
    <AboutPage>
      <SectionWrapper>
        <HeroWrapper>
          <AboutImage src="https://images.carecenterstage.com/root/care-center-stage-entertainer-gabriel-gurrola-unsplash.jpg" alt={'Care Center Stage'}/>
          <TextBlock
            align="center"
            maxWidth='822px'
            color={colors.babyBlue}
            primitive="h1"
            titleSize='xlarge'
            title={hero.headerText}
            subtitle={hero.bodyText}/>
        </HeroWrapper>
      </SectionWrapper>

      <Markdown children={markdownText}/>
    </AboutPage>
  )
}

export default withManager(About);