import styled from 'styled-components'
import ReactPlayer from "react-player/lazy";
import { AspectRatio } from 'components';

const VideoWrapper = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
`;


const Video = (props) => {
  const { children, url, id } = props;

  return (
    <VideoWrapper id={id}>
      <AspectRatio>
        <ReactPlayer height="100%" width="100%" url={url} />{children}
      </AspectRatio>
    </VideoWrapper>
  )
};

export default Video;