import { useEffect } from 'react';
import styled from 'styled-components'
import Portal from './portal'
import { Icon } from '../icons'
import { Text, Body } from '../typography'
import { colors } from 'theme'

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
`;

const ModalWrapper = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  padding: ${({ viewport }) => viewport !== 'desktop' ? '16px' : '32px'};
  width: ${({ width }) => width ?? '620px'};
  min-height: 250px;
  box-sizing: border-box;
  overflow-y: ${({noOverflowY}) => noOverflowY ? 'null' : 'scroll'}; // this should happen automatically if the modal height is close to the viewport height
  max-height: 90vh;
  ${({ fullscreen }) =>
    fullscreen &&
    `
  width: 100%;
  height: 100%;
  `
  }
  /* pointer-events: none; */
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    padding-bottom: 8px;
  }
`;

const HeaderWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`

const Modal = (props) => {
  const { 
    children, 
    id, 
    open = false, 
    disableClickExit, 
    onClose, 
    fullscreen, 
    title, 
    subtitle, 
    width, 
    hideClose, 
    titleColor, 
    viewport,
    align,
    noOverflowY
 } = props;
  const _stopPropagation = (e) => e.stopPropagation()

  useEffect(() => {
    //prevents scrolling underneath for mobile & desktop browsers
    if (open) {
      document.body.style.overflow = 'hidden';
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      //resets scrolling prevent styles
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.overflow = '';
      window.scrollTo(0, 
      parseInt(scrollY || '0') * -1);
    }
  }, [open]);

  const _renderModalHeader = () => {
    return (
      <div>
        <HeaderWrapper>
          <TitleWrapper>
            <Text align={align} size="medium" primitive="h3" bold color={titleColor ?? colors.navyBlue}>{title}</Text>
            {subtitle && <Body align={align}>{subtitle}</Body>}
          </TitleWrapper>
          {!hideClose && <div style={{ cursor: 'pointer' }} onClick={onClose}><Icon size="large" name="close" color={colors.pink} /></div>}
        </HeaderWrapper>
      </div>
    )
  }
  let modalId;
  if (id) modalId = id;
  else modalId = 'modal'
  return (
    <Portal
      portalId={modalId}>
      {open &&
        <Wrapper>
          <Overlay 
          // onMouseDown={onClose}
          >
            <ModalWrapper
              noOverflowY={noOverflowY}
              viewport={viewport}
              width={width}
              fullscreen={fullscreen}
              onMouseDown={disableClickExit ? null : _stopPropagation}>
              {_renderModalHeader()}
              {children}
            </ModalWrapper>
          </Overlay>
        </Wrapper>}
    </Portal>
  )
};

export default Modal;