import { useState, useRef, useCallback , useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import ReactCrop from "react-image-crop";
import { Body, Modal, PillButton, SmallLoader, TextLink } from "components";
import "react-image-crop/dist/ReactCrop.css";
import ActionTypes from 'redux/actionTypes';
import { useDispatch } from 'react-redux';
import { colors } from 'theme';

import Compressor from 'compressorjs';

const propTypes = {
  type: PropTypes.oneOf([
    "avatar", 
    "image"
  ]),
};

const defaultProps = {
  type: "image"
}

const Wrapper = styled.div`
  padding: 32px;
`;

const ButtonWrapper = styled.div`
  position: sticky;
  bottom: 32px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  max-width: 250px;
`;

const ImageCropper = (props) => {
  const { onClose, avatarUrl, type} = props;
  const avatar = type === "avatar"
  const [crop, setCrop] = useState({ width: avatar ? 150 : 266.66, height: 150, x: 0, y: 0, aspect: avatar ? 1/1  : 16/9}); 
  const [completedCrop, setCompletedCrop] = useState({ width: avatar ? 150 : 266.66, height: 150, x: 0, y: 0, aspect: avatar ? 1/1  : 16/9});
  const [src, setSrc] = useState();
  const [uploadedFile, setUploadedFile] = useState();
  const [blob, setBlob] = useState(avatarUrl);
  const imgRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const [showImageCropperModal, setShowImageCropperModal] = useState();
  const [uploading, setUploading] = useState();
  const [error, setError] = useState();
  const canvas = document.createElement('canvas');
  const dispatch = useDispatch();


  useEffect(()=>{
    if (!completedCrop || !blob || !imgRef.current) return;
    const image = imgRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    canvas.borderRadius = "50%";
    let width = completedCrop.width * pixelRatio * scaleX;
    let height = completedCrop.height * pixelRatio *  scaleY;

    canvas.width = width;
    canvas.height = height;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    canvas.toBlob(
      (blob) => {
        setBlob(blob)
        if (!blob) return
      },
      'image/jpeg',
      1
    );
    //eslint-disable-next-line
  },[completedCrop])

  const _onCompletedCrop = (c) => {
    setCompletedCrop(c)
    hiddenFileInput.current.value = '';
  };
  const _onCropChange = (c) => {
    setCrop(c);
  }

  const _resize = async (dataURI) => {
    const tempImage = new Image();
    tempImage.src = dataURI
    const ctx = canvas.getContext("2d");
    const MAX_WIDTH = 964;
    const MAX_HEIGHT = 964;

    tempImage.onload = () => {
      let width = tempImage.width;
      let height = tempImage.height;
      if (width > height) {
          if (width > MAX_WIDTH) {
              height = height * (MAX_WIDTH / width);
              width = MAX_WIDTH;
          }
      } else {
          if (height > MAX_HEIGHT) {
              width = width * (MAX_HEIGHT / height);
              height = MAX_HEIGHT;
          }
      }
      canvas.height = height;
      canvas.width = width;
      ctx.drawImage(tempImage, 0, 0, canvas.width, canvas.height);
      const croppedFileUrl = canvas.toDataURL('image/jpeg')
      setSrc(croppedFileUrl)
      canvas.toBlob(
        (blob) => {
          setBlob(blob)
          if (!blob) return
        },
        'image/jpeg',
        1
      );
      setShowImageCropperModal(true)
    }
  }

  const _onSelectFile = async (e) => {
    setUploading(true)
    setUploadedFile(e.target.files[0]);
    // file needs to be JPEG
    if (e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png') return _handleError();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => _resize(reader.result);
      setError(false)
    }
    // _onCropAndSave()
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const _onClose = () => {
    onClose && onClose()
    setShowImageCropperModal(false)
    hiddenFileInput.current.value = '';
    setUploading(false)
  }

  const _onCropAndSave = async () => {
    new Compressor(blob, {      
      quality: 0.4,
      success: (compressedResult) => {
        const file = new File([compressedResult], 'image.jpeg', { type: 'image/jpeg' });
        const formdata = new FormData();
        const dataType = avatar ? 'avatar' : 'image'
        formdata.append(dataType, file);
        const at = localStorage.getItem('AUTH_USER_TOKEN_KEY')
        avatar && dispatch({at, body: formdata, type: ActionTypes.user.setAvatar.START});
        type === 'image' && dispatch({at, body: formdata, type: ActionTypes.user.saveImage.START})
        onClose && onClose();
        setShowImageCropperModal(false)
        setUploading(false)
      },
    });    
}

  const _noCropAndSave = async () => {
    new Compressor(uploadedFile, {      
      quality: 0.4,
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        // setCompressedFile(res)
        const file = new File([compressedResult], 'image.jpeg', { type: 'image/jpeg' });
        const formdata = new FormData();
        formdata.append('image', file);
        const at = localStorage.getItem('AUTH_USER_TOKEN_KEY')
        dispatch({at, body: formdata, type: ActionTypes.user.saveImage.START})
        onClose && onClose();
        setShowImageCropperModal(false)
        setUploading(false)
      },
    });    
  }

  const _handleError = () => {
    setUploading(false)
    setError(true)
}

  const handleNewFileClick = event => {
    hiddenFileInput.current.click();
  };

  const _renderModal = () => {
    return (
      <Modal 
        title={`Crop your ${avatar ? 'avatar' : 'photo'}.`}
        width='474px'
        open={showImageCropperModal} 
        onClose={_onClose}>
        <Wrapper>
          <ReactCrop 
            {...props}
            keepSelection
            maxWidth={964} 
            circularCrop={avatar}
            src={src}
            crop={crop} 
            onChange={_onCropChange}
            onImageLoaded={onLoad}
            onComplete={_onCompletedCrop}
            open={showImageCropperModal}
            onClose={_onClose}/>
          <ButtonWrapper>
          {type !== 'avatar' && 
            <TextLink
              label="Upload Image without Cropping"
              onClick={_noCropAndSave}/>
          }
          <br/>
          <PillButton
            label="Crop & Save"
            onClick={_onCropAndSave}/>
          
        </ButtonWrapper>
        </Wrapper>
      </Modal>
    )
    };

  return (
    <>
        <input 
          type="file"
          ref={hiddenFileInput}
          onChange={_onSelectFile}
          style={{display:'none'}}/>
        <ErrorWrapper>
          {uploading ?
            <SmallLoader visible={uploading}/>
            : <PillButton
              disabled={uploading}
              size="small"
              label="Upload an image"
              onClick={handleNewFileClick}/>
          }
          {error &&
            <Body size='xsmall' color={colors.error}>Error: Please make sure your image is a JPEG or PNG and try again.</Body>
          }
        </ErrorWrapper>
      {_renderModal()}
      
      </>
  );
};

ImageCropper.defaultProps = defaultProps;
ImageCropper.propTypes = propTypes;
export default ImageCropper;