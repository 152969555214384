import styled from "styled-components";
import { withManager } from "utilities";
import { colors } from "theme";
import { Icon, Text, TextLink } from "components";
import { linkData, authLinkData, iconData } from './data';
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 240px;
  background-color: ${colors.navyBlue};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${({ viewport }) => (viewport === "mobile" ? 0 : 12)}px;
  padding-bottom: ${({ viewport }) => (viewport !== "desktop" ? 16 : 0)}px;
  &:last-child {
    padding-right: 0px;
  }
`;

const TextWrapper = styled.div`
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;
  padding-right: ${({ paddingRight }) => paddingRight ?? 0}px;
  padding-left: ${({ paddingLeft }) => paddingLeft ?? 0}px;
`;

const UpperWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1160px;
  min-height: 190px;
  box-sizing: border-box;
  padding: ${({ viewport }) => viewport !== 'desktop' ? '32px' : '64px 32px'};
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  padding-right: 32px;
  ${({ viewport, bottom }) =>
    viewport !== "desktop" &&
    `
        flex-basis: 100%;
        padding-right: 0px;
        padding-bottom: ${bottom ? 0 : 32}px;
    `};
`;
const RightWrapper = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
  flex-wrap: wrap;
  ${({ viewport, bottom }) =>
    viewport !== "desktop" &&
    `
        flex-basis: 100%;
        padding-left: 0px;
        padding-bottom: ${bottom ? 32 : 0}px;
    `};
  ${({ bottom, viewport }) =>
    bottom &&
    `
        flex-basis: 100%;
        padding-left: 0;
        justify-content: flex-end;
        flex-direction: ${viewport === "desktop" ? "row" : "column-reverse"};
    `};
`;

const MailChimpWrapper = styled.div`
  margin-top: 32px;
  input {
    background: rgba(255, 255, 255, 0);
    border: 2px solid white;
    color: white;
    font-size: 16px;
    padding: 12px;
    border-radius: 10px;
    margin-right: 8px;
    outline: none;
    min-width: 250px;
    opacity: .75;
    transition: all 300ms ease-in-out;

    ::-webkit-input-placeholder {
      color: white;
    }
    :-ms-input-placeholder {
      color: white;
    }

    &:hover {
      opacity: 1;
      transform: scale(1.05);
    }

    &:focus {
      border: 2px solid white;
      opacity: 1;
      transform: scale(1.05);
    }
  }

  button {
    margin-top: 8px;
    border: none;
    font-size: 14px;
    padding: 12px 28px;
    border-radius: 10px;
    background: ${colors.pink};
    cursor: pointer;
    font-weight: bold;
    font-family: dunbar-text, Helvetica, sans-serif;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: all .25s ease-in-out;
    color: ${colors.white};

    &:hover {
      background: linear-gradient(140deg,rgba(255, 255, 255, .38) 0%, rgba(255, 255, 255, .5) 100%);
      transform: scale(1.05);
    }
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${colors.babyBlue};
  white-space: nowrap;
  box-sizing: border-box;
  justify-content: center;
`;

const BottomContent = styled.div`
  display: flex;
  align-items: center;
  min-height: 50px;
  width: 100%;
  background-color: ${colors.babyBlue};
  white-space: nowrap;
  max-width: 1160px;
  padding: 16px 32px;
  box-sizing: border-box;
  justify-content: space-between;
  ${({ viewport }) =>
    viewport !== "desktop" &&
    `
        flex-direction: column-reverse;
    `};
`;

const Title = styled.h5`
  margin: 0;
  font-size: 21px;
  line-height: 40px;
  font-weight: bold;
  font-family: dunbar-tall, Helvetica, sans-serif;
  color: ${colors.white};
`;

const FlexWrapper = styled.div`
  display: flex;
`;

const InputWrapper = styled.div`
  width: 261px;
  padding-right: 6px;
`;

const TermsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 36px;
  ${({ viewport }) =>
    viewport !== "desktop" &&
    `
        padding-right: 0;
    `};
`;

const IconWrapper = styled.a`
  text-decoration: none;
  padding-right: 30px;
  &:hover {
    svg {
      fill: ${colors.navyBlue}
    }
  }
  &:last-child {
    padding-right: 0px;
  }
  
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${({ viewport }) => (viewport !== "desktop" ? 16 : 0)}px;
`;

const Subtitle = styled(Text)`
  font-size: 16px;
  max-width: 294px;
  opacity: 0.63;
`;

const ButtonWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: flex-start;
`;


const Footer = (props) => {
  const history = useHistory();
  const { viewport, authenticated, openAuthenticationModal } = props;

  const openSignInModal = () => openAuthenticationModal('signIn');
  const _signOut = () => {
    Auth.signOut().then(() => {
      history.go(0)
    })
  };
  const _handleLinkClick = (linkProps) => {
    if (linkProps.text === 'Sign In') return openSignInModal;
    if (linkProps.text === 'Sign Out') return _signOut;
    return () => history.push(linkProps.href);
  }

  const renderLink = (linkProps) => {
    return (
      <TextLink
        label={linkProps.text}
        color={colors.white}
        onClick={_handleLinkClick(linkProps)}
        href={linkProps.href} />
    );
  };

  const _renderLinks = (group) => {
    return group.map((link, i) => {
      return (
        <TextWrapper key={i} paddingBottom={10}>
          {renderLink({ ...link })}
        </TextWrapper>
      );
    });
  };

  const _renderLinkSections = (data) => {
    return data?.map((group, index) => {
      return (
        <LinkSection key={index} viewport={viewport}>
          <TextWrapper paddingBottom={12}>
            <Text color={colors.white} size="xsmall" bold type="text">
              {group.title}
            </Text>
          </TextWrapper>
          {_renderLinks(group.links)}
        </LinkSection>
      );
    });
  };
  let postUrl = `https://tombendermusic.us6.list-manage.com/subscribe/post?u=03515e1fc5452a2d66fbbd708&amp;id=5974820ecd`;

  return (
    <FooterContainer>
      <UpperWrapper viewport={viewport}>
        <LeftWrapper>
          <TextWrapper paddingBottom={8}>
            <Title>Subscribe for more.</Title>
          </TextWrapper>
          <TextWrapper paddingBottom={16}>
            <Subtitle size="xsmall" color={colors.white}>
              Join our email list for special updates and sweet deals.
            </Subtitle>
          </TextWrapper>
          <FlexWrapper>
            <InputWrapper>
            <MailChimpWrapper>
              <MailchimpSubscribe url={postUrl}/>
            </MailChimpWrapper>
            </InputWrapper>
            <ButtonWrapper>
              {/* <PillButton label="SUBMIT" /> */}
            </ButtonWrapper>
          </FlexWrapper>
        </LeftWrapper>
        <RightWrapper>
          {authenticated ?
            _renderLinkSections(authLinkData)
            : _renderLinkSections(linkData)}
        </RightWrapper>
      </UpperWrapper>
      <BottomWrapper viewport={viewport}>
        <BottomContent viewport={viewport}>
          <Text color={colors.white} size="xxsmall" type="text">
            2021 © Care Center Stage
          </Text>
          <RightWrapper bottom viewport={viewport}>
            <TermsWrapper viewport={viewport}>
              <TextLink size="small" color={colors.white} inline label="Privacy Policy" href='/privacy-policy' />
              <TextWrapper paddingRight={18} paddingLeft={18}>
                <Text color={colors.white} size="xxsmall" type="text"> | </Text>
              </TextWrapper>
              <TextLink size="small" color={colors.white} inline label="Terms & Conditions" href='/terms' />
            </TermsWrapper>
            <IconsWrapper viewport={viewport}>
              {iconData.map((icon, i) => (
                <IconWrapper target="_blank" key={i} href={icon.href}>
                  <Icon name={icon.name} color={colors.white} />
                </IconWrapper>
              ))}
            </IconsWrapper>
          </RightWrapper>
        </BottomContent>
      </BottomWrapper>
    </FooterContainer>
  );
};

export default withManager(Footer);
