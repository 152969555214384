import { useState } from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text, Icon } from 'components';
import { colors } from 'theme';

const propTypes = {
  rating: PropTypes.number,
  hideNumber: PropTypes.bool
}

const defaultProps = {
  hideNumber: false
}

const RatingsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StarWrapper = styled.span`
  padding-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({interactive}) => interactive && `cursor: pointer;`}
`;

const GroupWrapper = styled.div`
  display: flex;
  padding-right: 8px;
`;


const RatingBadge = props => {
  const { rating, hideNumber, interactive, onChange } = props;
  const [ hoverRating, setHoverRating] = useState();
  const [ newRating, setRating] = useState();

  const _renderStars = () => {
    let stars = [];
    let fill = rating;

    for (let i = 1; i < 6; i++) {
      if (!fill) stars.push(<StarWrapper key={i}><Icon color={colors.navyBlue} name='star' /></StarWrapper>);
      else if (fill < .25) stars.push(<StarWrapper key={i}><Icon color={colors.navyBlue} name='star' /></StarWrapper>)
      else if ((.25 <= fill) && (fill <= .75)) stars.push(<StarWrapper key={i}><Icon color={colors.navyBlue} name='star-half' /></StarWrapper>)
      else stars.push(<StarWrapper key={i}><Icon color={colors.navyBlue} name='star-full' /></StarWrapper>)
      fill--
    }
    
    return stars;
  };

  const _renderInteractiveStars = () => {
    return (
      <>
        {[1, 2, 3, 4, 5].map((value) => {
          return (
            <StarWrapper interactive key={value} onClick={() => onSaveRating(value)} onMouseEnter={() => onMouseEnter(value)} onMouseLeave={() => onMouseLeave()}>
              <Icon color={colors.navyBlue} name={setFill(value)} size="large" />
            </StarWrapper>
          )
        })}
      </>
    )
  }

  const setFill = (index) => {
    if (hoverRating >= index) {
      return 'star-full';
    } else if (!hoverRating && newRating >= index) {
      return 'star-full';
    }
    return 'star';
  };

  const onMouseEnter = (index) => {
    setHoverRating(index);
  };
  const onMouseLeave = () => {
    setHoverRating(0);
  };
  const onSaveRating = (value) => {
    setRating(value);
    onChange?.(value);
  };

  const roundedRating = Math.round(rating * 100) / 100;
  const roundedNewRating = Math.round(newRating * 100) / 100;

  return (
    <RatingsWrapper>
      <GroupWrapper>
        {!interactive && _renderStars()}
        {interactive && _renderInteractiveStars()}
        
      </GroupWrapper>
      {/* Need to rip this out eventually for use in Review Component & Profile Card */}
      {(rating === 0 || !rating) && !hideNumber && !interactive &&
        <Text type='text' size='xxsmall' color={colors.navyBlue}>No Reviews</Text>}
      {rating > 0 && !hideNumber &&
        <Text type='text' size={'xxsmall'} color={colors.pink}>{roundedRating.toString()}</Text>}
      {newRating > 0 && !hideNumber &&
        <Text type='text' size={'xxsmall'} color={colors.pink}>{roundedNewRating.toString()}</Text>}
    </RatingsWrapper>
  )
}

RatingBadge.propTypes = propTypes;
RatingBadge.defaultProps = defaultProps;
export default RatingBadge;