/**
An Http Service that wraps `fetch`
`_` denotes a private method or field
*/

export default class Http {
	constructor() {
		this._API_CONFIG = {};
		this._baseAPIRoute = '';
		this._config = {};
		this._route = '';
		this._routeConfig = {};
		this._initialized = false;
		this._authHeaders = {};
	}

	/**
	You need to run API.initialize()
	before beginning to make requests.
	*/
	initialize({ APIRoutes = {}, baseURL, options = {} }) {
		this._API_CONFIG = APIRoutes;
		this._baseAPIRoute = baseURL;
		this._authHeaders = options.authHeaders || {};
		this._initialized = true;
	}

	_constructRoute(routeID, params) {
		this._config = this._API_CONFIG[routeID];
		let path = this._config.path;
		if (!params) {
			return (this._route = this._baseAPIRoute + path);
		}
		let keys = Object.keys(params);
		let parts = path.split('/');
		keys.forEach((key) => {
			let findKey = `:${key}`;
			if (parts.indexOf(findKey) > -1) {
				parts[parts.indexOf(findKey)] = params[key];
				return;
			} else {
				return;
			}
		});
		let interpolatedRoute = parts.join('/');
		path = this._baseAPIRoute + interpolatedRoute;
		return (this._route = path);
	}

	_constructQueryParams(qs = {}) {
		let queryParams = Object.keys(qs)
			.map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(qs[k]))
			.join('&');
		if (!queryParams) return;
		this._route += `?${queryParams}`;
	}

	_constructHeaders(addHeaders = {}) {
		let headers = {
			...addHeaders
		};
	
		if (this._config.auth) {
			Object.assign(headers, this._authHeaders || {});
		}
		this._routeConfig.headers = new Headers(headers);
		return;
	}

	_attachBody(body) {
		let stringifiedBody;
		if (body instanceof FormData){
		 return this._routeConfig.body = body;
		} else {
		try {
			stringifiedBody = JSON.stringify(body);
		} catch (e) {
			throw e;
		} 
		return this._routeConfig.body = stringifiedBody;
	}  
	}
	
	setHeaders(newHeaders) {
		if (
			!this._routeConfig.headers ||
			!newHeaders ||
			typeof newHeaders !== 'object'
		)
			return;

		this._routeConfig.headers.append(newHeaders);
		return;
	}

	async get(route, { query, routeParams, headers } = {}) {
		if (!this._initialized) {
			throw new Error('Must initialize Http Service before requesting.');
		}

		this._constructRoute(route, routeParams);
		this._constructQueryParams(query);
		this._constructHeaders(headers);
		delete this._routeConfig.body;

		this._routeConfig.method = 'GET';
		let res = await fetch(this._route, this._routeConfig);
		if (!res.ok) throw res;
		let json = await res.json();
		return json;
	}

	async post(route, { query, routeParams, body, headers }) {
		if (!this._initialized) {
			throw new Error('Must initialize Http Service before requesting.');
		}

		this._constructRoute(route, routeParams);
		this._constructQueryParams(query || {});
		this._constructHeaders(headers);
		this._attachBody(body);

		this._routeConfig.method = 'POST';

		let res = await fetch(this._route, this._routeConfig);
		if (!res.ok) throw res;
		let json = await res.json();
		return json;
	}

	async put(route, { query, routeParams, headers, body } = {}) {
		if (!this._initialized) {
			throw new Error('Must initialize Http Service before requesting.');
		}

		this._constructRoute(route, routeParams);
		this._constructQueryParams(query);
		this._constructHeaders(headers);
		this._attachBody(body);

		this._routeConfig.method = 'PUT';

		let res = await fetch(this._route, this._routeConfig);

		if (!res.ok) throw res;
		let json = await res.json();
		return json;
	}

	async patch(route, { query, routeParams, body }) {
		if (!this._initialized) {
			throw new Error('Must initialize Http Service before requesting.');
		}

		this._constructRoute(route, routeParams);
		this._constructQueryParams(query);
		this._constructHeaders();
		this._attachBody(body);

		this._routeConfig.method = 'PATCH';

		let res = await fetch(this._route, this._routeConfig);
		if (!res.ok) throw res;
		let json = await res.json();
		return json;
	}

	async delete(route, { query, routeParams, body, headers }) {
		if (!this._initialized) {
			throw new Error('Must initialize Http Service before requesting.');
		}

		this._constructRoute(route, routeParams);
		this._constructQueryParams(query);
		this._constructHeaders(headers);
		this._attachBody(body);

		this._routeConfig.method = 'DELETE';

		let res = await fetch(this._route, this._routeConfig);
		if (!res.ok) throw res;
		let json = await res.json();
		return json;
	}
}
