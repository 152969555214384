let supportMarkdown = `
  ##### Support.
  If you run into any issues or have suggestions please send a detailed email to:

  tom@carecenterstage.com



  
`;

let contactMarkdown = `
  ##### Contact us.
  Coming Soon.
`;

export { supportMarkdown, contactMarkdown };
