import React from 'react';
import PropTypes from 'prop-types';
import IconData from '../util/data.json';
import { colors } from 'theme';

const propTypes = {
    name: PropTypes.string,
    data: PropTypes.object,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.oneOf(['small', 'medium', 'large', 'xLarge']), PropTypes.number])
};

const defaultProps = {
    name: 'arrow-right',
    color: colors.black,
    data: IconData,
    size: 'medium'
};

function mapChildren(children, color) {
    return (
        children.length > 0 &&
        children.map((child, index) => {
            let attr = child.attributes;
            if (child.name === 'g') {
                return (
                    <g key={`${child.name}${index}`} transform={attr.transform} fill={color} stroke={attr.stroke}>
                        {child.children.length > 0 && mapChildren(child.children)}
                    </g>
                );
            }
            else if (child.name === 'path') {
                return (
                    <path fill={attr.fill} key={`${child.name}${index}`} d={attr.d}>
                        {child.children.length > 0 && mapChildren(child.children)}
                    </path>
                );
            }
            else if (child.name === 'circle') {
                return (
                    <circle
                        fill={attr.fill}
                        id={attr.id}
                        key={`${child.name}${index}`}
                        cx={attr.cx}
                        cy={attr.cy}
                        r={attr.r}
                        opacity={attr.opacity}
                    >
                        {child.children.length > 0 && mapChildren(child.children)}
                    </circle>
                );
            }
            else if (child.name === 'rect') {
                return (
                    <rect
                        fill={attr.fill}
                        key={`${child.name}${index}`}
                        x={attr.x}
                        y={attr.y}
                        ry={attr.ry}
                        rx={attr.rx}
                        transform={attr.transform}
                        width={attr.width}
                        height={attr.height}
                    >
                        {child.children.length > 0 && mapChildren(child.children)}
                    </rect>
                );
            }
            else if (child.name === 'polyline') {
                return (
                    <polyline
                        fill={attr.fill}
                        key={`${child.name}${index}`}
                        points={attr.points}
                    >
                        {child.children.length > 0 && mapChildren(child.children)}
                    </polyline>
                );
            }
            else if (child.name === 'line') {
                return (
                    <line
                        fill={attr.fill}
                        key={`${child.name}${index}`}
                        x1={attr.x1}
                        x2={attr.x2}
                        y1={attr.y1}
                        y2={attr.y2}
                    >
                        {child.children.length > 0 && mapChildren(child.children)}
                    </line>
                );
            }
            else if (child.name === 'polygon') {
                return (
                    <polygon
                        fill={attr.fill}
                        key={`${child.name}${index}`}
                        points={attr.points}
                    >
                        {child.children.length > 0 && mapChildren(child.children)}
                    </polygon>
                );
            }
            else if (child.name === 'linearGradient') {
                return (
                    <linearGradient
                        key={`${child.name}${index}`}
                        id={attr.id}
                        gradientUnits={attr.gradientUnits}
                        x1={attr.x1}
                        y1={attr.y1}
                        x2={attr.x2}
                        y2={attr.y2}
                        gradientTransform={attr.gradientTransform}
                    >
                        {child.children.length > 0 && mapChildren(child.children)}
                    </linearGradient>
                );
            }
            else if (child.name === 'stop') {
                return (
                    <stop
                        offset={attr.offset}
                        stopColor={attr['stop-color']}
                        key={`${child.name}${index}`}
                    >
                        {child.children.length > 0 && mapChildren(child.children)}
                    </stop>
                );
            } else {
                return null;
            }
        })
    );
}

function determineSize(size) {
    if (typeof size === 'number') return size;
    switch (size) {
        case 'small':
            return 12;
        case 'medium':
            return 16;
        case 'large':
            return 24;
        case 'xLarge':
            return 32;
        case 'jumbo':
        default:
            return 50;
    }
}

const Icon = props => {
    const { data, name, color, size, viewBox } = props;
    let icon = data[name];
    if (!icon?.attributes) return null;
    return (
        <svg
            style={{ transition: 'all 300ms ease'}}
            fill={color}
            viewBox={viewBox ?? icon.attributes.viewBox}
            width={props.width ?? determineSize(size)}
            height={props.height ?? determineSize(size)}
        >
            {mapChildren(icon.children, color)}
        </svg>
    )
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
