import PropTypes from "prop-types";
import styled, { keyframes } from 'styled-components'
import { colors } from "theme";

const propTypes = {
  visible: PropTypes.bool,
};

const defaultProps = {
};

const SpinAnim = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
  }
}`;

const LoadingComponent = styled.div`
  border: 16px solid ${colors.darkGray};
  border-radius: 50%;
  border-top: 16px solid${colors.babyBlue}; 
  width: 60px;
  height: 60px;
  animation: ${SpinAnim} 2s linear infinite;
`;

const SmallLoader = (props) => {
  const {visible } = props;

  if (!visible || visible === "false") {
    return null;
  }


  return visible ? (
					<LoadingComponent/>
  ) : null;
};

SmallLoader.propTypes = propTypes;
SmallLoader.defaultProps = defaultProps;

export default SmallLoader;
