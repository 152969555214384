import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text, TextLink, Icon } from 'components';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { colors } from 'theme';
import { withManager } from 'utilities';
// import { ConsoleLogger } from '@aws-amplify/core';

const MAX_WIDTH = '250px';

const Wrapper = styled.div`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  /* backdrop-filter: saturate(180%) blur(20px); */
  border-radius: 10px;
  padding: 16px;
  transition: all 300ms ease;
  max-height: ${({ open, viewport }) => !open && viewport !== 'desktop' ? '26px' : '900px'};
  box-shadow: 0px 12px 24px ${colors.navyBlue}30;
  /* width: ${({ viewport }) => viewport !== 'desktop' ? '100%' : '20%'}; */
  max-width: ${MAX_WIDTH};
  margin-bottom: 16px;
`;

const NavWrapper = styled.div`
  display: flex;
  visibility: ${({ open, viewport }) => !open && viewport !== 'desktop' ? 'hidden' : 'visible'};
  opacity: ${({ open, viewport }) => !open && viewport !== 'desktop' ? '0' : '1'};
  transition: opacity 300ms ease;
  flex-direction: column;
`;

const MobileMenuTrigger = styled.div`
  margin: 4px 0 4px 0;
  transform: ${({ open }) => open ? 'rotate(180deg)' : 'rotate(0)'};
  transition: all 350ms ease;
  display: flex;
  align-items: center;
`;

const SpaceWrapper = styled.div`
  width: 100%;
  height: ${({ height }) => height ?? '16px'};
`;

const LinkWrapper = styled.li`
  text-decoration: none;
  list-style: none;
  padding-right: ${({ largePadding }) => largePadding ? '32px' : '16px'};
  ${({ viewport, noPadding }) => viewport !== 'desktop' &&
    `padding-bottom: ${noPadding ? '0' : '16px'};`
  }
`;

const AnchorLinkSection = styled.div`
  margin-left: ${({entertainer}) => entertainer ? '16px' : 0};
`;

const SettingsNav = (props) => {
  const { viewport, data, entertainer } = props;
  const [open, setOpen] = useState(false);
  let textLinkSize = viewport === 'desktop' ? 'small' : 'medium';

  const {  user } = useSelector (({user})=> {
    return {
      user: user.data,
    }
  })

  /* active link will be determined if hash / url contains the href in the link */
	const location = useLocation();
	const history = useHistory();

	const _onLinkKeyDown = (e, link) => {
    if (e.keyCode === 32 || e.keyCode === 13) {
      if (location.pathname === "/settings" || location.pathname === "/settings/") {
        history.push(link.href);
      } else {
        history.push('./settings'+ link.href);
      }
    if (viewport !== 'desktop') setOpen(false);
    } 
    return; 
  }

	const _onLinkClick = (e, link) => {
    if (location.pathname === "/settings" || location.pathname === "/settings/") {
      history.push(link);
      
    } else {
      history.push('./settings'+ link);
    }
    if (viewport !== 'desktop') setOpen(false);
  }

  useEffect(()=>{
    if(!location.hash) return;
    const id = location.hash.substring(1)
		let elem = document.getElementById(id);
		if (elem) {
			let {top} = elem.getBoundingClientRect();
			window.scrollTo({
				top: top + window.scrollY - 93,
				behavior: "smooth"
			});
		}

  },[location.hash])

  const _onIconClick = () => {
    if (viewport !== 'desktop') setOpen(!open);
  }

  const _goToMyProfile = () => {
    history.push(`./${user.id}`)
  }

  const _renderNavMenu = () => {
    return (
      <NavWrapper 
        viewport={viewport} 
        open={open} 
        setOpen={setOpen}>
        <SpaceWrapper/>
          <LinkWrapper>
          {entertainer &&
            <TextLink size={textLinkSize} color={colors.blue} label='View Profile' onClick={_goToMyProfile}/>
          }
          </LinkWrapper>  
          <AnchorLinkSection entertainer={entertainer}>
            {data?.map((item, index) => {
              let anchorLink = "#"+item.hash;
              let active = location.hash.includes(anchorLink);
              return (
                <LinkWrapper key={index}>
                  <TextLink 
                    size={textLinkSize}
                    color={active ? colors.pink : colors.blue} 
                    onKeyDown={e => _onLinkKeyDown(e, anchorLink)}
                    onClick={e => _onLinkClick(e, anchorLink)}
                    label={item.title}/>
                  </LinkWrapper>  
              )
            })}
          </AnchorLinkSection>
        <LinkWrapper>
          <TextLink 
            size={textLinkSize} 
            color={colors.blue} 
            label='Account Settings'
            onClick={() => history.push('/account-settings')}/>
        </LinkWrapper>  
        
        {/* // removed for new business model 
        {entertainer &&
          <LinkWrapper>
            <TextLink 
              size={textLinkSize} 
              color={colors.blue} 
              label='Billing & Membership'
              onClick={() => history.push('/billing')}/>
          </LinkWrapper> } */}
      </NavWrapper>
    );
  }
  return (
      <Wrapper viewport={viewport} open={open}>
        <div 
          onClick={_onIconClick}
          style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{marginRight: '16px'}}>
            <Text bold color={colors.navyBlue} size='small'>Your Account</Text>
          </div>
          { viewport !== 'desktop' &&
            <MobileMenuTrigger open={open}>
              <Icon name="caret-down" color={colors.pink}/>
            </MobileMenuTrigger>
          }
          
        </div>
        { _renderNavMenu() }
      </Wrapper>
  )
}

export default withManager(SettingsNav);