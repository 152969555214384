import styled from 'styled-components';
import { colors } from 'theme';
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(['jumbo', 'large', 'medium', 'small']),
  buttonStyle: PropTypes.oneOf(['primary', 'secondary']),
  type: PropTypes.string,
  disabled: PropTypes.bool
},
defaultProps = {
  color: colors.pink,
  size: 'large',
  buttonStyle: 'primary',
  type: 'button',
  disabled: false
};

const HEIGHT = 48
const BORDER_RADIUS = 10

const SHADOW_OPACITY = '50'
const SHADOW_OPACITY_HOVER = '30'
const BOX_SHADOW = '0 1px 2px 0 '
const BOX_SHADOW_HOVER = '0 12px 24px ';

function _handlePadding(size) {
  let padding =
    size === 'small'
      ? '8px 16px'
      : size === 'medium'
        ? '12px 24px'
        : size === 'jumbo'
          ? '32px 64px'
          : '16px 32px'
  return padding;
}

function _handleFontSize(size) {
  let fontSize =
    size === 'small'
      ? '12px'
      : size === 'medium'
        ? '14px'
        : size === 'jumbo'
          ? '24px'
          : '16px'
  return fontSize;
}

const Text = styled.span`
  font-family: ${({ size }) => size === 'jumbo' ? 'dunbar-tall' : 'dunbar-text'}, Helvetica, sans-serif;
  font-size: ${({ size }) => _handleFontSize(size)};
  letter-spacing: ${({ size }) => size === 'jumbo' ? '1.92px' : '0.62px'};
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ inverted, buttonStyle, color }) =>
    (inverted || buttonStyle === 'secondary') ? color : colors.white};
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-align: center;
`;

const Wrapper = styled.button`
  position: relative;
  border: none;
  padding: ${({ size }) => _handlePadding(size)};
  display: flex;
  width: ${({ size }) => size === "jumbo" ? '100%' : 'fit-content'};
  height: ${HEIGHT}px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({ inverted, color, buttonStyle, disabled }) => 
    disabled ? colors.grayLight : buttonStyle === 'secondary' ? 'transparent' : inverted ? colors.white : color};
  color: ${colors.white};
  border-radius: ${BORDER_RADIUS}px;
  border: ${({ buttonStyle, color }) => buttonStyle === 'secondary' ? '2px solid ' + color : null};
  outline: none;
  box-shadow: ${({ color, disabled }) => disabled ? 'none' : BOX_SHADOW + color + SHADOW_OPACITY};
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  ${({ disabled }) => disabled && `
    touch-action: none;
    pointer-events: none;
  `}
  ${({ disabled }) => !disabled && `
  transition: all .3s ease-in-out;
  &:hover {
    transform: scale(1.05);
    background-color: ${({ inverted, buttonStyle, color })=> 
      (inverted || buttonStyle === 'secondary')   ? color : colors.white};
    box-shadow: ${({ color }) => BOX_SHADOW_HOVER + color + SHADOW_OPACITY_HOVER};
      
    ${Text} {
      color: ${({ inverted, buttonStyle, color })=> 
        (inverted || buttonStyle === 'secondary')  ? colors.white : color};
    }
  };
  
  &:focus {
    &::after {
      position: absolute;
      content: '';
      z-index: 100;
      top: 50%;
      left: 50%;
      bottom: 50%;
      right: 50%;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      border: 1px dashed black;
      transform: translate(-50%,-50%);
      border-radius: ${BORDER_RADIUS}px;
    }
  }
  `}
`;

const PillButton = (props) => {
  const { label, inverted, color, type, size, buttonStyle } = props;
  
  return(
    <Wrapper 
      {...props}
      type={type}
      buttonStyle={buttonStyle}
      size={size}
      inverted={inverted}
      color={color}>
      <Text inverted={inverted} size={size} buttonStyle={buttonStyle} color={color}>
        {label}
      </Text>
    </Wrapper>
  )
};

PillButton.propTypes = propTypes;
PillButton.defaultProps = defaultProps;
export default PillButton