import styled from "styled-components";
import PropTypes from "prop-types";
import { Text, Body, Icon } from "components";
import { colors } from "theme";
import { withManager } from 'utilities';

const propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
};

const defaultProps = {};

const ContentWrapper = styled.div`
  height: 100%;
  max-width: 464px;
  display: flex;
  cursor: pointer;
	align-items: center;
  box-shadow: 0px 5px 12px ${colors.navyBlue}30;
  padding: 16px;
  border-radius: 10px;
  background-color: ${colors.white};
  margin-bottom: 16px;
  opacity: ${({ selected }) => selected ? 1 : .6};
  transition: all .3s ease;
  background-color: ${({ selected, careCenter }) =>
    selected && careCenter ?
    colors.babyBlue
    : selected 
    ? colors.pink
    : colors.white
  };

  &:hover {
    opacity: 1;
    box-shadow: 0px 12px 24px ${colors.navyBlue}15;
  }
`;

const IconWrapper = styled.div`
  background-color: ${colors.white};
  display: flex;
  justify-content: center;
  padding: 8px;
  border: 3px solid ${({ careCenter }) => careCenter ? colors.babyBlue : colors.pink};
  z-index: 1;
	border-radius: 50%;
  transition: all 0.3s ease-in;
  cursor: pointer;
`;

const TextWrapper = styled.div`
  padding-bottom: ${({ paddingBottom }) => paddingBottom ?? 4}px;
  display: flex;
	flex-direction: column;
  margin-left: 32px;
`;

const UserTypeCard = (props) => {
  const {
    careCenter,
    title,
    subtitle,
    text,
		viewport,
    onClick,
    selected
  } = props;


	return (
      <ContentWrapper 
        careCenter={careCenter}
        selected={selected}
        viewport={viewport} 
        onClick={onClick}>
        <IconWrapper careCenter={careCenter}>
          <Icon name="check" size="large" color={careCenter ? colors.babyBlue : colors.pink}/>
        </IconWrapper>
        <TextWrapper>
          <Text
            bold
            color={selected ? colors.white : careCenter ? colors.babyBlue : colors.pink}
            primitive="h2"
            size='small'>{title}
            </Text>
            <Text
              color={selected ? colors.white : colors.darkGray}
              primitive="h2"
              size='xxsmall'>{subtitle}
            </Text>
            <Body
              color={selected ? colors.white: null}>
              {text}
            </Body>
        </TextWrapper>
      </ContentWrapper>
  );
};

UserTypeCard.propTypes = propTypes;
UserTypeCard.defaultProps = defaultProps;

export default withManager(UserTypeCard);
