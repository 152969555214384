import { 
  call, 
  put 
} from "redux-saga/effects";
import ActionTypes from "../actionTypes";


export function* putInit (action) {
  const { body, at } = action

  try {
    const response = yield call(() => {

      return global.API.put('USER_INIT', {
        headers: {
          accesstoken: at,
          'Content-Type': 'application/json',
        },
        body: {
          ...body
        }
      })
    });

    yield put({
      type: ActionTypes.user.putInit.SUCCESS,
      response: response,
      body: body,
    });

  } catch(error) {
    yield put({
      type: ActionTypes.user.putInit.ERROR,
      error
    });
  }
};


export function* getInit (action) {
  const { at } = action

  try {
    const response = yield call(() => {
      return global.API.get('USER_INIT', {
        headers: {
          accesstoken: at,
        },
      })
    });

    yield put({
      type: ActionTypes.user.getInit.SUCCESS,
      response: response,
    });

  } catch(error) {
    yield put({
      type: ActionTypes.user.getInit.ERROR,
      error
    });
  }
};


export function* getSettings (action) {
  try {

    const { Item } = yield call(() => {

      const { at } = action;
      return global.API.get('USER_GET_SETTINGS', {
        headers: {
          accesstoken: at
        }
      })
    });

    yield put({
      id: action.params.id,
      type: ActionTypes.user.getSettings.SUCCESS,
      response: Item
    });

  } catch(error) {
    yield put({
      type: ActionTypes.user.getSettings.ERROR,
      error
    });
  }
};

export function* saveSettings (action) {
  const { body, at } = action

  try {
    const response = yield call(() => {

      return global.API.put('USER_SAVE_SETTINGS', {
        headers: {
          accesstoken: at,
          'Content-Type': 'application/json',
        },
        body: {
          ...body
        }
      })
    });


    yield put({
      type: ActionTypes.user.saveSettings.SUCCESS,
      response: response,
      body: body,
    });

  } catch(error) {
    yield put({
      type: ActionTypes.user.saveSettings.ERROR,
      error
    });
  }
};



export function* setAvatar (action) {
  
  try {
    const response = yield call(() => {
      const { body,at } = action;
      return global.API.post('USER_SET_AVATAR', {
        body: body,
        headers: {
          accesstoken: at,
          // 'Content-Type': 'image/jpeg'
        },
      })
    }); 

    yield put({
      type: ActionTypes.user.setAvatar.SUCCESS,
      response
    });

  } catch(error) {
    yield put({
      type: ActionTypes.user.setAvatar.ERROR,
      error
    });
  }
};


export function* saveImage (action) {
  
  try {
    const response = yield call(() => {
      const { body,at } = action;
      return global.API.post('USER_SAVE_IMAGE', {
        body: body,
        headers: {
          accesstoken: at,
          // 'Content-Type': 'image/jpeg'
        },
      })
    }); 


    yield put({
      type: ActionTypes.user.saveImage.SUCCESS,
      response
    });

  } catch(error) {
    yield put({
      type: ActionTypes.user.saveImage.ERROR,
      error
    });
  }
};

export function* deleteImage (action) {
  try {
    const response = yield call(() => {
      const { body, at } = action;
      return global.API.delete('USER_DELETE_IMAGE', {
        body: body,
        headers: {
          accesstoken: at,
          'Content-Type': 'application/json',
        },
      })
    }); 

    yield put({
      type: ActionTypes.user.deleteImage.SUCCESS,
      response
    });

  } catch(error) {
    yield put({
      type: ActionTypes.user.deleteImage.ERROR,
      error
    });
  }
};


export function* deleteUser (action) {
  try {
    const response = yield call(() => {
      const { body, at } = action;
      return global.API.post('USER_DELETE_ACCOUNT', {
        headers: {
          accesstoken: at,
          "Content-Type": "application/json;charset=utf-8",
        },
        body: body
      })
    }); 

    yield put({
      type: ActionTypes.user.deleteUser.SUCCESS,
      response
    });

  } catch(error) {
    yield put({
      type: ActionTypes.user.deleteUser.ERROR,
      error
    });
  }
};

