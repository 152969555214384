import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { colors } from 'theme'
import { Body } from 'components';
import Option from './DropdownOption';
import Trigger from './DropdownTrigger';

const propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
};

const defaultProps = {
  placeholder: ""
}

const ComponentWrapper = styled.div`
  width: ${({ width }) => width ?? '100%'};
  max-width: 600px;
  position: relative;
  height: ${({ label }) => label ? 80 : 48}px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
`;

const DropdownList = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  background: ${colors.white};
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 16px 0 8px 0;
  top: -12px;
  position: relative;
  z-index: 2;
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 8px;
`;

const DropdownSelect = (props) => {
  const { label, defaultValue, data, placeholder, labelColor, onChange, width } = props;
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelected] = useState(defaultValue);
  const selectItem = val => {
    setSelected(data.filter(item => item.value === val.value)[0]);
    setOpen(false);
    onChange && onChange({ target: { name: props.name, value: val.value } });
  }

  useEffect(()=>{
    setSelected(defaultValue)
  },[defaultValue])

  return (
    <ComponentWrapper width={width} label={label}>
      <Wrapper id="selectWrapper">
        {label && <Label><Body size="small" bold color={labelColor ?? colors.blue}>{label}</Body></Label>}
        <Trigger onClick={e => setOpen(!open)} active={open} selectedOption={selectedOption} placeholder={defaultValue ?? placeholder} />
        {open &&
          <DropdownList role='list'>
            {data?.map((item, index) => {
              return (
                <Option
                  key={index}
                  listItem={index === data.length - 1}
                  onClick={() => selectItem(item)}
                  value={item.value}
                  selected={selectedOption?.value === item.value}
                >
                  {item.children}
                </Option>
              )
            })}
          </DropdownList>}
      </Wrapper>
    </ComponentWrapper>

  )
};

DropdownSelect.propTypes = propTypes;
DropdownSelect.defaultProps = defaultProps;

export default DropdownSelect