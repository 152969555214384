import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { Avatar, PillButton, TextLink, Burger, Icon } from 'components';
import { colors } from 'theme';
import { withManager } from 'utilities';
import ActionTypes from 'redux/actionTypes';
import { navProfileEntertainerData, navProfileCareCenterData } from './data';

const HEADER_HEIGHT = 93;

const Placeholder = styled.div`
  height: ${HEADER_HEIGHT}px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 999;
`;

const Wrapper = styled.div`
  background-color: ${colors.white};
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  align-items: center;
  transition: all .3s ease-in;
  z-index: 99;
  ${({ scrolled }) => scrolled &&
    ` box-shadow: 0px 6px 12px ${colors.navyBlue}30;
    // height: ${HEADER_HEIGHT}px;
  `}
`;

const LogoWrapper = styled.img`
  max-width: 325px;
  max-height: 92px;
  min-height: 25px;
  min-width: 350px;
  cursor: pointer;

  @media only screen  and (max-width : 550px) {
    max-width: 300px;
    min-width: 280px;
  min-height: 15px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
`;

const LoginWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${({ viewport }) => viewport !== 'desktop' &&
    `padding-top: 32px;`
  }
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ viewport, open, scrolled }) => viewport !== 'desktop' &&
    `
    display: ${open ? 'flex' : 'none'};
    flex-direction: column;
    background: ${colors.white};
    box-shadow: ${scrolled ? '0px 12px 24px ' + colors.navyBlue + '30' : 'none'};
    transform: ${open ? 'translateX(0)' : 'translateX(101%)'};
    width: ${open ? '70%' : '100%'};
    border-radius: 10px 0 0 10px;
    height: 100vh;
    text-align: right;
    max-width: 300px;
    padding: 128px 32px;
    position: fixed;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 3;
    align-items: flex-start;

    span {
      transition: color 0.3s linear;
      width: auto;
    }
  `}
`;

const TextWrapper = styled.span`
  padding-right: ${({ largePadding }) => largePadding ? '32px' : '16px'};

  ${({ viewport, noPadding }) => viewport !== 'desktop' &&
    `padding-bottom: ${noPadding ? '0' : '32px'};`
  }
`;

const ProfileWrapper = styled.div`
  margin-right: 16px;
  cursor: pointer;
  ${({ viewport, noPadding }) => viewport !== 'desktop' &&
    `
    display: flex;
  `}
`;

const CatDropdown = styled.div`
  background-color: ${colors.white};
  position: ${({ viewport }) => viewport !== 'desktop' ? 'relative' : 'absolute'} ;
  transition: all 0.35s ease;
  z-index: 1;
  border-radius: 10px;
  margin-top: 8px;
  margin-left: 8px;

  ${({ viewport }) => viewport === 'desktop' ? `
    box-shadow: ${'0px 12px 24px ' + colors.navyBlue + '30'};
    padding: 32px;
  `
    : `
    padding: 32px 0 0 16px;
  ` }
`;

const ProfileDropdown = styled.div`
  background-color: ${colors.white};
  position: ${({ viewport }) => viewport !== 'desktop' ? 'relative' : 'absolute'} ;
  z-index: 1;
  border-radius: 10px;
  margin-top: 8px;
  width: fit-content;

  ${({ viewport }) => viewport === 'desktop' ? `
    box-shadow: ${'0px 12px 24px ' + colors.navyBlue + '30'};
    padding: 32px;`
    : `padding: 0 0 0 16px;`}

@media only screen  and (max-width : 1650px) {
  right: 0;
}
`;

const Spacer = styled.div`
  width: 100%;
  border-bottom: 2px solid ${colors.grayLight};
  margin: ${({ height }) => height ? height / 2 + 'px 0' : '16px 0'};
`;

const MobileMenuTrigger = styled.div`
  margin: 0 8px;
  transform: ${({ open }) => open ? 'rotate(180deg)' : 'rotate(0)'};
  transition: all 0.35s ease;
  display: flex;
  align-items: center;
`;

const LinkWrapper = styled.li`
  text-decoration: none;
  list-style: none;
  padding-right: ${({ largePadding }) => largePadding ? '32px' : '16px'};
  ${({ viewport, noPadding }) => viewport === 'desktop' ?
    `padding-bottom: ${noPadding ? '0' : '16px'};`
    : `padding-bottom: ${noPadding ? '0' : '32px'};`
  }
`;

const GlobalNav = (props) => {
  const {
    categoryData,
    viewport,
    authenticated,
    openAuthenticationModal,
  } = props;
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [categoryDropdown, setCategoryDropdown] = useState(false);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const wrapperRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const _renderNavMenu = () => {
    let textLinkSize = viewport === 'desktop' ? 'small' : 'medium';

    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setCategoryDropdown(false);
        setProfileDropdown(false);
      }
    };

    useEffect(() => {
      //prevents scrolling underneath for mobile & desktop browsers
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        //resets scrolling prevent styles
        document.body.style.overflow = '';
      }
    });

    useEffect(() => {
      document.addEventListener("click", handleClickOutside, false);
      return () => {
        document.removeEventListener("click", handleClickOutside, false);
      };
    }, []);

    const _toggleCatNav = () => {
      setCategoryDropdown(!categoryDropdown)
      setProfileDropdown(false)
    }

    const _toggleProfileNav = () => {
      setProfileDropdown(!profileDropdown)
      setCategoryDropdown(false)
    }

    const openSignUpModal = () => {
      openAuthenticationModal('signUp');
      setOpen(false);
    }
    const openSignInModal = () => {
      openAuthenticationModal('signIn');
      setOpen(false);
    }

    const _signOut = () => {
      Auth.signOut().then(() => {
        localStorage.clear();
        history.go(0)
      })
    };

    /** 
     * this needs to be trimmed down to just get the nav stuff necessary for 
     */
    const {
      user,
      avatarUrl,
      // fetching 
    } = useSelector(({ user }) => {
      return {
        user: user.data,
        avatarUrl: user.data?.avatarUrl,
        fetching: user.fetching
      }
    })

    const _goToEntertainement = () => {
      history.push('./search');
      setOpen(false)
      // _toggleCatNav()
    }

    const _goToCategory = (item) => {
      const cat = item.value.toLowerCase();
      history.push({
        pathname: './search',
        search: `?category=${cat}`
      });
      dispatch({ type: ActionTypes.profile.search.START, query: { category: item.value } })
      setCategoryDropdown(false)
      setOpen(false)
    }
    const userNavData = user?.group === 'carecenters' ? navProfileCareCenterData : navProfileEntertainerData;

    const handleMenuClick = (item) => {
      setOpen(false)
      if (item.title === 'View Profile') {
        history.push('/' + user?.id)
      } else {
        history.push(item.href)
      }
    }

    return (
      <NavWrapper
        ref={wrapperRef}
        viewport={viewport}
        scrolled={scrolled}
        open={open}
        setOpen={setOpen}>
        <TextWrapper viewport={viewport}>
          <TextLink
            size={textLinkSize}
            color={colors.blue}
            label="Find Entertainment"
            onClick={_goToEntertainement} />
        </TextWrapper>
        <TextWrapper viewport={viewport} largePadding>
          <div
            onClick={_toggleCatNav}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <TextLink
              size={textLinkSize}
              onClick={_toggleCatNav}
              color={colors.blue}
              label="Search by Category" />
            {viewport !== 'desktop' &&
              <MobileMenuTrigger open={categoryDropdown}>
                <Icon name="caret-down" color={colors.pink} />
              </MobileMenuTrigger>
            }
          </div>
          {categoryDropdown &&
            <CatDropdown viewport={viewport}>
              {categoryData?.map((item, index) => {
                return (
                  <LinkWrapper
                    viewport={viewport}
                    key={index + 22}
                    noPadding={index === categoryData.length - 1}>
                    <TextLink
                      size={textLinkSize}
                      color={colors.navyBlue}
                      onKeyDown={() => { }}
                      onClick={() => _goToCategory(item)}
                      label={item.children} />
                  </LinkWrapper>
                )
              })}
            </CatDropdown>
          }
        </TextWrapper>
        { viewport !== 'desktop' && <Spacer />}
        { authenticated ?
          <div>
            <ProfileWrapper
              viewport={viewport}
              onClick={_toggleProfileNav}>
              <Avatar
                size="small"
                borderColor={colors.babyBlue}
                src={avatarUrl} />
              {(profileDropdown || viewport !== 'desktop') &&
                <ProfileDropdown right viewport={viewport}>
                  {userNavData?.map((item, index) => {
                    return (
                      <LinkWrapper
                        viewport={viewport}
                        key={index}>
                        <TextLink
                          target={item.title === 'View Profile' ? '_blank' : null}
                          size={textLinkSize}
                          color={colors.navyBlue}
                          onClick={() => handleMenuClick(item)}
                          label={item.title} />
                      </LinkWrapper>
                    )
                  })}
                  <LinkWrapper viewport={viewport}>
                    <TextLink
                      size={textLinkSize}
                      color={colors.pink}
                      onKeyDown={() => { }}
                      onClick={_signOut}
                      label={'Log Out'} />
                  </LinkWrapper>
                </ProfileDropdown>
              }
            </ProfileWrapper>
          </div>
          : <LoginWrapper viewport={viewport}>
            <TextWrapper noPadding viewport={viewport} >
              <TextLink
                onClick={openSignInModal}
                size={textLinkSize}
                color={colors.navyBlue}
                label="Log in" />
            </TextWrapper>
            <PillButton
              size='small'
              label="SIGN UP"
              onClick={openSignUpModal} />
          </LoginWrapper>
        }
      </NavWrapper>
    );
  }

  const _onScroll = () => {
    if (window.scrollY > 1) {
      !scrolled && setScrolled(true);
    } else {
      scrolled && setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", _onScroll)
    return () => {
      window.removeEventListener("scroll", _onScroll);
    };
  });

  return (
    <Placeholder>
      <Wrapper scrolled={scrolled}>
        <InnerWrapper>
          <div onClick={() => history.push('./')} style={{ outline: 'none', display: 'flex', justifyContent: 'center' }}>
            <LogoWrapper src="https://images.carecenterstage.com/root/care-center-stage-logo-inline-full-color.svg" alt={'Care Center Stage'} />
          </div>
          {viewport === 'desktop' &&
            _renderNavMenu()
          }
          {viewport !== 'desktop' &&
            <Burger open={open} setOpen={setOpen} />
          }
        </InnerWrapper>
      </Wrapper>
      {viewport !== 'desktop' &&
        _renderNavMenu()
      }

    </Placeholder>
      )
}

export default withManager(GlobalNav);