import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import { TextBlock, PillButton, TextLinkArrow } from 'components';
import { colors, gradients } from 'theme'
import { SignUpCTA, SignUpInfo } from 'modules';
import { signUpData } from './data';
import { withManager } from 'utilities';
import {ReactComponent as Chat} from 'assets/specialtyIcons/ccs-carecenter-chat.svg';
import {ReactComponent as Cycle} from 'assets/specialtyIcons/ccs-carecenter-cycle.svg';
import {ReactComponent as Target} from 'assets/specialtyIcons/ccs-carecenter-target.svg';
import {ReactComponent as Globe} from 'assets/specialtyIcons/ccs-entertainer-globe.svg';
import {ReactComponent as GoBig} from 'assets/specialtyIcons/ccs-entertainer-goBig.svg';
import {ReactComponent as Search} from 'assets/specialtyIcons/ccs-entertainer-search.svg';

const Homepage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`;

const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 40vh;
  width: ${({ viewport }) =>  viewport === 'desktop' ? 'calc(100vw - 32px)' : viewport === 'tablet' ? 'calc(100vw - 16px)' : 'calc(100vw - 8px)'};
  background: ${gradients.triColorGradient};
  padding: ${({ viewport }) => viewport === 'desktop' ? '96px 16px 128px 16px' : '64px 16px'};
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin: 16px;
`;

const CTAWrapper = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) => viewport !== 'desktop' ? 'column' : 'row'};
  align-items: center;
  justify-content: space-around;
  max-width: 964px;
  width: 100%;
  margin-top: -100px;
  padding: ${({ viewport }) => viewport !== 'desktop' ? '0 32px' : '0'};
  z-index: 2;
  box-sizing: border-box;
`;

const InfoSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 40vh;
  width: ${({ viewport }) =>  viewport === 'desktop' ? 'calc(100vw - 32px)' : viewport === 'tablet' ? 'calc(100vw - 16px)' : 'calc(100vw - 8px)'};
  background: ${({ careCenter }) => careCenter ? gradients.lightBlueGradient : gradients.triColorGradient};
  padding: ${({ viewport }) => viewport === 'desktop' ? '96px 16px 128px 16px' : '64px 16px'};
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin: 16px;
  padding: ${({ viewport }) => viewport !== 'desktop' ? '32px' : '88px'};
`;

const InfoCardWrapper = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) => viewport !== 'desktop' ? 'column' : 'row'};
  margin: 32px 0;
  width: 100%;
  justify-content: space-between;
  max-width: 964px;
`;

const SpaceWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  height: 32px;
`;

const SignUp = (props) => {
  const { viewport, openAuthenticationModal } = props;
  const history = useHistory();
  const { 
    hero, 
    ctaCareCenter, 
    ctaEntertainer, 
    intro, 
    careCenterInfo, 
    entertainerInfo, 
  } = signUpData;

  const openSignUpModal = () => openAuthenticationModal('signUp');

  const _scrollToTop = () => {
    window.scrollTo({
      top: 0,})
  }

  useEffect(_scrollToTop)

  const _onLinkClick = (e, link) => {
    let id = link.substring(1);
		let elem = document.getElementById(id);
		if (elem) {
			let top = elem.getBoundingClientRect().top;
			window.scrollTo({
				top: top - 128,
				behavior: "smooth"
			});
		}
		history.push(link.href);
  }

  return (
    <Homepage viewport={viewport}>
      <HeroContainer viewport={viewport}>
        <TextBlock
          align="center"
          maxWidth='572px'
          color={colors.white}
          primitive="h1"
          titleSize='xxlarge'
          title={hero.title}
          subtitle={hero.text}/>  
          <SpaceWrapper/>        
          <SpaceWrapper/>        
      </HeroContainer>
      <CTAWrapper viewport={viewport} id="cta">
        <SignUpCTA 
          careCenter
          title={ctaCareCenter.title}
          subtitle={ctaCareCenter.text}
          price={ctaCareCenter.price}
          onButtonClick={openSignUpModal}
          onLinkClick={(e) => _onLinkClick(e, '#care-center-info')}/>
        <SignUpCTA
          title={ctaEntertainer.title}
          subtitle={ctaEntertainer.text}
          price={ctaEntertainer.price}
          onButtonClick={openSignUpModal}
          onLinkClick={(e) => _onLinkClick(e, '#entertainer-info')}/>
      </CTAWrapper>

      <TextBlock
        align="center"
        maxWidth='586px'
        color={colors.blue}
        primitive="h3"
        titleSize='xlarge'
        title={intro.title}
        subtitle={intro.text}/>
        <SpaceWrapper/>
        <TextLinkArrow 
          color={colors.blue}
          onClick={() => {history.push('/about')}}
          label="How it all started"/>
      <SpaceWrapper/>        

      <InfoSection careCenter id="care-center-info">
        <TextBlock
          align="center"
          maxWidth='406px'
          color={colors.white}
          primitive="h3"
          titleSize='large'
          title={careCenterInfo.title}
          subtitle={careCenterInfo.text}/>
        <InfoCardWrapper viewport={viewport}>
          <SignUpInfo
            careCenter
            icon={Target}
            title={careCenterInfo.card1.title}
            subtitle={careCenterInfo.card1.text}/>
          <SignUpInfo
            careCenter
            icon={Chat}
            title={careCenterInfo.card2.title}
            subtitle={careCenterInfo.card2.text}/>
          <SignUpInfo
            careCenter
            icon={Cycle}
            title={careCenterInfo.card3.title}
            subtitle={careCenterInfo.card3.text}/>
        </InfoCardWrapper>
        <PillButton
          inverted
          size="medium"
          label="Join the Community"
          onClick={(e) => _onLinkClick(e, '#cta')}
          color={colors.babyBlue}/>
      </InfoSection>  

      <InfoSection id="entertainer-info">
        <TextBlock
          align="center"
          maxWidth='386px'
          color={colors.white}
          primitive="h3"
          titleSize='large'
          title={entertainerInfo.title}
          subtitle={entertainerInfo.text}/>
        <InfoCardWrapper viewport={viewport}>
          <SignUpInfo
            icon={GoBig}
            title={entertainerInfo.card1.title}
            subtitle={entertainerInfo.card1.text}/>
          <SignUpInfo
            icon={Globe}
            title={entertainerInfo.card2.title}
            subtitle={entertainerInfo.card2.text}/>
          <SignUpInfo
            icon={Search}
            title={entertainerInfo.card3.title}
            subtitle={entertainerInfo.card3.text}/>
        </InfoCardWrapper>
        <PillButton
          inverted
          size="medium"
          label="Join the Community"
          onClick={(e) => _onLinkClick(e, '#cta')}
          color={colors.pink}/>
      </InfoSection>  
        
    </Homepage>
  )
}

export default withManager(SignUp);