import styled from "styled-components";
import PropTypes from "prop-types";
import { TextBlock, PillButton } from "components";
import { colors, gradients } from "theme";
import { withManager } from 'utilities';

const propTypes = {
  imageSource: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  category: PropTypes.string,
  hideButton: PropTypes.bool
};

const defaultProps = {};

const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: ${({ viewport }) =>  viewport === 'desktop' ? 'calc(100vw - 32px)' : viewport === 'tablet' ? 'calc(100vw - 16px)' : 'calc(100vw - 8px)'};
  background: ${({ gradient }) => gradient ?? gradients.lightBlueGradient};
  padding: ${({ viewport }) => viewport === 'desktop' ? '96px 16px 128px 16px' : '64px 16px'};
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  margin: 16px;
`;

const PageHero = (props) => {
  const { title, subtitle, viewport, onButtonClick, buttonLabel, gradient } = props;

  return (
    <HeroContainer viewport={viewport} gradient={gradient}>
        <TextBlock
          align="center"
          maxWidth="572px"
          color={colors.white}
          primitive="h3"
          titleSize="xlarge"
          title={title}
          subtitle={subtitle}
        />
      {onButtonClick &&  buttonLabel && <PillButton color={colors.pink} onClick={onButtonClick} label={buttonLabel} />}
    </HeroContainer>
  );
};

PageHero.propTypes = propTypes;
PageHero.defaultProps = defaultProps;

export default withManager(PageHero);