import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text, Body, TextLink } from 'components';
import { colors } from 'theme';
import ReactMarkdown from 'react-markdown';

const propTypes = {
  children: PropTypes.string,
}

const defaultProps = {
}

const Wrapper = styled.div`
`;

const Markdown = props => {
  let headerMargin = '32px 0 8px 0';

  const components = {
    p: (props) => <Body {...props} />,
    h1: (props) => <Text bold style={{ margin: headerMargin}} {...props} primitive="h1" size="xxlarge"/>,
    h2: (props) => <Text bold style={{ margin: headerMargin}} {...props} size="xlarge" color={colors.pink}/>,
    h3: (props) => <Text bold style={{ margin: headerMargin}} {...props} size="large" color={colors.blue}/>,
    h4: (props) => <Text bold style={{ margin: headerMargin}} {...props} size="medium" color={colors.babyBlue}/>,
    h5: (props) => <Text bold style={{ margin: headerMargin}} {...props} size="small" color={colors.blue}/>,
    a: (props) => <TextLink inline {...props} label={props.children} href={props.href}/>,
    li: (props) => <Body {...props} primitive="li" />,
  };
  return (
    <Wrapper>
        <ReactMarkdown
        children={props.children}
        components={components}
        />
    </Wrapper>
  )
}

Markdown.propTypes = propTypes;
Markdown.defaultProps = defaultProps;
export default Markdown;