export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  pink: '#FF007F',
  blue: '#004EFF',
  babyBlue: '#00BCF5',
  navyBlue: '#0A0085',
  purple: '#7A00E3',
  gray: '#7F7F7F',
  darkGray: '#A7A7A7',
  grayLight: '#E0E0E0',
  'off-white': '#f8f8f8',
  error: '#FF0000'
}

export const gradients = {
  triColorGradient: 'linear-gradient(112deg, ' + colors.pink + ' -5%,' + colors.purple + ' 41%,' + colors.babyBlue + ' 100%)',
  blueGradient: 'linear-gradient(135deg, ' + colors.navyBlue + ' 0%,' + colors.babyBlue + ' 100%)',
  lightBlueGradient: 'linear-gradient(135deg, ' + colors.babyBlue + ' 0%, #0070F2 100%)'
}