import { useState, useEffect } from "react";
import styled from "styled-components";
import { withManager } from "utilities";
import { ProfileNav, ProfileHero, JoinHero, Reviews, ContactEntertainer, LeaveReview } from "modules";
import {
  Avatar,
  Body,
  Icon,
  PillButton,
  Text,
  TextBlock,
  Carousel,
  TextLink,
  Loader,
  Video
} from "components";
import { validateUrl } from 'utilities';
import { profileLinkData } from "./data";
import { colors } from "theme";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ActionTypes from "redux/actionTypes";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  min-height: calc(100vh - 488px);
  padding: 16px 0px;
  ${({ viewport }) =>
    viewport !== "desktop" &&
    `
		padding-top: 0px;
	`}
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport === "desktop" ? "row" : "column"};
  justify-content: flex-start;
  padding: 0 ${({ padding, viewport }) => padding && viewport !== 'desktop' ? 32 : 0}px;
  box-sizing: border-box;
  padding-bottom: ${({ paddingBottom }) => paddingBottom ?? 64}px;
  max-width: 964px;
  width: 100%;
  margin: auto;
`;
const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 ${({ viewport }) => viewport !== 'desktop' ? 32 : 0}px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom ?? 64}px;
  max-width: 964px;
  width: 100%;
  margin: auto;
`;

const NavWrapper = styled.div`
  position: sticky;
  top: 100px;
  margin-top: -40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const BottomPadding = styled.div`
  padding-bottom: ${({ padding }) => padding ?? 16}px;
`;

const CarouselWrapper = styled.div`
  max-width: 964px;
  margin: auto;
  width: 100%;
  margin: 32px 0;
`;

const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 67%;
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 33%;
  padding-left: ${({ viewport }) => (viewport === "desktop" ? 16 : 0)}px;
`;

const ReviewsWrapper = styled.div`
  width: 99vw;
  background-color: ${colors["off-white"]};
  min-height: 286px;
  margin-bottom: 32px;
  padding: 32px;
  box-sizing: border-box;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  padding-right: 16px;
  &:last-child {
    padding-right: 0;
  }
  &:hover {
    svg path {
      transition: all 0.35s ease;
      fill: ${colors.pink};
    }
  }
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 32px;
  ${({ viewport }) =>
    viewport !== "desktop" &&
    `
				flex-direction: column;
		`}
`;

const ButtonWrapper = styled.div`
  padding-left: 32px;
  ${({ viewport }) =>
    viewport !== "desktop" &&
    `
				padding-left: 0;
				padding-top: 32px;
		`}
`;

const Profile = (props) => {
  const { viewport, openAuthenticationModal, authenticated } = props;
  const params = useParams();

  const { profile, fetching, user } = useSelector(({ profile, user }) => {
    return {
      user: user.data,
      profile: profile[params?.id],
      fetching: profile.fetching
    }
  })
  const dispatch = useDispatch();
  const [messageModal, setMessageModal] = useState();
  const [reviewModal, setReviewModal] = useState();
  const history = useHistory();
  const isUserProfile = user?.id === profile?.id;

  // start page at top
  const _scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  useEffect(_scrollToTop, [params.id]);

  const _getProfile = () => {
    dispatch({ params, type: ActionTypes.profile.getOne.START });
  };

  useEffect(_getProfile, [params, dispatch]);

  const _renderAboutSection = () => {
    let defaultDescription =
      profile.displayName +
      " has not yet added a description, send them a message to discover more!";
    return (
      <AboutWrapper id="about">
        <BottomPadding padding={32}>
          <BottomPadding>
            <Text color={colors.blue} bold size="medium">
              About
            </Text>
          </BottomPadding>
          <Body color={colors.gray}>
            {profile.description ?? defaultDescription}
          </Body>
        </BottomPadding>
        {viewport !== "desktop" && _renderDetailsSection()}
      </AboutWrapper>
    );
  };

  const _renderVideoSection = () => {
    if (!profile.youtubeVideoUrl) return;
    return (
      <VideoWrapper id="video" viewport={viewport}>
        <BottomPadding>
          <Text color={colors.blue} bold size="medium"> Video </Text>
        </BottomPadding>
        <Video height="100%" width="100%" url={profile.youtubeVideoUrl} />
      </VideoWrapper>
    );
  };

  const _renderDetailsSection = () => {
    const unCamelWord = (text) => {
      const result = text.replace(/([A-Z])/g, " $1");
      return `${result.charAt(0)}${result.slice(1)}`.toUpperCase();
    };
    if (!profile.details) return;
    return (
      <DetailsWrapper viewport={viewport} id="details">
        <BottomPadding>
          <Text color={colors.blue} bold size="medium">
            Details
          </Text>
        </BottomPadding>
        {Object.keys(profile.details)?.map((key, index) => {
          return (
            <BottomPadding key={index}>
              <TextBlock
                color={colors.babyBlue}
                titleSize="xxsmall"
                bodySize="small"
                title={unCamelWord(key)}
                key={index}
                subtitle={profile.details[key]}
              />
            </BottomPadding>
          );
        })}
      </DetailsWrapper>
    );
  };
  const _renderReviewSection = () => {
    return (
      <ReviewsWrapper id="reviews" viewport={viewport} padding>
        <SectionWrapper paddingBottom={0}>
          <Reviews
            reviews={profile?.reviews}
            user={user}
            profileId={profile?.id}
            displayName={(!profile?.displayName || profile?.displayName.length === 0) ? profile.name : profile?.displayName}
            onClickLeaveReview={_handleReviewModal}
          />
        </SectionWrapper>
      </ReviewsWrapper>
    );
  };

  const _renderItem = (val, color) => (
    <BottomPadding>
      <Text color={color ?? colors.blue} size="xxsmall">
        {val}
      </Text>
    </BottomPadding>
  );

  const _renderSocial = () => {
    if (!profile.socialMedia) return;
    return Object.keys(profile.socialMedia).map((key, index) => {
      if (key === 'other') return null;
      if (key === 'twitter') return null;
      if (!validateUrl(profile.socialMedia[key])) return null;
      return (
        <IconWrapper key={`${key}${index}`}>
          <a href={profile.socialMedia[key]} target="_blank" rel="noreferrer">
            <Icon name={key.split('Url')[0]} color={colors.babyBlue} size="large" />
          </a>
        </IconWrapper>
      );
    });
  };
  const _renderContactSection = () => (
    <BottomPadding padding={0} id="contact">
      <BottomPadding padding={32}>
        <Text color={colors.blue} bold size="medium">
          Contact
        </Text>
      </BottomPadding>
      <AvatarWrapper viewport={viewport}>
        <Avatar src={profile.avatarUrl} size="large" />
        <ButtonWrapper viewport={viewport}>
          {isUserProfile ?
            <PillButton label="Edit Profile" onClick={() => history.push('/settings')} />
            : <PillButton label="MESSAGE ENTERTAINER" onClick={_handleMessageModal} />}
        </ButtonWrapper>
      </AvatarWrapper>
      <BottomPadding>
        <Text bold color={colors.navyBlue} size="xlarge">
          {(!profile?.displayName || profile?.displayName.length === 0) ? profile.name : profile?.displayName}
        </Text>
      </BottomPadding>
      {profile.location && profile.location.city &&
        _renderItem(profile.location.city + ", " + profile.location.state)}
      {profile?.location?.virtual &&
        _renderItem('+ Virtual Entertainment Available', colors.pink)}
      {profile?.location?.virtual && !profile.location.city &&
        _renderItem('Virtual Entertainment Only', colors.pink)}
      {profile.phoneNumber && _renderItem(profile.phoneNumber)}
      {profile.websiteUrl && (
        <BottomPadding>
          <TextLink href={profile.websiteUrl} target="_blank" label={profile.websiteUrl.split('https://')} />
        </BottomPadding>
      )}
      {profile.socialMedia?.additionalLink && (
        <BottomPadding>
          <TextLink
            href={profile.socialMedia?.additionalLink}
            target="_blank"
            rel="noreferrer"
            label={profile.socialMedia?.additionalLink}
          />
        </BottomPadding>
      )}
      {_renderSocial()}
    </BottomPadding>
  );

  const _handleMessageModal = () => {
    if (isUserProfile) return history.push('/settings');
    return setMessageModal(true);
  }
  const _handleReviewModal = () => setReviewModal(true);

  const _renderMessageModal = () => {
    return (
      <ContactEntertainer
        authenticated={authenticated}
        openAuthenticationModal={openAuthenticationModal}
        data={profile}
        open={messageModal}
        onClose={() => setMessageModal(null)} />
    );
  };

  const _renderLeaveReviewModal = () => {
    return (
      <LeaveReview
        user={user}
        authenticated={authenticated}
        profile={profile}
        open={reviewModal}
        onClose={() => setReviewModal(null)} />
    );
  };
  const _profileLinkData = () => {
    let data = profileLinkData;
    if (!profile.youtubeVideoUrl) data = data.filter(item => item.label !== "Video / Audio");
    if (!profile.images) data = data.filter(item => item.label !== "Photos");
    return data;
  }

  return (
    <PageWrapper viewport={viewport}>
      <Loader visible={fetching} />
      {profile && <>
        {_renderMessageModal()}
        {user && _renderLeaveReviewModal()}
        <ProfileHero isUserProfile={isUserProfile} onButtonClick={_handleMessageModal} data={profile} />
        <NavWrapper>
          <ProfileNav links={_profileLinkData()} />
        </NavWrapper>
        <CarouselWrapper id="photos" viewport={viewport} padding>
          <Carousel data={profile.images} />
        </CarouselWrapper>
        <SectionWrapper viewport={viewport} padding>
          {_renderAboutSection()}
          {viewport === "desktop" && _renderDetailsSection()}
        </SectionWrapper>
        {_renderVideoSection()}
        {_renderReviewSection()}
        <SectionWrapper viewport={viewport} padding>{_renderContactSection()}</SectionWrapper>
        <SectionWrapper viewport={viewport} padding>
          <JoinHero />
        </SectionWrapper>
      </>}
    </PageWrapper>
  );
};

export default withManager(Profile);
