import { useEffect, useState } from "react";
import styled from "styled-components"
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { 
  PillButton, 
  DropdownSelect, 
  Text, 
  Body, 
  Checkbox, 
  TextBlock, 
  TextInput, 
  TextArea, 
  Loader, 
  Avatar, 

} from 'components';
import { colors, gradients } from 'theme'
import { SettingsNav, ImageCropper, ImageGallery, WelcomeModal } from 'modules';
import { withManager, getAddressFromCoordinates, convertPlaceToAddress } from 'utilities';
import ActionTypes from 'redux/actionTypes'
import { selectData, entertainerSectionData, careCenterSectionData, careCenterSelectData } from './data';
import { constructBody } from "./constructBody";
import { usePlacesWidget } from "react-google-autocomplete";

const inputWidth = 360;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`;

const PageWrapper = styled.div`
  display: flex;
  width: ${({ viewport }) =>  viewport === 'desktop' ? 'calc(100vw - 32px)' : viewport === 'tablet' ? 'calc(100vw - 16px)' : 'calc(100vw - 8px)'};
  margin: 4px 0 8px 0;
  border-radius: 10px;
  align-items: flex-start;
  background: ${({ isEntertainer }) => isEntertainer ? gradients.blueGradient : gradients.lightBlueGradient};
  height: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: ${({ viewport }) => viewport !== 'desktop' ? '80px' : '64px'} 16px 64px 16px;
  flex-direction: ${({ viewport }) => viewport !== 'desktop' ? 'column' : 'row'};
  max-width: 1440px;
  margin: auto;
`;

const NavColumn = styled.div`
  display: flex;
  align-items: flex-start;
  width: ${({ viewport }) => viewport !== 'desktop' ? '100%' : '25%'};
  margin-top: ${({ viewport }) => viewport !== 'desktop' ? '-64px' : '0'};
`;

const NavFixWrapper = styled.div`
  position: ${({ viewport }) => viewport !== 'desktop' ? 'fixed' : 'sticky'};
  top: ${({ viewport }) => viewport !== 'desktop' ? null : '157px'};
  z-index: 99;
  display: flex;
  flex-direction: ${({ viewport }) => viewport !== 'desktop' ? 'row' : 'column'};
  flex-wrap: wrap;
`;

const RadioGroupWrapper = styled.div`
  padding-bottom: 16px;
`;

const ContentColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: ${({ viewport }) => viewport !== 'desktop' ? '100%' : '75%'};;
  padding-left: ${({ viewport }) => viewport !== 'desktop' ? '0' : '16px'};
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  background-color: ${colors.white};
  padding: ${({ viewport }) => viewport !== 'desktop' ? '16px' : '32px'};
  margin-bottom: 16px;
  border-radius: 10px;

  h5 {
    margin-bottom: 8px;
  }
`;

const HalfWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: ${(inputWidth * 2) + 16}px;
  justify-content: space-between;
`;

const ImageUploadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Spacer = styled.div`
  display: flex;
  width: 100%;
  height: ${({ height }) => height ?? '32px'};
  border-top: ${({ border }) => border ? `1px solid ${colors.grayLight}` : 'transparent'};
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EntertainerSettings = (props) => {
  const { viewport, onClose, authenticated } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [inputs, setInputs] = useState();
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [openWelcomeModal, setOpenWelcomeModal] = useState();
  const [localFetch, setLocalFetching] = useState();
  const { ref } = usePlacesWidget({
    apiKey:'AIzaSyCGgraya3Br6GxH-2-PKgfCGdPivYS13Dg',
    onPlaceSelected: async (place) => {
      if (!place)return
      const {city, state: st, country} = convertPlaceToAddress(place.address_components);
      setInputs(state => ({ 
        ...state, 
        location: {
          city,
          state: st,
          country,
          latitude: place.geometry.location.lat(),
          longitude:place.geometry.location.lng(),
          formatted_address: place.formatted_address
        }, 
       }));
    }
  });
  // this is so we don't have to change max-width for Half-Wrapper
  let newWidth = inputWidth + 'px';
  
  const _onChange = ({ target: { name, value } }) => setInputs(state => ({ ...state, [name]: name === location ? {formatted_address: value} : value }), []);

  const { user, fetching } = useSelector(({ user }) => {
    return {
      user: user.data,
      fetching: user.fetching
    }
  })


  let isEntertainer = user?.group === "entertainers" || authenticated?.user?.attributes['custom:group'] === 'entertainers';
  let isCarecenter = user?.group === "carecenters" || authenticated?.user?.attributes['custom:group'] === 'carecenters';


  const _saveSettings = () => {
    if (!inputs) return 
      const body = constructBody(inputs, user)
      const at = localStorage.getItem('AUTH_USER_TOKEN_KEY');

      //dispatch action with body to set settings
      dispatch({ body: body, at, type: ActionTypes.user.saveSettings.START });
      setInputs()
    // }
  }

  const  getCurrentPosition = () => {
    setLocalFetching(true);
    const locationSuccess = async(response) => {
      const { latitude, longitude } = response.coords;
      const { city, state, country, formatted_address } = await getAddressFromCoordinates(latitude, longitude);
      const location = {
        city,
        state,
        country,
        latitude,
        longitude,
        formatted_address
      }
      setLocalFetching(false);
      setInputs(state => ({ ...state, location: location}));
    }
    const locationError = () => {
      setLocalFetching(false);
    }
    navigator.geolocation.getCurrentPosition(locationSuccess, locationError);
  }

  const _getSettings = () => {
    setLocalFetching(true);
    const at = localStorage.getItem('AUTH_USER_TOKEN_KEY')
    dispatch({ at, params, type: ActionTypes.user.getSettings.START })
    setLocalFetching(false);
    ;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps 
  useEffect(_getSettings, [location.pathname]);

  // render a "Welcome to Beta" modal
  useEffect(() => {
    if (!user) return;
    if (initialPageLoad && user?.group !== "carecenters"  &&
    (!user?.avatarUrl || !user?.location?.zip || !user?.categories))  {
      setOpenWelcomeModal(true)
      setInitialPageLoad(false)
    }
  }, [user, initialPageLoad]);

  const _onGalleryOrderChange = (newOrder) => {
    setInputs(state => ({ ...state, 'images': newOrder }), [])
  }

  // const _onClosePrompt = (answer) => {
  //   switch (answer){
  //     case 'yes':
  //       history.block(() => {});
  //       history.push(currentPath);
  //       break;
  //     case 'no':
  //     default:
  //       return;
  //   }
  // }

  const _renderCareCenterSettings = () => (
    <ContentColumn viewport={viewport}>
      {viewport !== 'desktop' && <Spacer height='64px' />}
      <TextBlock
        maxWidth='572px'
        color={colors.white}
        primitive="h2"
        titleSize='xlarge'
        title='Account Settings'
        subtitle='Here is where you will find all of the information that will display on your business profile page.' />
      <Spacer height='16px' />
      <Spacer height='16px' />

      {/* Basic Info */}
      <SectionWrapper viewport={viewport} id={careCenterSectionData[0].hash}>
        <Text
          bold
          primitive='h5'
          size="small"
          color={colors.pink}>{careCenterSectionData[0].title}</Text>
        <TextInput
          onChange={_onChange}
          name='name'
          width={newWidth}
          defaultValue={user?.name}
          label="Contact Name" />
        <TextInput
          onChange={_onChange}
          name='displayName'
          width={newWidth}
          defaultValue={(!user?.displayName || user?.displayName.length === 0) ? user.name : user?.displayName}
          label="Care Center Name" />
        <TextInput
          onChange={_onChange}
          name="email"
          width={newWidth}
          label="Contact Email"
          defaultValue={(!user?.contactEmail || user?.contactEmail.length === 0) ? user.email : user?.contactEmail}
          helperText="This is the email the contact button on your Profile page will send messages to. If you do not provide one, it will default to the email associated with your account." />
        <TextInput
          inputRef={ref}
          onChange={_onChange}
          name="locationInput"
          width={newWidth}
          label="Location"
          value={inputs?.location?.formatted_address}
          defaultValue={user?.location?.formatted_address}
          placeholder="Location"
          disabled={localFetch}
          currentLocationLink
          currentLocationClick={getCurrentPosition} />
        <Spacer/>
        <DropdownSelect
          onChange={_onChange}
          defaultValue={user && user.categories && user.categories[0].charAt(0).toUpperCase() + user.categories[0].slice(1)}
          width={newWidth}
          label="Type of Care Center"
          placeholder="Elder Care, Rehabilitation, etc..."
          data={careCenterSelectData} />
        <Spacer/>
        <HalfWrapper>
          <TextArea
            onChange={_onChange}
            name="description"
            defaultValue={user?.description}
            label="About"
            maxLength="350"
            helperText="350 Character Limit" />
        </HalfWrapper>
        <PillButton
          disabled={!inputs}
          onClick={_saveSettings}
          color={colors.pink}
          size='large'
          label='Save' />
      </SectionWrapper>
    </ContentColumn>
  );


  const _renderEntertainerSettings = () => (
    <ContentColumn viewport={viewport}>
      {/* <Notification visible type="warning" title="Fill out your profile to go live!" message="Youre leaving some fields behind. Please be sure to fill out all of the fields in the Basic Information section in order for your profile to go live."/> */}
      {viewport !== 'desktop' && <Spacer height='64px' />}
      <TextBlock
        maxWidth='572px'
        color={colors.white}
        primitive="h2"
        titleSize='xlarge'
        title='Profile Settings'
        subtitle='Here is where you will find all of the information that will display on your business profile page.' />
      <Spacer height='32px' />

      {/* Basic Info */}
      <SectionWrapper
        viewport={viewport}
        id={entertainerSectionData[0].hash}>
        <Text
          bold
          primitive='h5'
          size="small"
          color={colors.pink}>{entertainerSectionData[0].title}</Text>
        <HalfWrapper>
          <TextInput
            onChange={_onChange}
            name='displayName'
            width={newWidth}
            defaultValue={(!user?.displayName || user?.displayName.length === 0) ? user.name : user?.displayName}
            label="Display Name" />
        </HalfWrapper>
        <AvatarWrapper>
          <Avatar src={user?.avatarUrl} />
          <ImageCropper
            type="avatar"
            id={user.id}
            onClose={onClose}
            avatarUrl={user.avatarUrl}
          />
        </AvatarWrapper>
        <Spacer />

        <HalfWrapper>
          <TextInput
            onChange={_onChange}
            name="email"
            width={newWidth}
            label="Contact Email"
            defaultValue={(!user?.contactEmail || user?.contactEmail.length === 0) ? user.email : user?.contactEmail}
            helperText="This is the email the contact button on your Profile page will send messages to." />
          <TextInput
            onChange={_onChange}
            name="websiteUrl"
            width={newWidth}
            label="Website"
            defaultValue={user?.websiteUrl}
            placeholder="www.yourwebsitehere.com" />
        </HalfWrapper>
        <HalfWrapper>
          <TextInput
            inputRef={ref}
            onChange={_onChange}
            name="location"
            width={newWidth}
            label="City"
            placeholder="12345"
            value={inputs?.location?.formatted_address}
            defaultValue={user?.location?.formatted_address}
            disabled={localFetch}
            currentLocationLink
            currentLocationClick={getCurrentPosition} />
          <TextInput
            type='number'
            onChange={_onChange}
            name="travelDistance"
            defaultValue={user?.travelDistance}
            width={newWidth}
            label="Maximum Distance (in miles)"
            placeholder="50" />
          <Body size="small">
            <span style={{ color: colors.pink }}>**</span>Our location services are currently only available in the United States & some parts of Canada. We are working on making our platform available internationally.
          </Body>
        </HalfWrapper>
        <Spacer />
        <Spacer border />
        <RadioGroupWrapper>
          <Body
            bold
            color={colors.blue}
            size="small">
            Do you offer Virtual Entertainment?
          </Body>
          <Body
            primitive='p'
            color={colors.darkGray}
            size="xsmall">
            (Via Zoom, Google Meet, Skype, etc.)
          </Body>
          <Spacer height={'8px'} />
          <Checkbox
            name='virtual'
            onChange={_onChange}
            defaultValue={user?.location?.virtual}
            label="Yes, I offer virtual entertainment." />
        </RadioGroupWrapper>

      </SectionWrapper>

      {/* Category Info */}
      <SectionWrapper viewport={viewport} id={entertainerSectionData[1].hash}>
        <Text bold primitive='h5' size="small" color={colors.pink}>{entertainerSectionData[1].title}</Text>
        <HalfWrapper>
          <DropdownSelect
            name="category"
            onChange={_onChange}
            defaultValue={user?.categories && user.categories[0]}
            width={newWidth}
            label="Choose an Entertainment Category"
            placeholder="Musician, Magician, etc..."
            data={selectData} />
        </HalfWrapper>
        <HalfWrapper>
          <TextInput
            onChange={_onChange}
            name="tags"
            maxLength="50"
            defaultValue={user?.tags?.join()}
            width={newWidth}
            label="Tags" />
          <Body size="xsmall">Tags help people find you when they’re searching for specific types of entertainment. Use commas to separate words. (Example: Acoustic, 90s, Live, Rock)</Body>
        </HalfWrapper>
      </SectionWrapper>

      {/* Photo Gallery */}
      <SectionWrapper viewport={viewport} id={entertainerSectionData[2].hash}>
        <Text bold primitive='h5' size="small" color={colors.pink}>{entertainerSectionData[2].title}</Text>
        <Body>Press the 'X' on the image to delete it so that you can add a new one.</Body>
        <Body>Drag the photos around in the order your want them to appear on your profile.</Body>
        <ImageUploadWrapper>
          <ImageGallery
            onOrderChange={_onGalleryOrderChange}
            images={user.images}
          />
        </ImageUploadWrapper>
      </SectionWrapper>

      {/* About Section */}
      <SectionWrapper viewport={viewport} id={entertainerSectionData[3].hash}>
        <Text bold primitive='h5' size="small" color={colors.pink}>{entertainerSectionData[3].title}</Text>
        <HalfWrapper>
          <TextArea
            onChange={_onChange}
            name="description"
            defaultValue={user?.description}
            label="About"
            maxLength="350"
            helperText="350 Character Limit" />
        </HalfWrapper>
      </SectionWrapper>

      {/* Detailed Information */}
      <SectionWrapper viewport={viewport} id={entertainerSectionData[4].hash}>
        <Text bold primitive='h5' size="small" color={colors.pink}>{entertainerSectionData[4].title}</Text>
        <HalfWrapper>
          <TextInput
            onChange={_onChange}
            name="requirements"
            defaultValue={user?.details?.requirements}
            width={newWidth}
            label="Requirements" />
          <TextInput
            onChange={_onChange}
            name="equipment"
            defaultValue={user?.details?.equipment}
            width={newWidth}
            label="Equipment" />
          <TextArea
            onChange={_onChange}
            name="other"
            maxLength="150"
            helperText="150 Character Limit"
            defaultValue={user?.details?.other}
            label="Other Details" />
        </HalfWrapper>
        <HalfWrapper>
          <Spacer border />
          <TextInput
            onChange={_onChange}
            name="pricing"
            defaultValue={user?.details?.pricing}
            width={newWidth}
            label="Pricing" />
        </HalfWrapper>
      </SectionWrapper>

      {/* YouTube Video */}
      <SectionWrapper viewport={viewport} id={entertainerSectionData[5].hash}>
        <Text bold primitive='h5' size="small" color={colors.pink}>{entertainerSectionData[5].title}</Text>
        <HalfWrapper>
          <TextInput
            onChange={_onChange}
            name="youtubeVideoUrl"
            defaultValue={user?.youtubeVideoUrl}
            width={newWidth}
            label="YouTube Video Link"
            helperLink="How do I find this?"
            helperLinkHref="https://support.google.com/youtube/answer/171780?hl=en"
            helperText='example: https://youtu.be/2nrDi1T1hwE' />
        </HalfWrapper>
      </SectionWrapper>

      {/* Social Media */}
      <SectionWrapper viewport={viewport} id={entertainerSectionData[6].hash}>
        <Text bold primitive='h5' size="small" color={colors.pink}>{entertainerSectionData[6].title}</Text>
        <HalfWrapper>
          <TextInput
            onChange={_onChange}
            name="facebookUrl"
            defaultValue={user?.socialMedia?.facebookUrl}
            width={newWidth}
            label="Facebook Link" />
          <TextInput
            onChange={_onChange}
            name="instagramUrl"
            defaultValue={user?.socialMedia?.instagramUrl}
            width={newWidth}
            label="Instagram Link" />
          <TextInput
            onChange={_onChange}
            name="twitterUrl"
            defaultValue={user?.socialMedia?.twitterUrl}
            width={newWidth}
            label="Twitter Link" />
          <TextInput
            onChange={_onChange}
            name="spotifyUrl"
            defaultValue={user.socialMedia?.spotifyUrl}
            width={newWidth}
            label="Spotify Link" />
          <TextInput
            onChange={_onChange}
            name="youtubeUrl"
            defaultValue={user.socialMedia?.youtubeUrl}
            width={newWidth}
            label="YouTube Channel Link" />
          <TextInput
            onChange={_onChange}
            name="soundcloudUrl"
            defaultValue={user.socialMedia?.soundcloudUrl}
            width={newWidth}
            label="SoundCloud Link" />
          <TextInput
            onChange={_onChange}
            name="additionalLink"
            defaultValue={user?.additionalLink}
            width={newWidth}
            label="Other Link" />
        </HalfWrapper>
        <PillButton
          disabled={!inputs}
          onClick={_saveSettings}
          color={colors.pink}
          size='large'
          label='Save' />
      </SectionWrapper>
    </ContentColumn>
  );


  let newData = isEntertainer ? entertainerSectionData : careCenterSectionData;

  return (
    <Wrapper>
      {/* <YesNoModal
        question="Are you sure you want to leave without saving your changes?" 
        yesLabel="Yes"
        noLabel="No"
        open={showPrompt} 
        onClose={_onClosePrompt}
      /> */}
      <WelcomeModal open={openWelcomeModal} onClose={()=>setOpenWelcomeModal(false)}/>
      <Loader visible={fetching} />
      <PageWrapper viewport={viewport} isEntertainer={isEntertainer}>
        <InnerWrapper viewport={viewport}>

          <NavColumn viewport={viewport}>
            <NavFixWrapper viewport={viewport}>
              <SettingsNav entertainer={isEntertainer} data={newData} />
              <div style={{ width: viewport !== 'desktop' ? '16px' : null }}></div>
              <PillButton disabled={!inputs} onClick={_saveSettings} color={colors.pink} size='large' label='Save' />
            </NavFixWrapper>

          </NavColumn>
          {isEntertainer &&
            _renderEntertainerSettings()}
          {!isEntertainer && isCarecenter &&
            _renderCareCenterSettings()}
        </InnerWrapper>
      </PageWrapper>
    </Wrapper>

  )
}

export default withManager(EntertainerSettings);