import { useEffect, useState } from 'react';
import styled from "styled-components"
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { usePlacesWidget } from "react-google-autocomplete";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { PageHero, ProfileCard } from 'modules';
import { Checkbox, DropdownSelect, PillButton, Text, TextInput, Loader, Body } from "components";
import { colors, gradients } from "theme";
import {getAddressFromCoordinates, getCoordinatesFromSearch, withManager} from 'utilities';
import ActionTypes from 'redux/actionTypes';

let selectData = [
	{
		value: 'All',
		children: 'All'
	},
	{
		value: 'Musician',
		children: 'Musician'
	},
	{
		value: 'Magician',
		children: 'Magician'
	},
	{
		value: 'Storyteller',
		children: 'Storyteller'
	},
	{
		value: "Educator",
		children: 'Educator'
	},
	{
		value: "Performer",
		children: 'Performer'
	},
	{
		value: "Other",
		children: 'Other'
	}
];

const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
`;

const CardsContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
`;

const CardWrapper = styled.div`
    margin-right: ${({ viewport }) => viewport !== 'mobile' ? '16px' : '0'};
    margin-bottom: ${({ viewport }) => viewport !== 'mobile' ? '32px' : '0'};
    &:nth-child(3n) {
        margin-right: ${({ viewport }) => viewport === 'desktop' ? '16px' : '0'};
    }
`;

const DropdownWrapper = styled.div`
    display: flex;
    padding-bottom: 36px;
    align-items: ${({ viewport }) => viewport !== 'desktop' ? 'flex-start' : 'center'};
    flex-direction: ${({ viewport }) => viewport !== 'desktop' ? 'column' : 'row'};
`;

const Wrapper = styled.div`
    display: flex;
    padding-left: 16px; 
    width: 100%;
    min-width: 190px;
    ${({ viewport }) => viewport !== 'desktop' && `
        padding: 0;
    `}
 `;

const ButtonWrapper = styled.div`
    display: flex;
    padding: 0 16px; 
    border-right: 1px solid ${colors.grayLight};
${({ viewport }) => viewport !== 'desktop' && `
    padding-right: 0;
    padding-left: 8px;
    border: none;
    padding-bottom: 16px;
`}
`;

const CurrentLocationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: ${({ viewport }) => viewport !== 'desktop' && 16}px;
    width: 100%;
`;

const LocationWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1272px;
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    justify-content: center;
`;

const Search = (props) => {
	const { viewport } = props;
	const history = useHistory();
	const { pathname, search } = useLocation();
	const dispatch = useDispatch();
	const decodeQuery = () => search && JSON.parse('{"' + search.substring(1).replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) });
	const query = decodeQuery();
	let searchParams = new URLSearchParams(search);
	const { ref } = usePlacesWidget({
    apiKey:'AIzaSyCGgraya3Br6GxH-2-PKgfCGdPivYS13Dg',
    onPlaceSelected: async (place) => {
			if (!place || !place.geometry || !place.formatted_address) return;
			setAddress(place.formatted_address)
			setCoordinates({latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng()})
    }
  });
	const {
    getPlacePredictions,
		placePredictions,
		isPlacePredictionsLoading
  } = usePlacesService({
    apiKey: 'AIzaSyCGgraya3Br6GxH-2-PKgfCGdPivYS13Dg',
  });

	const [localFetch, setFetching] = useState(false);
	const [categoryFilter, setCategory] = useState(query.category ? query.category : 'All');
	const [virtualFilter, setVirtual] = useState(query.virtual ? query.virtual === 'true' : undefined);
	const [address, setAddress] = useState()
	const [coordinates, setCoordinates] = useState();
	const [ showMessage, setShowMessage] = useState();
	const { filteredResults, searching } = useSelector(({ profile }) => ({ filteredResults: profile.filteredResults, searching: profile.searching }));

	useEffect(()=>{
		dispatch({query, type: ActionTypes.profile.search.START})
				//eslint-disable-next-line
	},[search])

	useEffect(()=>{
		if (categoryFilter === 'all' || categoryFilter === 'All') {			
			searchParams.delete("category");
			history.push({path: pathname, search: searchParams.toString()})
		} else {
			let cat = searchParams.get("category");
			if (cat === categoryFilter) return
			searchParams.set("category", categoryFilter);
			history.push({path: pathname, search: searchParams.toString()})
			}
				//eslint-disable-next-line
	},[categoryFilter])

	useEffect(()=>{
		if (virtualFilter && !query.virtual) {
			searchParams.set("virtual", "true");
			searchParams.delete("lat")
			searchParams.delete("long")

		}
		if (!virtualFilter && query.virtual) {
		searchParams.delete("virtual")
		// zipCode && searchParams.set("zip", zipCode)
		};

		history.push({path: pathname, search: searchParams.toString()})
		//eslint-disable-next-line
	},[virtualFilter]);


	const _onClickUseCurrentLocation = () => {
		setFetching(true);
		const onSuccess = async (value) => {
			const { latitude, longitude } = value.coords;
			setCoordinates({latitude, longitude})
			const {formatted_address} = await getAddressFromCoordinates(latitude, longitude);
			setAddress(formatted_address)
			setFetching(false);
		}
		const onError = () => {
			setFetching(false);
		}
		navigator.geolocation.getCurrentPosition(onSuccess, onError);
	}


	const _executeSearch = async() => {
		const addy = placePredictions[0].description
		const {lat, long } = await getCoordinatesFromSearch(addy);
		setAddress(addy);
		setCoordinates({latitude: lat, longitude: long});
		!showMessage && setShowMessage(addy);
		searchParams.set('lat', lat)
		searchParams.set('long', long)
		history.push({
			pathname: pathname,
			search: searchParams.toString()
		})
		setFetching(false)
	}

	useEffect (()=>{
		if (placePredictions.length > 0) _executeSearch()
		//eslint-disable-next-line
	},[placePredictions])

	const _onClickLocationSearch = async() => {

		/**
		 * if there are no coordinates (google maps api wasnt run and the user didnt use the 'get location' browser query)
		 * AND there was user input (the address field was not undefined) we have to run the google query on the 
		 * entry. with that, it will return a city and we can say a "did you mean buffalo ny?" if the user inputs 'buff'
		 */


		/**this first if statement will send the request to google places with the input looking for a place, 
		 * once it reutrns with places the side effecft will be called and execute the search.
		 * this is set up this way so we dont make an api call for every keypress
		 * 
		 * */
		if ((!coordinates?.latitude || !coordinates?.longitude) && address?.length > 0) {
			setFetching(true)
			getPlacePredictions({input: address, types: ['(cities)']});

		} else {
			coordinates?.latitude && searchParams.set('lat', coordinates.latitude);
			coordinates?.longitude && searchParams.set('long', coordinates.longitude);
			showMessage && setShowMessage(false);
			if(!coordinates){
				searchParams.delete('long')
				searchParams.delete('lat')
			}
			history.push({
				pathname: pathname,
				search: searchParams.toString()
			})
		}
	};

	const _onChangeLocation = (e) => {
		if (coordinates) setCoordinates();
		setAddress(e.target.value)
		
		if (e.target.value === '' || !e.target.value ){
			setCoordinates(null);
		}
	}

	return (
		<>
			<Loader visible={searching || localFetch} />
			<PageWrapper viewport={viewport}>
				<PageHero title="Find the right entertainment for your care community" gradient={gradients.triColorGradient} />
				<ContentWrapper>
					<Text bold size="medium">Filter by</Text>
					<DropdownWrapper viewport={viewport}>
						<div style={{ paddingBottom: 23, width: '100%' }}>
							<DropdownSelect
								label="Category"
								labelColor={colors.pink}
								data={selectData}
								value={categoryFilter.charAt(0).toUpperCase() + categoryFilter.slice(1)}
								defaultValue={categoryFilter.charAt(0).toUpperCase() + categoryFilter.slice(1)}
								onChange={item => setCategory(item.target.value.toLowerCase())}
								placeholder="All" />
						</div>
						<LocationWrapper>
							<Wrapper viewport={viewport}>
								<CurrentLocationWrapper viewport={viewport}>
									<TextInput
										inputRef={ref}
										label="City"
										labelColor={colors.pink}
										placeholder={virtualFilter ? "Virtual Only" : "Start typing a location..."}
										value={address}
										onChange={_onChangeLocation}
										disabled={virtualFilter || localFetch}
										currentLocationLink
										currentLocationClick={_onClickUseCurrentLocation} />
								</CurrentLocationWrapper>
							</Wrapper>
							<ButtonWrapper viewport={viewport}>
								<PillButton 
								label="Submit" 
								onClick={e => virtualFilter ? null : _onClickLocationSearch()} 
								disabled={virtualFilter || localFetch  || isPlacePredictionsLoading } 
								/>
							</ButtonWrapper>
						</LocationWrapper>
						<Wrapper viewport={viewport}>
							<Checkbox label="Virtual Entertainers Only" checked={virtualFilter} onChange={e => setVirtual(e.target.value)} />
						</Wrapper>
					</DropdownWrapper>
					{showMessage && 
					 <>
						<Body color={colors.black} style={{}}>{`Did you mean ${showMessage}?`}
						</Body><Body style={{paddingBottom: '12px'}}> {`Showing results for ${showMessage}.`}</Body></>}
					<CardsContainer viewport={viewport}>

						{filteredResults?.map(item => {
							return (
								<CardWrapper key={item.id} viewport={viewport}>
									<ProfileCard
										id={item.id}
										imageSource={item.avatarUrl}
										name={(!item.displayName || item.displayName.length === 0) ? item.name : item.displayName}
										categories={item.categories}
										location={item.location}
										tags={item.tags}
										profileId={item.id}
										rating={item.reviews?.overallRating}
										virtual={item.location?.virtual} />
								</CardWrapper>)
						})}
					</CardsContainer>
				</ContentWrapper>
			</PageWrapper>
		</>
	)
}

export default withManager(Search);