const API_ROUTE = `https://serverless.carecenterstage.com`;
// const API_ROUTE = `http://localhost:6006`;
const API_CONFIG = {
	PROFILE_BY_ID: { path: '/profile/:id' },
	PROFILE_FEATURED: { path: '/profile/featured' },
	PROFILE_SEARCH: { path: '/profile/search'},
	USER_INIT: {path: '/user/init', auth: true},
	USER_GET_SETTINGS: {path: '/user/settings', auth: true},
	USER_SAVE_SETTINGS: {path: '/user/settings', auth: true},
	USER_SET_AVATAR: {path: '/user/avatar', auth: true},
	USER_GET_NAVIGATION: {path: '/user/navigation', auth: true},
	ENTERTAINER_CONTACT: { path: '/contact/entertainer', auth: true},
	ADD_REVIEW: { path: '/review/add-review', auth: true},
	USER_SAVE_IMAGE: { path: '/user/image', auth: true},
	USER_DELETE_IMAGE: { path: '/user/image', auth: true},
	USER_DELETE_ACCOUNT: { path: '/user/deleteUser', auth: true}
};


export { API_ROUTE, API_CONFIG };
