import { useEffect } from 'react';
import styled from 'styled-components';
import { Text } from 'components';
import { colors } from 'theme';
import { useHistory, useLocation } from 'react-router';
import { withManager } from 'utilities';

const propTypes = {};

const defaultProps = {};

const ProfileNavWrapper = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 42px;
    border-radius: 42px;
    box-shadow: 0px 6px 12px ${colors.navyBlue}30;
    height: 81px;
    background-color: rgba(255, 255, 255, 0.75);
		backdrop-filter: saturate(180%) blur(10px);
    margin: 0;
    width: 100%;
    max-width: 768px;
	${({ viewport }) => viewport !== 'desktop' && `
		flex-wrap: wrap;
		height: auto;
		border-radius: 0px;
		justify-content: space-around;
		padding: 16px 32px;
	`};
`;

const LinkWrapper = styled.li`
		position: relative;
    margin: 0;
    list-style-type: none;
    cursor: pointer;
`;

const Link = styled.a`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	padding-right: ${({ viewport }) => viewport !== 'desktop' ? 16 : 36}px;
	padding-bottom: ${({ viewport }) => viewport !== 'desktop' ? 12 : 0}px;
  text-decoration: none;
	outline: none;
  &:hover {
    p {
      transition: all 0.3s ease;
      color: ${colors.pink};
    }
  }
	&:last-child {
			padding-right: 0px;
	}
	&:focus:not(:hover) li {
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			left: 50%;
			border: 1px dashed ${colors.navyBlue};
			height: calc(100% + 6px);
			width: calc(100% + 6px);
			
		}
	}
`;

const ProfileNav = props => {
	const { links, viewport } = props;
	/* active link will be determined if hash / url contains the href in the link */
	const location = useLocation();
	const history = useHistory();

	const _onKeyDown = (e, link) => (e.keyCode === 32 || e.keyCode === 13) && history.push(link.href);

	const _onClick = (e, link) => {
		history.push(link.href);
	}

	useEffect(()=>{
    if(!location.hash) return;
    const id = location.hash.substring(1)
		let elem = document.getElementById(id);
		if (elem) {
			let {top} = elem.getBoundingClientRect();
			window.scrollTo({
				top: top + window.scrollY - 85,
				behavior: "smooth"
			});
		}

  },[location.hash])
	
	return (
		<ProfileNavWrapper viewport={viewport}>
			{links?.map((link, index) => {
				let active = location.hash.includes(link.href);
				return (
					<Link key={'profile-nav-item-' + index} viewport={viewport} onClick={e => _onClick(e, link)} tabIndex={0} onKeyDown={e => _onKeyDown(e, link)}>
						<LinkWrapper key={index}>
							<Text color={active ? colors.pink : colors.blue} size="xxsmall" type="text">
								{link.label}
							</Text>
						</LinkWrapper>
					</Link>
				)
			})}
		</ProfileNavWrapper>
	)
}

ProfileNav.propTypes = propTypes;
ProfileNav.defaultProps = defaultProps;

export default withManager(ProfileNav);