import styled from 'styled-components';
import { colors } from 'theme'
import { Body } from 'components';

const OptionWrapper = styled.button`
  background: transparent;
  width: 100%;
  border: none;
  padding: 0px 16px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${colors['off-white']};
    p {
      color: ${colors.pink};
    }
  }
  &:focus {
    p {
      color: ${colors.pink};
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 12px 0px;
  border-bottom: ${({ lastItem }) => lastItem ? 'none' : '1px solid ' + colors.grayLight};

  p {
      letter-spacing: 0px;
  }
`;


const Option = (props) => {
  const { children, selected, listItem } = props;
  return (
    <OptionWrapper
      {...props}
      role="option"
      aria-selected={selected}>
      <TextWrapper lastItem={listItem}>
        <Body size='small' color={colors.navyBlue}>{children}</Body>
      </TextWrapper>
    </OptionWrapper>
  )
};

export default Option;