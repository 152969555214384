let homeData = {
  hero: {
    title: 'Find entertainment for your care community.',
    text: 'Your care community deserves high-quality entertainment. We make it easy for you to connect with talented performers from all over the world.',
  },
  intro: {
    title: 'Better connections for a brighter community.',
    text: 'They say that music is the language of the spirit. That laughter is the best medicine. That entertainment provides relief and art provokes engagement. Are you ready to energize and uplift your care community? Now you can find entertainers who perform exclusively for you.',
  },
  entertainers: {
    title: 'Featured entertainers.',
    text: 'Get a hold of the hottest acts in care center entertainment!',
  },
  categories: {
    title: 'Simplify your search.',
    text: 'Are you looking for a comedian or a crooner? A musician or a magician? A storyteller or a sculptor? Select the type of entertainment you’re looking for and then filter your results from there.',
  },
  affiliates: {
    title: 'Meet our affiliates.',
  }
};

let selectData = [
  {
    value: 'Musician',
    children: 'Musician'
  },
  {
    value: 'Magician',
    children: 'Magician'
  },
  {
    value: 'Storyteller',
    children: 'Storyteller'
  },
  {
    value: "Educator",
    children: 'Educator'
  },
  {
    value: "Performer",
    children: 'Performer'
  },
  {
    value: "Other",
    children: 'Other'
  },
];

let featuredEntertainers = [
  {
    displayName: 'Eric Robinson',
    avatarUrl: 'https://ca.slack-edge.com/T029HBGCE04-U029PQRE801-c6ca5e5f77ac-256',
    categories: ['Musician'],
    "location": {
      "city": "Berlin",
      "state": "Wyoming",
      "zip": 31195,
      "latitude": 56.696792,
      "longitude": 132.477488
    },
    tags: ['acoustic', 'funny', 'so good', 'the best', '90s'],
    rating: 3.8,
  },
  {
    displayName: 'Nathan Myers',
    avatarUrl: 'https://pbs.twimg.com/profile_images/1305276067280814091/kJvlNOZV_400x400.jpg',
    categories: ['Performer'],
    "location": {
      "city": "Berlin",
      "state": "Wyoming",
      "zip": 31195,
      "latitude": 56.696792,
      "longitude": 132.477488
    },
    tags: ['smart', 'reallysmart', 'supersmart', 'smartest'],
    rating: 4.4
  },
  {
    displayName: 'Tyler Siskar',
    avatarUrl: 'https://ca.slack-edge.com/TBUNR2G8P-UPJPD81C0-4a978aaa9924-512',
    categories: ['Musician'],
    "location": {
      "city": "Berlin",
      "state": "Wyoming",
      "zip": 31195,
      "latitude": 56.696792,
      "longitude": 132.477488
    },
    tags: ['hip-hop', 'mumble rap'],
    rating: 4.1
  },
  {
    displayName: 'Hello Puppet',
    avatarUrl: 'https://randomuser.me/api/portraits/men/75.jpg',
    categories: ['Musician'],
    "location": {
      "city": "Berlin",
      "state": "Wyoming",
      "zip": 31195,
      "latitude": 56.696792,
      "longitude": 132.477488
    },
    tags: ['acoustic', 'funny', '70s', '80s'],
    rating: 3.7
  },
  {
    displayName: 'Nitty Gritty',
    avatarUrl: 'https://randomuser.me/api/portraits/men/76.jpg',
    categories: ['Storyteller'],
    "location": {
      "city": "Berlin",
      "state": "Wyoming",
      "zip": 31195,
      "latitude": 56.696792,
      "longitude": 132.477488
    },
    tags: ['acoustic', 'funny', '70s', '80s', '90s'],
    rating: 4.9
  },
  {
    displayName: 'Incognito Mosquito',
    avatarUrl: 'https://randomuser.me/api/portraits/men/77.jpg',
    categories: ['Magician'],
    "location": {
      "city": "Berlin",
      "state": "Wyoming",
      "zip": 31195,
      "latitude": 56.696792,
      "longitude": 132.477488
    },
    tags: ['acoustic', 'funny', '70s', '80s', '90s'],
    rating: 5
  }

];

export { homeData, selectData, featuredEntertainers };
