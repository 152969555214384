import styled from 'styled-components';
import { Icon } from 'components';
import { colors } from 'theme';
import PropTypes from 'prop-types';
import { withManager } from 'utilities';

const propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool
},
defaultProps = {
  color: colors.pink,
  disabled: false
};

const HEIGHT = 48
const BORDER_RADIUS = 10

const SHADOW_OPACITY = '50'
const SHADOW_OPACITY_HOVER = '30'
const BOX_SHADOW = '0 1px 2px 0 '
const BOX_SHADOW_HOVER = '0 12px 24px ';

function _handlePadding(size) {
  let padding =
    size === 'small'
      ? '8px 16px'
      : size === 'medium'
        ? '12px 24px'
        : size === 'jumbo'
          ? '32px 64px'
          : '16px 32px'
  return padding;
}

function _handleFontSize(size) {
  let fontSize =
    size === 'small'
      ? '12px'
      : size === 'medium'
        ? '14px'
        : size === 'jumbo'
          ? '24px'
          : '16px'
  return fontSize;
}

const Text = styled.span`
  font-family: dunbar-text , Helvetica, sans-serif;
  font-size: ${({ size }) => _handleFontSize(size)};
  letter-spacing: 0.62px;
  text-transform: uppercase;
  font-weight: 700;
  color: ${colors.white};
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-align: center;
`;

const Wrapper = styled.a`
  position: relative;
  position: fixed;
  z-index: 6;
  right: ${({ viewport }) => viewport !== 'desktop' ? '4px' : '8px'};
  bottom: 24px;
  text-decoration: none;
  border: none;
  padding: ${({ size }) => _handlePadding(size)};
  display: flex;
  width:  fit-conten;
  height: ${HEIGHT}px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${({color}) => color};
  color: ${colors.white};
  border-radius: ${BORDER_RADIUS}px;
  outline: none;
  box-shadow: ${({ color, disabled }) => disabled ? 'none' : BOX_SHADOW + color + SHADOW_OPACITY};
  cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
  ${({ disabled }) => disabled && `
    touch-action: none;
    pointer-events: none;
  `}
  ${({ disabled }) => !disabled && `
  transition: all .3s ease-in-out;
  &:hover {
    transform: scale(1.05);
    box-shadow: ${({ color }) => BOX_SHADOW_HOVER + color + SHADOW_OPACITY_HOVER};
  };
  
  &:focus {
    &::after {
      position: absolute;
      content: '';
      z-index: 100;
      top: 50%;
      left: 50%;
      bottom: 50%;
      right: 50%;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      border: 1px dashed black;
      transform: translate(-50%,-50%);
      border-radius: ${BORDER_RADIUS}px;
    }
  }
  `}
`;

const DonateButton = (props) => {
  const { inverted, color, type, size, buttonStyle, viewport } = props;
  
  return(
    <Wrapper 
      {...props}
      viewport={viewport}
      // target="_blank"
      className="custom-dbox-popup"
      href="https://donorbox.org/care-center-stage-donate-form"
      type={type}
      buttonStyle={buttonStyle}
      size='small'
      inverted={inverted}
      color={color}>
      <div style={{ display: 'flex', justifyContent: 'center', paddingRight: '4px'}}>
        <Icon name="heart" color={colors.white}></Icon>
      </div>
      <Text inverted={inverted} size={size} buttonStyle={buttonStyle} color={color}>
        Donate
      </Text>
    </Wrapper>
  )
};

DonateButton.propTypes = propTypes;
DonateButton.defaultProps = defaultProps;
export default withManager(DonateButton);