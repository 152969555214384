import styled from "styled-components";
import PropTypes from "prop-types";
import { TextBlock, Text, DropdownSelect, PillButton } from "components";
import { colors } from "theme";
import { useState } from "react";
import { withManager } from 'utilities';

const propTypes = {
    imageSource: PropTypes.string,
};

const defaultProps = {
    imageSource: "https://images.carecenterstage.com/root/care-center-stage-entertainer-gabriel-gurrola-unsplash.jpg" 
};

const HeroContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    min-height: 80vh;
    width: ${({ viewport }) =>  viewport === 'desktop' ? 'calc(100vw - 32px)' : viewport === 'tablet' ? 'calc(100vw - 16px)' : 'calc(100vw - 8px)'};
    background-image: url(${({ background }) => background});
    background-size: cover;
    background-position: center;
    padding: ${({ viewport }) => viewport === 'desktop' ? '96px 16px 128px 16px' : '64px 16px'};
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    z-index: 2;
    margin: 16px;

    &::after { 
        background-color: ${colors.black};
        border-radius: 10px;
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 0.55; 
    }
`;

const SearchWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    width: ${({ viewport }) => viewport === 'desktop' ? '580px' : "auto"};
    height: ${({ viewport }) => viewport === 'desktop' ? '102px' : "auto"};
    padding: ${({ viewport }) => viewport === 'desktop' ? '0' : "20px"};
`;

const ItemWrapper = styled.div`
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: ${({ viewport }) => viewport === 'desktop' ? 'row' : "column"};
`;

const DropdownWrapper = styled.div`
    width: 232px;
    padding: ${({ viewport }) => viewport === 'desktop' ? 0 : '12px'} 16px;
`;

const Spacer = styled.div`
  padding-bottom: 16px;
`;

const CTAWrapper = styled.div`
    margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HomeHero = (props) => {
    const { data, imageSource, selectData, onSearch, viewport, openAuthenticationModal } = props;
    const [value, setValue] = useState(null);
    return (
        <HeroContainer 
            viewport={viewport} 
            background={imageSource}>
            <TextBlock
                align="center"
                maxWidth='572px'
                color={colors.white}
                primitive="h1"
                titleSize='xxlarge'
                title={data.title}
                subtitle={data.text}/>
            <Spacer/>
            <SearchWrapper viewport={viewport}>
                <ItemWrapper viewport={viewport}>
                    <Text size='small' color={colors.blue} bold>Find me a...</Text>
                    <DropdownWrapper viewport={viewport}>
                        <DropdownSelect data={selectData} placeholder="Musician, Magician, etc" onChange={setValue} />
                    </DropdownWrapper>
                    <PillButton label="SEARCH" onClick={() => onSearch && onSearch(value)} />
                </ItemWrapper>
            </SearchWrapper>
            <CTAWrapper>
                <Text bold size="xsmall" primitive="h4" color={colors.white}>Caregivers & Entertainers click here to sign up!</Text>
                <Spacer />
                <PillButton size="small" buttonStyle="secondary" label="Sign Up for Free" color={colors.pink} onClick={() => openAuthenticationModal('signUp')}/>
            </CTAWrapper>
        </HeroContainer>
    );
};

HomeHero.propTypes = propTypes;
HomeHero.defaultProps = defaultProps;

export default withManager(HomeHero);
