import styled from 'styled-components';
import { colors } from 'theme';
import { Icon } from '../../icons';
import PropTypes from 'prop-types';

const propTypes = {
	label: PropTypes.string,
  color: PropTypes.string,
},
defaultProps = {
  color: colors.pink,
};

const BORDER_RADIUS = 10

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: all .3s ease-in-out;
  cursor: pointer;
  `;

const Text = styled.span`
  font-family: dunbar-text, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 1.62px;
  font-weight: 700;
  line-height: 30px;
  color: ${({ color }) => color};
`;

const Wrapper = styled.a`
  position: relative;
  display: flex;
  flex-direction: row;
  border: none;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-transform: uppercase;
  
  ${Text} {
    transition: all .3s ease-in-out;
    padding-right: 4px;
  }

  &:hover {
    ${Text} {
      cursor: pointer;
      letter-spacing: 2px;
      padding-right: 8px;
    }
  };
  
  &:focus {
    &::after {
      position: absolute;
      content: '';
      z-index: 100;
      top: 50%;
      left: 50%;
      bottom: 50%;
      right: 50%;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      border: 1px dashed black;
      transform: translate(-50%,-50%);
      border-radius: ${BORDER_RADIUS}px;
    }
  }
`;

const TextLinkArrow = (props) => {
  const { label, type, color } = props;
  const secondary = type === 'secondary'
  return (
    <Wrapper {...props} secondary={secondary}>
      <Text
        $bold
        primitive="span"
        size="textLinkArrow"
        color={color}>
        {label}
      </Text>
      <IconWrapper>
        <Icon size="medium" name="arrow-right" color={color} />
      </IconWrapper>
    </Wrapper>
  )
};

TextLinkArrow.propTypes = propTypes;
TextLinkArrow.defaultProps = defaultProps;
export default TextLinkArrow