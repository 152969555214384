import PropTypes from 'prop-types'
import styled, { keyframes } from "styled-components";
import { Text, Body, Icon  } from "components";
import { colors } from "theme";


const propTypes = {
  type: PropTypes.oneOf(['warning','info', 'error'])
}

const animateIn = keyframes`
 0% { 
    transform: translateY(-30px);
    opacity: 0.2; }
 100% { 
    transform: translateY(0); 
    opacity: 1; }
`;



const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  max-width: 792px;
  width: 100%;
  background-color: ${colors.grayLight};
  border-radius: 10px;
  border: 2px solid ${({color})=> color};
  margin-bottom: 16px;
  padding: 32px;
  box-sizing: border-box;
  animation-name: ${animateIn};
  animation-duration: 300ms;
  animation-iteration-count: once;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorTextWrapper = styled.div`
  margin-bottom: 4px;
  margin-top: 4px;
`;


const Notification = (props) => {
  const { 
    onCloseButtonClick, 
    visible, 
    title,
    message,
    type,
  } = props;

  const _calculateColor = () => {
    switch(type){
      case 'error': 
      return colors.error;
      case 'warning':
      return colors.pink
      case 'info':
        default:
      return colors.babyBlue
    }
  }

  if (!visible) return null;
  return (
    <Wrapper color={_calculateColor()}>
      <InnerWrapper>
        <Text 
          bold 
          primitive="h3" 
          size="medium" 
          color={_calculateColor()}>{title}
        </Text>
          <ErrorTextWrapper>
            <Body>{message}</Body>
          </ErrorTextWrapper>
      </InnerWrapper>
        <div 
          style={{ cursor: 'pointer' }} 
          onClick={onCloseButtonClick}>
            <Icon 
              size="large" 
              name="close" 
              color={_calculateColor()}/>
        </div>
    </Wrapper>
  );
};

Notification.propTypes = propTypes;
export default Notification; 