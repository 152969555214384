import {
  Route,
  Switch,
  // Redirect
} from "react-router-dom"
import {
  Search,
  Home,
  Settings,
  AccountSettings,
  // BillingSettings,
  Profile,
  SignUp,
  About,
  Contact,
  Support,
  // Error404,
  TermsOfService,
  PrivacyPolicy,
  
  // ComingSoon
} from "pages"

import {
  AuthenticatedRoute
} from './AuthenticatedRoute'

const MainRoutes = (props) => {
  const { authenticated, openAuthenticationModal } = props;

  const _renderSettings = () => {
    return <Settings authenticated={authenticated} openAuthenticationModal={openAuthenticationModal} />
  }

  const _renderAccountSettings = () => {
    return <AccountSettings authenticated={authenticated}/>
  }

  // removed based on new business model
  // const _renderBillingSettings = () => {
  //   return <BillingSettings authenticated={authenticated}/>
  // }

  const _renderProfilepage = () => {
    return <Profile authenticated={authenticated} openAuthenticationModal={openAuthenticationModal}/>
  }

  const _renderSearch = () => {
    return <Search />
  }

  const _renderHomepage = () => {
    return <Home authenticated={authenticated} openAuthenticationModal={openAuthenticationModal}/>
  }

  const _renderSignUp = () => {
    return <SignUp openAuthenticationModal={openAuthenticationModal} />
  }

  const _renderAbout = () => {
    return <About />
  }

  const _renderContact = () => {
    return <Contact />
  }

  const _renderSupport = () => {
    return <Support />
  }

  const _renderTermsOfService = () => {
    return <TermsOfService />
  }

  const _renderPrivacyPolicy = () => {
    return <PrivacyPolicy />
  }

  // const _renderComingSoon = () => {
  //   return <ComingSoon/>
  // }
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={_renderHomepage}
      />
      <AuthenticatedRoute
        exact
        path="/settings"
        render={_renderSettings}
      />
      <AuthenticatedRoute
        exact
        path="/account-settings"
        render={_renderAccountSettings}
      />
      <Route
        exact
        path="/sign-up"
        render={_renderSignUp}
      />
      <Route
        exact
        path="/about"
        render={_renderAbout}
      />
      <Route
        exact
        path="/contact"
        render={_renderContact}
      />
      <Route
        exact
        path="/support"
        render={_renderSupport}
      />
      <Route
        exact
        path="/terms"
        render={_renderTermsOfService}
      />
      <Route
        exact
        path="/privacy-policy"
        render={_renderPrivacyPolicy}
      />
      <Route
        exact
        path="/search"
        render={_renderSearch}
      />
      <Route
        exact
        path="/:id"
        render={_renderProfilepage}
      />
      {/* <AuthenticatedRoute
        exact
        path="/billing"
        render={_renderBillingSettings}
      /> */}
      {/* <Route path="*">
        <Error404 />
      </Route> */}
    </Switch>
  )
}

export default MainRoutes