import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { RadioButton } from 'components';

const propTypes = {
	orientation: PropTypes.oneOf(['horizontal', 'vertical'])
};

const defaultProps = {
  orientation: 'vertical'
};

const RadioButtonGroupWrapper = styled.div`
    display: flex;
    flex-direction: ${({ horizontal }) => horizontal ? 'row' : 'column'};
    &:first-child {
    	padding-bottom: 0px;
    }
`;

const RadioButtonWrapper = styled.div`
  padding: ${({ horizontal }) => horizontal ? '0 16px 0 0' : '0 0 16px 0'};
`;

const RadioButtonGroup = props => {
    const { data, onChange, orientation } = props;
		const [selected, setSelected] = useState(null);
		const horizontal = orientation === 'horizontal'


    const _onChange = e => {	
			setSelected(e.target.value);
			onChange && onChange(e);
    }
    return (
			<RadioButtonGroupWrapper 
			  horizontal={horizontal} 
				role="radiogroup">
				{data?.map((radio, index) => {
					return (
						<RadioButtonWrapper 
							key={index} 
							horizontal={horizontal}>
								<RadioButton 
									{...radio} 
									onChange={_onChange} 
									selected={selected === radio.value} 
								/>
						</RadioButtonWrapper>
					)
				})}
			</RadioButtonGroupWrapper>
    )
}

RadioButtonGroup.propTypes = propTypes;
RadioButtonGroup.defaultProps = defaultProps;

export default RadioButtonGroup;