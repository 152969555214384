let aboutData = {
  hero: {
      headerText: 'The man behind the music.',
      bodyText: 'Care Center Stage Founder & President Tom Bender is a lifelong musician from Western New York. Committed to uplifting care center communities across his home area and beyond, Tom performs an average of 400 shows at more than 50 different care centers every single year.',
  }
}
let markdownText = `
  ##### Let’s get virtual.
  When the pandemic hit in 2020, Tom met with a friend and fellow musician, Brian Beaudry, to discuss the challenges it presented to the entertainment industry.
  
  The guys quickly realized three things:  
  1.	Care center communities needed safe but satisfying alternatives to live entertainment.
  2.	Entertainers needed opportunities to secure gigs, perform for audiences, and sustain their careers.
  3.	Care Center Stage needed to develop a virtual network that could connect both.

  With the advantages of virtual performances now at the forefront of the business, Care Center Stage has evolved from a local business to a global platform.

  ##### Come together.
  Care Center Stage was built to bring care centers and entertainers together on a single, user-friendly platform. 

  On one side, caregivers can use the platform to connect with entertainers to:
  1.	Schedule live performances on-site
  2.	Stream live performances online
  3.	Access pre-recorded shows that were personalized just for them

  On the other side, entertainers can use the platform to:
  1.	Find gig opportunities at care communities around the world
  2.	Access professional marketing tools and resources
  3.	Develop their brand and promote their performances 


  ##### One love.
  Care Center Stage is the first and only website of its kind anywhere in the world. Purpose-built to connect care center communities with entertainers, our platform serves as a networking hub, resource center, and gig-finding site all in one. In other words, Care Center Stage is here to help you plug in, get ready, and enjoy the show. 

  ##### Here, there, and everywhere.
  Live music in the lounge? Book it. Live stream from Buffalo to Bakersfield? Book it. Pre-recorded show performed just for you? Book it! With Care Center Stage, you can find a family-friendly comedian for the kids, a Rolling Stones cover band for the rockers, and a classic crooner for the quiet and comfortable. Whether you’re live in the lounge or online in your lounge chair, Care Center Stage has something for everyone. 

  ##### You CAN always get what you want.
  Have we mentioned that you can get a completely, totally, 100-percent personalized show performed just for you? No more flipping through the channels until you find something decent. No more scrolling through old, commercial-filled shows on YouTube. No more recycling the same tired acts, period. At Care Center Stage, you’re connecting with a pro who can give you what you want, when you want it.
`;

export { aboutData, markdownText };
