import styled from 'styled-components'; 
import { Icon, Body } from 'components';
import { colors } from 'theme';
import { useState } from 'react';
import { ImageCropper, YesNoModal } from 'modules';
 
const Wrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  background-color: ${colors.pink};
  border-radius: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -12px;
  top: -12px;
  cursor: pointer;
  transition: all 300ms ease;

  &:hover {
    background-color: ${colors.white};
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding-bottom: 56.2%;
  background-image: ${({url})=> `url(${url})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 10px;
  border: 2px solid #f2f2f2;
`;

const ImageContainer = styled.div`
  width: 200px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ImagePlaceholder = styled.div`
  width: 200px;
  height: 100px;
  /* aspect-ratio: 16/9; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const ImageSlot = props => {
  const { url, clickClose } = props;
  const [closeHovered, setCloseHovered] = useState();
  const [closeActive, setCloseActive] = useState();
  const [openYesNoModal, setOpenYesNoModal] = useState();

  const _calcIconColor = () => {
    if (closeHovered) {
      if (closeActive) return colors.grayLight;
      else return colors.pink
    }
    return colors.white;
  }

  const _onClickClose = () => {
    setOpenYesNoModal(true)
  }

  const onCloseYesNo = (choice) => {
    setOpenYesNoModal(false);
    if (choice === 'yes' && clickClose) return clickClose(url)
    else return;

  }


  const _renderImage = () => {
    return (
      <>
        <YesNoModal
          question="Are you sure you want to delete this photo?" 
          yesLabel="Delete"
          noLabel="Cancel"
          open={openYesNoModal} 
          onClose={onCloseYesNo}/>
        <IconWrapper
          onClick={_onClickClose}
          onMouseEnter={()=>setCloseHovered(true)}
          onMouseLeave={()=>setCloseHovered(false)}
          onMouseDown={()=>setCloseActive(true)}
          onMouseUp={()=>setCloseActive(false)}>
          <Icon
            color={_calcIconColor()}
            name="close"/>
        </IconWrapper>
        <ImageContainer>
          <ImageWrapper url={url}>
          </ImageWrapper>
        </ImageContainer>
      </>
    )
  }

  const _renderPlaceholder = () => {
    return (
      <ImagePlaceholder {...props}>
        <ImageCropper/>
        <div style={{paddingTop: '8px'}}><Body size='small' color={colors.babyBlue}>up to 3</Body></div>
      </ImagePlaceholder>
    )
  }
  return (
    <Wrapper>
      {url && _renderImage()}
      {!url && _renderPlaceholder()}
      </Wrapper>
  )
};

export default ImageSlot