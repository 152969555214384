let entertainerSectionData = [
  {
    title: 'Basic Information',
    hash: 'basic-info'
  },
  {
    title: 'Category Information',
    hash: 'category-info'
  },
  {
    title: 'Photo Gallery',
    hash: 'photo-gallery'
  },
  {
    title: 'About',
    hash: 'about'
  },
  {
    title: 'Detailed Information',
    hash: 'detailed-information'
  },
  {
    title: 'YouTube Video',
    hash: 'youtube-video'
  },
  {
    title: 'Social Media',
    hash: 'social-media'
  },
]

let careCenterSectionData = [
  {
    title: 'Basic Information',
  }
]

let selectData = [
  {
    value: 'Musician',
    children: 'Musician'
  },
  {
    value: 'Magician',
    children: 'Magician'
  },
  {
    value: 'Storyteller',
    children: 'Storyteller'
  },
  {
    value: "Other",
    children: 'Other'
  },
  {
    value: "Educator",
    children: 'Educator'
  },
  {
    value: "Performer",
    children: 'Performer'
  },
];

let careCenterSelectData = [
  {
    value: 'Elder Care',
    children: 'Elder Care'
  },
  {
    value: 'Rehabilitation',
    children: 'Rehabilitation'
  },
  {
    value: 'Children',
    children: 'Children'
  },
  {
    value: "Hospice",
    children: 'Hospice'
  },
  {
    value: "Education",
    children: 'Education'
  },
  {
    value: "Other",
    children: 'Other'
  },
];

export { entertainerSectionData, careCenterSectionData, selectData, careCenterSelectData };
