import PropTypes from "prop-types";
import styled from "styled-components";
import { 
  Modal,
  PillButton,
} from "components";

const propTypes = {
  data: PropTypes.object,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
};
const defaultProps = {
  question: "Would you like to save your changes?",
  yesLabel: "Save",
  noLabel: "Cancel"
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const YesButtonWrapper = styled.div`
  padding-right: 12px;
`;

const YesNoModal = (props) => {
  const { 
    open,
    yesLabel,
    noLabel,
    onClose, 
    question, 
  } = props;



  return (
    <>
      <Modal 
        title={question}
        open={open} 
        onClose={onClose}>
          <ContentWrapper>
      <Wrapper>
      <YesButtonWrapper>
        <PillButton
          onClick={()=>onClose('yes')}
          type="submit"
          label={yesLabel} />
      </YesButtonWrapper>
      <PillButton
        onClick={()=>onClose('no')}
        type="submit"
        label={noLabel} />
      </Wrapper>
      </ContentWrapper>
    </Modal>
    </>
  )
};

YesNoModal.propTypes = propTypes;
YesNoModal.defaultProps = defaultProps;

export default YesNoModal;
