import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from 'theme';

const propTypes = {
    src: PropTypes.string,
    size: PropTypes.string,
    borderColor: PropTypes.string,
};

const defaultProps = {
    borderColor: colors.white,
    size: 'large',
    src: 'https://images.carecenterstage.com/root/defaultAvatar.jpg'
};

const AvatarComponent = styled.div`
    height: ${({ determineSize, size }) => determineSize(size)}px;
    width: ${({ determineSize, size }) => determineSize(size)}px;
    border:  ${({ borderColor, size }) =>
        size === 'small'
            ? '3px solid ' + borderColor
            : '6px solid ' + borderColor};
    box-shadow: 0px 6px 12px ${colors.navyBlue}30;
    border-radius: 50%;
    background-image: url(${({ src }) => src});
    background-size: cover;
    transition: all .3s ease-in;
`;

const _determineSize = size => {
    switch (size) {
        case 'small':
            return 65;
        case 'jumbo':
            return 250;
        case 'large':
        default:
            return 150;
    }
}

const Avatar = props => {
    return (
        <AvatarComponent {...props} src={props.src} determineSize={_determineSize} />
    )
}

Avatar.propTypes = propTypes;
Avatar.defaultProps = defaultProps;

export default Avatar;