import { useEffect } from 'react';
import styled from "styled-components";
import { TextBlock, Markdown } from 'components';
import { colors, gradients } from 'theme'
import { withManager } from 'utilities';
import { contactMarkdown } from './data';

const ContactPage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`;

const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 20vh;
  width: ${({ viewport }) =>  viewport === 'desktop' ? 'calc(100vw - 32px)' : viewport === 'tablet' ? 'calc(100vw - 16px)' : 'calc(100vw - 8px)'};
  background: ${gradients.lightBlueGradient};
  padding: ${({ viewport }) => viewport === 'desktop' ? '96px 16px 128px 16px' : '64px 16px'};
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin: 16px;
`;

const SpaceWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  height: 32px;
`;

const Contact = (props) => {
  const { viewport } = props;
  const _scrollToTop = () => {
    window.scrollTo({
      top: 0,})
  }

  useEffect(_scrollToTop)

  return (
    <ContactPage viewport={viewport}>
      <HeroContainer viewport={viewport}>
        <TextBlock
          align="center"
          maxWidth='572px'
          color={colors.white}
          primitive="h1"
          titleSize='xxlarge'
          title="Contact Us"/>  
      </HeroContainer>

      <Markdown children={contactMarkdown}/>

      <SpaceWrapper/>
        
    </ContactPage>
  )
}

export default withManager(Contact);