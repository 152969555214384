import styled from "styled-components";
import PropTypes from "prop-types";
import { Text, Avatar, PillButton, Body } from "components";
import { colors, gradients } from "theme";
import { withManager } from "utilities";

const propTypes = {
	data: PropTypes.shape({
		avatarUrl: PropTypes.string,
		displayName: PropTypes.string,
		location: PropTypes.object,
		categories: PropTypes.arrayOf(PropTypes.string),
		tags: PropTypes.arrayOf(PropTypes.string)
	})
};

const defaultProps = {};

const
	PADDING_TOP = '64px',
	PADDING_SIDES = '32px',
	BORDER_RADIUS = '10px';

const ComponentWrapper = styled.div`
  padding: ${PADDING_TOP} 0;
	border-radius: ${BORDER_RADIUS};
  display: flex;
  flex-direction: column;
  align-items: center;
	justify-content: center;
	width: ${({ viewport }) =>  viewport === 'desktop' ? 'calc(100vw - 32px)' : viewport === 'tablet' ? 'calc(100vw - 16px)' : 'calc(100vw - 8px)'};
  height: 721px;
	box-sizing: border-box;
  background: ${gradients.triColorGradient};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 32px;
  padding: 0 ${PADDING_SIDES};
`;

const BottomPadding = styled.div`
	padding-bottom: ${({ padding }) => padding ?? 16}px;
`;

const VirtualWrapper = styled.div`
	margin-top: 8px;
	border: 1px solid ${colors.white};
	padding: 8px;
	border-radius: 10px;
`;

const ProfileHero = (props) => {
	const { avatarUrl, displayName, location, categories, tags, name } = props.data;
	const { viewport, onButtonClick, isUserProfile } = props;

	const _renderTags = () => <Body size="small" color={colors.white}>{tags.join(", ")}</Body>;
	const _renderCategories = () => <Text size="large" bold color={colors.white}>{categories.join(", ")}</Text>;
	return (
		<ComponentWrapper viewport={viewport}>
			<ContentWrapper>
				<BottomPadding>
					<Avatar src={avatarUrl} size={viewport === 'desktop' ? 'jumbo' : 'large'} />
				</BottomPadding>
				<BottomPadding>
					<Text size="xxlarge" bold color={colors.white}>{(!displayName || displayName.length === 0) ? name : displayName}</Text>
				</BottomPadding>
				<BottomPadding>
					{location?.city && <Text size="small" color={colors.white} align="center">{location?.city + ', ' + location?.state}</Text>}
					{location?.city && location?.virtual && <VirtualWrapper><Text size="xxsmall" color={colors.white} align="center">+ Virtual Entertainment Available</Text></VirtualWrapper>}
					{location?.virtual && !location?.city && <VirtualWrapper><Text size="xxsmall" color={colors.white} align="center">Virtual Entertainment Only</Text></VirtualWrapper>}
				</BottomPadding>
				<BottomPadding padding={8}>
					{categories && _renderCategories()}
				</BottomPadding>
				<BottomPadding padding={0}>
					{tags && _renderTags()}
				</BottomPadding>
			</ContentWrapper>
			<PillButton color={colors.pink} onClick={onButtonClick} size="large" label={isUserProfile ? "Edit Profile" : "CONTACT ENTERTAINER"} />
		</ComponentWrapper>
	);
};

ProfileHero.propTypes = propTypes;
ProfileHero.defaultProps = defaultProps;

export default withManager(ProfileHero);
