import { useEffect } from 'react';
import styled from "styled-components";
import { useHistory } from 'react-router-dom';
import { TextBlock, PillButton } from 'components';
import { colors } from 'theme'
import { withManager } from 'utilities';

const ErrorPage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 70vh;
  box-sizing: border-box;
`;

const HeaderHero = styled.h1`
  font-size: ${({viewport}) => viewport !== 'desktop' ? '225px' : '312px'};
  font-family: dunbar-tall, Helvetica, sans-serif;
  color: ${colors.babyBlue};
  margin: 0;
`;

const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 20vh;
  width: ${({ viewport }) =>  viewport === 'desktop' ? 'calc(100vw - 32px)' : viewport === 'tablet' ? 'calc(100vw - 16px)' : 'calc(100vw - 8px)'};
  padding: ${({ viewport }) => viewport === 'desktop' ? '96px 16px 128px 16px' : '64px 16px'};
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin: 16px;
`;

const SpaceWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  height: 32px;
`;

const Error404 = (props) => {
  const { viewport } = props;
  const history = useHistory();
  const _scrollToTop = () => {
    window.scrollTo({
      top: 0,})
  }

  useEffect(_scrollToTop)

  return (
    <ErrorPage viewport={viewport}>
      <HeroContainer>
        <HeaderHero viewport={viewport}>
          404
        </HeaderHero>
        <TextBlock
          align="center"
          maxWidth='572px'
          primitive="h3"
          color={colors.pink}
          titleSize='xlarge'
          title="Oops, something went wrong! "
          subtitle="Click the button below to go to the homepage."/>  
        <SpaceWrapper/>
        <PillButton
          label="Go to Homepage"
          onClick={()=> history.push('./')}/>
      </HeroContainer>
    </ErrorPage>
  )
}

export default withManager(Error404);