import { useState, useCallback } from 'react';
import jsonp from "jsonp";

function toQueryString(params) {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
}

export const useMailChimp = (url) => {
  const [message, setMessage] = useState();

  const subscribe = useCallback((data) => {
    const params = toQueryString(data);
    const ajaxURL = url.replace("/post?", "/post-json?");
    const newUrl = ajaxURL + "&" + params;

    jsonp(newUrl, { param: "c" }, (err, data) => {
      setMessage(data.msg); 
    });
  }, [url]);

  return { subscribe, message };
}