import styled from 'styled-components';
import { colors } from 'theme';
import PropTypes from 'prop-types';
import { Text } from '../Text';
import { Body } from '../Body';

const propTypes = {
  titlePrimitive: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  titleSize: PropTypes.oneOf(['xxlarge', 'xlarge', 'large', 'medium', 'small', 'xsmall', 'xxsmall', 'xxxsmall', 'body', 'bodySmall']),
  bodySize: PropTypes.oneOf(['xsmall', 'small', 'medium']),
  children: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  bold: PropTypes.bool
},
  defaultProps = {
    titlePrimitive: 'h2',
    titleSize: 'xlarge',
    bodySize: 'medium',
    color: colors.blue,
    align: 'left',
    bold: true
  };

const Wrapper = styled.div`
  text-align: ${({ align }) => align === 'center' ? "center" : "left"};
  max-width: ${({ maxWidth }) => maxWidth ? maxWidth : null};
`;

const HeaderWrapper = styled.div`
  margin-bottom : ${({ titleSize, subtitle }) => _handleMarginBottom(titleSize, subtitle)};
`;

function _handleMarginBottom(titleSize, subtitle) {
  if (!titleSize || !subtitle) return;
  if (titleSize === 'xxlarge' || titleSize === 'xlarge') {
    return '16px'
  } else if (titleSize === 'large') {
    return '8px'
  }
  return '4px';
}

const TextBlock = (props) => {

  const {
    title,
    subtitle,
    align,
    maxWidth,
    color,
    titlePrimitive,
    titleSize,
    bodySize,
    bold
  } = props;

  let bodyTextColor = color === colors.white ? colors.white : colors.gray;

  return (
    <Wrapper
      {...props}
      align={align}
      maxWidth={maxWidth}>
      <HeaderWrapper {...props}>
        <Text
          bold={bold}
          color={color}
          size={titleSize}
          primitive={titlePrimitive}
          align={align}>
          {title}
        </Text>
      </HeaderWrapper>
      <Body
        primitive="p"
        size={bodySize}
        align={align}
        color={bodyTextColor}>
        {subtitle}
      </Body>
    </Wrapper>
  )
};

TextBlock.propTypes = propTypes;
TextBlock.defaultProps = defaultProps;

export default TextBlock;