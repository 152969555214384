import styled from 'styled-components';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { AspectRatio, Icon } from 'components';
import { colors } from 'theme';
import { withManager } from 'utilities';

const SlideWrapper = styled.div`
    display: flex;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${({ url }) => url});
    box-sizing: border-box;
`;

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: ${colors.white};
    box-shadow: 0px 0px 8px rgba(0,0,0,.2);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    top: 50%;
    ${({ direction }) => direction === 'prev' ? 'left' : 'right'}: 8px;
    z-index: 1;
    cursor: pointer;
    &:hover {
        div {
            transition: all 0.35s ease;
            border-color: ${colors.pink};
        }
    }
`;

const Carousel = props => {
    const { data } = props;

    const _renderNextArrow = (onClick) => {
        return (
            <IconWrapper onClick={onClick}>
                <Icon
                    color={colors.pink}
                    size='large'
                    name="caret-right"/>
            </IconWrapper>
        )
    }
    const _renderPrevArrow = (onClick) => {
        return (
            <IconWrapper direction={'prev'} onClick={onClick}>
                <Icon
                color={colors.pink}
                size='large'
                name="caret-left"/>
            </IconWrapper>
        )
    }

    return (
        <ReactCarousel showThumbs={false} showStatus={false} infiniteLoop showArrows={false} renderArrowNext={_renderNextArrow} renderArrowPrev={_renderPrevArrow}>
            {data?.map((slide, index) => {
                return (
                    <AspectRatio key={'image_' + index}>
                        <SlideWrapper url={slide.url} />
                    </AspectRatio>
                )
            }
            )}
        </ReactCarousel>
    )
}

export default withManager(Carousel);