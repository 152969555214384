import styled from 'styled-components'

const AspectRatioWrapper = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
`;

const AspectRatioInnerContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;


const AspectRatio = (props) => {
    const { children } = props;

    return (
        <AspectRatioWrapper id={props.id}>
            <AspectRatioInnerContainer>
                {children}
            </AspectRatioInnerContainer>
        </AspectRatioWrapper>)
};

export default AspectRatio;