import ActionTypes from "../actionTypes";
const initialState = {};

export default function reduceUser(state = initialState, action) {
  const { type } = action;
  if (!type) return state;
  switch (type) {
    case ActionTypes.user.getInit.START:
      if (!action.user) return;

      const {
        sub,
        name,
        email,
        "custom:group": group,
      } = action.user.attributes;
      const user = {
        id: sub,
        name: name,
        email: email,
        group: group,
      };
      return {
        ...state,
        error: null,
        fetching: null,
        data: {
          ...state?.data,
          ...user,
        },
      };
    case ActionTypes.user.getInit.ERROR:
      return {
        ...state,
        error: null,
        fetching: null,
      };

    case ActionTypes.user.getInit.SUCCESS:
      return {
        ...state,
        error: null,
        fetching: null,
        data: {
          ...state.data,
          ...action.response,
        },
      };

    case ActionTypes.user.putInit.START:
      return {
        ...state,
        error: null,
        fetching: null,
        data: action.body,
      };
    case ActionTypes.user.putInit.ERROR:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    case ActionTypes.user.putInit.SUCCESS:
      return {
        ...state,
        error: null,
        fetching: null,
        data: action.body,
      };
    case ActionTypes.user.getSettings.START:
      return {
        ...state,
        error: null,
        fetching: !state.data,
        data: state.data,
      };
    case ActionTypes.user.getSettings.ERROR:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    case ActionTypes.user.getSettings.SUCCESS:
      return {
        ...state,
        error: null,
        fetching: null,
        data: {
          ...state.data,
          ...action.response,
        },
      };
    case ActionTypes.user.saveSettings.START:
      return {
        ...state,
        error: null,
        fetching: true,
        data: state.data,
      };
    case ActionTypes.user.saveSettings.ERROR:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    case ActionTypes.user.saveSettings.SUCCESS:
      return {
        ...state,
        error: null,
        fetching: null,
        data: {
          ...state.data,
          ...action.body,
        },
      };
    case ActionTypes.user.setAvatar.START:
      return {
        ...state,
        error: null,
        fetching: false,
      };
    case ActionTypes.user.setAvatar.ERROR:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    case ActionTypes.user.setAvatar.SUCCESS:
      return {
        ...state,
        error: null,
        fetching: null,
        data: {
          ...state.data,
          avatarUrl: action.response.avatarUrl,
        },
      };
    case ActionTypes.user.saveImage.START:
      return {
        ...state,
        error: null,
        fetching: false,
      };
    case ActionTypes.user.saveImage.ERROR:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    case ActionTypes.user.saveImage.SUCCESS:
      return {
        ...state,
        error: null,
        fetching: null,
        data: {
          ...state.data,
          images: action.response,
        },
      };
    case ActionTypes.user.deleteImage.START:
      return {
        ...state,
        error: null,
        fetching: false,
      };
    case ActionTypes.user.deleteImage.ERROR:
      return {
        ...state,
        error: action.error,
        fetching: false,
      };
    case ActionTypes.user.deleteImage.SUCCESS:
      return {
        ...state,
        error: null,
        fetching: null,
        data: {
          ...state.data,
          images: action.response,
        },
      };
    case ActionTypes.profile.addReview.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          reviewed: action.response.reviewed,
        },
      };

    case ActionTypes.user.deleteUser.START:
      return {
        ...state,
        error: null,
        fetching: false,
        body: action
      };
      case ActionTypes.user.deleteUser.SUCCESS:
      return {
          ...state,
          error: null,
          fetching: false,
          body: action.response,
        };
      case ActionTypes.user.deleteUser.ERROR:
      return {
          ...state,
          error: action.error,
          fetching: false,
          body: action.response,
        };
    default:
      return state;
  }
}
