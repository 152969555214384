import styled from 'styled-components';
import PropTypes from "prop-types";
import cuid from "cuid";
import { Body } from 'components';
import { colors } from 'theme'

const propTypes = {
  label: PropTypes.string, 
  type: PropTypes.string, 
  labelColor: PropTypes.string, 
  error: PropTypes.bool, 
  errorText: PropTypes.string, 
  helperText: PropTypes.string, 
  placeholder: PropTypes.string, 
  width: PropTypes.string, 
  helperLink: PropTypes.string,
  helperLinkHref: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  onChange: () => {}
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width ?? '100%'};
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 8px;
  display: ${({ hide }) => hide && 'none'};
`;

const TextWrapper = styled.div`
  width: 100%;
  padding-bottom: 8px;
  height: 18px;
`;

const TextAreaWrapper = styled.textarea`
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  font-weight: normal;
  min-height: 128px;
  font-family: dunbar-text;
  font-weight: normal;
  color: ${({ error }) => error ? colors.error : colors.babyBlue};
  padding: 16px;
  border-radius: 10px;
  border: 2px solid ${({ error }) => error ? colors.error : colors.grayLight};
  box-sizing: border-box;
  transition: all 0.3s ease-in;

  &:hover{
    outline: none;
    padding: 15px;
    border: 3px solid ${colors.grayLight};
  }

  &:focus{
    outline: none;
    padding: 15px;
    border: 3px solid ${colors.babyBlue};
  }
  ::placeholder {
    color: ${colors.grayLight};
  }
`;

const TextArea = (props) => {
  const { 
    label, 
    type, 
    labelColor, 
    error, 
    errorText, 
    helperText, 
    placeholder, 
    width,
    defaultValue,
  } = props;
  let inputId = cuid();

  return (
    <Wrapper
      width={width}
      errorText={errorText}
      helperText={helperText}>
      <Label
        htmlFor={inputId}
        hide={placeholder && !label}>
        <Body
          size="small"
          bold
          color={labelColor ?? colors.blue}>{placeholder && !label ? placeholder : label}
        </Body>
      </Label>
      <TextAreaWrapper
        {...props}
        id={inputId}
        defaultValue={defaultValue}
        placeholder={placeholder}
        error={error}
        errorText={errorText}
        type={type} />
      {helperText &&
        <TextWrapper>
          <Body
            size="xsmall"
            color={colors.gray}>
            {helperText}
          </Body>
        </TextWrapper>}
      <TextWrapper>
        {(error && errorText) &&
          <Body
            size="xsmall"
            color={colors.error}>
            {errorText}
          </Body>
        }</TextWrapper>
    </Wrapper>
  )
};

TextArea.propTypes = propTypes;
TextArea.defaultProps = defaultProps;

export default TextArea;