import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding-top: 93px;
`


const ContentWell = (props) => {
  const { children } = props;

  return(
    <div>
      <Wrapper>{children}
      </Wrapper>
    </div>)
};

export default ContentWell