import styled from "styled-components";
import PropTypes from "prop-types";
import { Text, TextBlock, PillButton, TextLink } from "components";
import { colors } from "theme";
import { withManager } from 'utilities';
import {ReactComponent as ApplauseIcon} from 'assets/specialtyIcons/care-center-stage-applause.svg';
import {ReactComponent as DiscoBallIcon} from 'assets/specialtyIcons/care-center-stage-discoBall.svg';

const propTypes = {
  imageSource: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  price: PropTypes.string,
  onLinkClick: PropTypes.func,
  onButtonClick: PropTypes.func,
};

const defaultProps = {};

const ContentWrapper = styled.div`
  height: 100%;
  max-width: 464px;
  display: flex;
  flex-direction: column;
	align-items: center;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  background-color: ${colors.white};
  box-shadow: 0px 3px 6px ${colors.navyBlue}50;
  display: flex;
  justify-content: center;
  width: 100px;
  z-index: 1;
	margin-top: -105px;
	border-radius: 50%;
  transition: all 0.3s ease-in;
`;

const ComponentWrapper = styled.div`
  background-color: ${colors.white};
  box-shadow: 0px 12px 24px ${colors.navyBlue}30;
  border-radius: 10px;
  padding: ${({ viewport }) => viewport !== 'desktop' ? '64px' : '64px'};
  display: flex;
  flex-direction: column;
  position: relative;
	max-width: 464px;
  height: 396px;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
	margin-bottom: 64px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 8px;
`;

const BottomWrapper = styled.div`
	display: flex;
	justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const TextWrapper = styled.div`
  padding-bottom: ${({ paddingBottom }) => paddingBottom ?? 4}px;
  display: flex;
  align-items: center;
	flex-direction: column;
	justify-content: center;
  padding: 32px 0 16px 0;
`;

const SignUpCTA = (props) => {
  const {
    careCenter,
    title,
    subtitle,
		price,
		onLinkClick,
		onButtonClick,
		viewport
  } = props;

	const IconChange = careCenter ? ApplauseIcon : DiscoBallIcon;
	let ctaId = careCenter ? 'careCenterCta' : 'entertainerCta';

	return (
    <ComponentWrapper viewport={viewport} id={ctaId}>
      <ContentWrapper viewport={viewport}>
        <IconWrapper>
          <IconChange/>
        </IconWrapper>
        <TextWrapper>
          <TextBlock
          align="center"
          maxWidth='572px'
          color={careCenter ? colors.babyBlue : colors.pink}
          primitive="h2"
          titleSize='small'
          title={title}
          subtitle={subtitle}/>
          <Text 
            bold 
            size="xxsmall"
            color={careCenter ? colors.babyBlue : colors.pink}>
            {price}
          </Text>
        </TextWrapper>
        <BottomWrapper>
          <ButtonWrapper>
            <PillButton
              size="medium"
              label="Sign Up Now"
              onClick={onButtonClick}
              color={careCenter ? colors.babyBlue : colors.pink}/>
          </ButtonWrapper>
          <TextLink
            label="LEARN MORE"
            onClick={onLinkClick}
            color={careCenter ? colors.babyBlue : colors.pink}/>
        </BottomWrapper> 
      </ContentWrapper>
    </ComponentWrapper>
  );
};

SignUpCTA.propTypes = propTypes;
SignUpCTA.defaultProps = defaultProps;

export default withManager(SignUpCTA);
