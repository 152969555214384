import ActionTypes from '../actionTypes';
const initialState = {};

export default function reduceProfile(state = initialState, action) {
  const { type, response } = action;
  if (!type) return state;
  switch (type) {
    case ActionTypes.profile.getOne.START:
      const { id } = action.params;
      return {
        ...state,
        error: null,
        fetching: !state[id],
      }
    case ActionTypes.profile.getOne.ERROR:
      return {
        ...state,
        error: action.error,
        fetching: false
      }
    case ActionTypes.profile.getOne.SUCCESS:
      return {
        ...state,
        error: null,
        fetching: null,
        [action.id]: {
          ...state[action.id],
          ...response
        }
      }
    case ActionTypes.profile.getFeatured.START:
      return {
        ...state,
        error: null,
        fetching: !state?.featured,
        featured: state?.featured,
      }
    case ActionTypes.profile.getFeatured.ERROR:
      return {
        ...state,
        error: action.error,
        fetching: false
      }
    case ActionTypes.profile.getFeatured.SUCCESS:
      return {
        ...state,
        error: null,
        fetching: null,
        featured: action.response
      }
    case ActionTypes.profile.search.START:
      return {
        ...state,
        error: null,
        searching: true,
        filteredResults: state?.filteredResults,
      }
    case ActionTypes.profile.search.ERROR:
      return {
        ...state,
        error: action.error,
        searching: false
      }
    case ActionTypes.profile.search.SUCCESS:
      return {
        ...state,
        error: null,
        searching: false,
        filteredResults: action.response
      }

      case ActionTypes.profile.addReview.START: 
      return {
        ...state,
        error: null,
        success: null,
        postingReview: true,
      }
    case ActionTypes.profile.addReview.ERROR:
      return {
        ...state,
        error: action.error,
        success: null,
        postingReview: false
      }
    case ActionTypes.profile.addReview.SUCCESS:

      return {
        ...state,
        error: null,
        postingReview: false, //use dis instead of fetching or page will show loader duh 
        [response.addedUser]: {
          ...state[response.addedUser],
          reviews: response.newReviews
        }
      }
    default:
      return state;
  }
}