const linkData = [
  {
    title: "For Care Centers",
    links: [
      {
        text: "Find Entertainment",
        href: "/search"
      },
      {
        text: "Search by Category",
        href: "/search"
      }
    ]
  },
  {
    title: "Your Account",
    links: [
      {
        text: "Sign In",
      },
      {
        text: "Sign Up",
        href: '/sign-up'
      }
    ]
  },
  {
    title: "Company",
    links: [
      {
        text: "About",
        href: "/about"
      },
      {
        text: "Support",
        href: "/support"
      },
      // {
      //   text: "Contact",
      //   href: "/contact"
      // }
    ]
  }
];

const authLinkData = [
  {
    title: "For Care Centers",
    links: [
      {
        text: "Find Entertainment",
        href: "/search"
      },
      {
        text: "Search by Category",
        href: "/search"
      }
    ]
  },
  {
    title: "Your Account",
    links: [

      {
        text: "Settings",
        href: "/settings"
      },
      {
        text: "Sign Out",
      }
    ]
  },
  {
    title: "Company",
    links: [
      {
        text: "About",
        href: "/about"
      },
      {
        text: "Support",
        href: "/support"
      },
      // {
      //   text: "Contact",
      //   href: "/contact"
      // }
    ]
  }
];

const iconData = [
  {
    name: 'instagram',
    href: 'https://www.instagram.com/carecenterstage/'
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/CareCenterStage'
  },
  {
    name: 'facebook',
    href: 'https://www.facebook.com/CareCenterStage'
  },
  {
    name: 'youtube',
    href: 'https://www.youtube.com/channel/UCqDa-748-nYd9_X52UMO8Aw'
  }
]

export { linkData, authLinkData, iconData };