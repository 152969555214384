import styled from 'styled-components';
import { colors } from 'theme'
import { Body } from 'components';

const TriggerWrapper = styled.button`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  color: ${colors.gray};
  padding: 0px 16px;
  border-radius: 10px;
  border: 2px solid ${({ open }) => open ? colors.babyBlue : colors.grayLight};
  box-sizing: border-box;
  background-color: white;
  z-index: 3;
  cursor: pointer;
  outline: none;
  &:focus,
  &:active {
    border: 2px solid ${colors.babyBlue};
  }
  &:hover:not(:focus) {
    transition: all ease 0.35s;
    box-shadow: 0 0 0 1px ${colors.grayLight};
  }
`;

const TempIcon = styled.div`
  height: 6px;
  width: 6px;
  border-right: 2px solid ${colors.navyBlue};
  border-bottom: 2px solid ${colors.navyBlue};
  transform: ${({ active }) => active ? 'rotate(225deg)' : 'rotate(45deg)'};
  transition: all 0.35s ease;
`;

const IconWrapper = styled.div`
  padding-left: 12px;
`;


const Trigger = (props) => {
  const { onClick, active, selectedOption, placeholder } = props;
  return (
    <TriggerWrapper onClick={onClick} open={active}>
      <Body size="small" color={selectedOption ? colors.navyBlue : colors.darkGray}>{selectedOption?.children ?? placeholder}</Body>
      <IconWrapper><TempIcon active={active} /></IconWrapper>
    </TriggerWrapper>
  )
};

export default Trigger;