import { 
  call, 
  put 
} from "redux-saga/effects";
import ActionTypes from "../actionTypes";

export function* addReview (action) {
  const { body, at } = action
  try {
    const response = yield call(() => {
      return global.API.put('ADD_REVIEW', {
        headers: {
          accesstoken: at,
          'Content-Type': 'application/json',
        },
        body: {
          ...body
        }
      })
    });

    yield put({
      type: ActionTypes.profile.addReview.SUCCESS,
      response: response
    });

  } catch(error) {
      yield put({
      type: ActionTypes.profile.addReview.ERROR,
      error
    });
  }
};