
import styled from "styled-components";
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { colors } from "theme";
import { Auth } from 'aws-amplify';
import {
  Modal,
  Body,
  PillButton,
  TextInput,
  TextLink,
  Text,
  Checkbox
} from "components";
import { UserTypeCard } from "modules";
import { validateInputs } from 'utilities';
import { useMailChimp } from './mailchimpSignUp'
import { ReactComponent as FbSignIn } from '../../assets/facebook-signin.svg';
import { ReactComponent as FbSignUp } from '../../assets/facebook-signup.svg';
import { useHistory } from "react-router";
import { useDispatch } from 'react-redux';
import ActionTypes from "redux/actionTypes";

const FBURL = 'https://carecenterstage-dev.auth.us-east-1.amazoncognito.com/oauth2/authorize?redirect_uri=https://carecenterstage.com/&response_type=code&client_id=4kh9gp82uuig202rq3qoihjuar&identity_provider=Facebook'

const propTypes = {
  loadAs: PropTypes.oneOf(['signIn', 'signUp'])
}

const Wrapper = styled.div``;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`;

// const RadioGroupWrapper = styled.div`
//   padding-bottom: 16px;
// `;
const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Spacer = styled.div`
  width: 100%;
  height: ${({ large, small }) => large ? '32px' : small ? '8px' : '16px'};
`;

const SocialButtonWrapper = styled.a`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  cursor: pointer;
  width: auto;
  box-sizing: border-box;
  &:active{
    opacity: 30%;
  }
`;

const Login = (props) => {
  const {
    open,
    onClose,
    setModalState,
    modalState,
  } = props;
  const [inputs, setInputs] = useState();
  const [localFetch, setLocalFetch] = useState();
  const [userGroup, setUserGroup] = useState();
  const [addNewsletter, setAddNewsletter] = useState();
  const [errors, setErrors] = useState();
  const history = useHistory();
  const dispatch = useDispatch()
  const {subscribe, message} = useMailChimp('https://tombendermusic.us6.list-manage.com/subscribe/post?u=03515e1fc5452a2d66fbbd708&amp;id=5974820ecd');
  
  /**input handlers */
  const _onChange = ({ target: { name, value } }) => {
    setInputs(state => ({ ...state, [name]: value }), []);
    setErrors(state => ({ ...state, [name]: null}), []);
  }
  const _onError = (name, errorMessage) => setErrors(state => ({ ...state, [name]: errorMessage }), [])

  const _onCreateAccount = async () => {
    if (!validateInputs(inputs, 'signUp', _onError)) return;
    const { password, name, email, userGroup } = inputs;
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name,
        'custom:group': userGroup,
        'custom:accountStatus': 'verify'
      }
    }).then(() => setModalState('verify'))
    .catch((err) => {
      if (err.code === "UsernameExistsException") return _onError('email', err.message) 
      if (err.code === "TooManyRequestsException") return _onError('email', err.message)

      return _onError('email', 'Something went wrong. Please try again later.')

    })
  }

  const _onAddMailchimp = (email) => {
    if (!email) return;
    subscribe({
      EMAIL: email,
    })

    if (!message) return;
    if (message?.includes('already subscribed')) return;
  }
  /**
   * this function is called when the verification code is clicked.
   * 
   * if the users confirmation code is expected, it logs the user in, and changes the accountStatus to
   * select group. this forces the user to choose a group before continuing.
   */
  const _onVerify = async () => {
    const { email: username, confirmationCode, password } = inputs;
    setLocalFetch(true)
    await Auth.confirmSignUp(
      username, confirmationCode
    ).then(() => {
      // setModalState('signIn') sign in
      Auth.signIn(username, password).then(async (user) => {
        localStorage.setItem('AUTH_USER_TOKEN_KEY', user.signInUserSession.accessToken.jwtToken);
        await Auth.updateUserAttributes(user, {
          'custom:accountStatus': 'selectGroup'
        });
        if (addNewsletter) {
          _onAddMailchimp(username)
        }
        setModalState('selectGroup')
        // window.location.reload(false)
        //navigate to profile page on initial sign in, otherwise, home with logged in state
      setLocalFetch(false)
  })

    }).catch((err) => {
      //match actual error from cognito to meaningful error messages, but for now - it sets error to true
      _onError('confirmationCode', 'Please retype your code and try again')
      setLocalFetch(false)
  })
  }

  const _onVerifyForgotPassword = () => {
    const {email: username, password, confirmPass, confirmationCode} = inputs;
    const uppercase = /[A-Z]/.test(password);
    const lowercase = /[a-z]/.test(password);
    const number = /[0-9]/.test(password);
    if (!(uppercase && lowercase && number && password.length > 7)) {
      const _getError = () => {
        if (!uppercase) return 'Password must have 1 uppercase letter';
        else if (!lowercase) return 'Password must have 1 lowercase letter';
        else if (!number) return 'Password must contain a number'
        else return 'Password must contain at least 8 characters.'
      }
      _onError(
        'password',
        _getError()
      )
    } else if (password !== confirmPass) {
      _onError(
        'confirmPass',
        'Passwords do not match.'
      );
    } else {
      Auth.forgotPasswordSubmit(username, confirmationCode, password).then(()=>_signIn()).catch((err)=>{
        _onError('confirmPass', 'Error resetting password, please try again')
      })
    }

  }

  const _selectGroup = async () => {
    Auth.currentAuthenticatedUser()
      .then(async (user) => {
        await Auth.updateUserAttributes(user, {
          'custom:group': userGroup,
          'custom:accountStatus': 'active'
        });

        //call initial put user api
        const body = {
          id: user.attributes.sub,
          group: userGroup,
          contactEmail: user.attributes.email,
          email: user.attributes.email,
          name: user.attributes.name,
          displayName: user.attributes.name
        };

        dispatch({ body, at: user.signInUserSession.accessToken.jwtToken, type: ActionTypes.user.putInit.START })
        onClose()
        setModalState(null)
        if (userGroup === 'carecenters') return 
        history.push('/settings')

      })
  }

  const _resendVerificationCode = async (onSignIn) => {
      setLocalFetch(true)
      await Auth.resendSignUp(inputs.email)
    .then(async () => {
      setLocalFetch(false)
      if (onSignIn) return setModalState('verify');
    }) 
    .catch((err) => {
      if (err.code === "LimitExceededException") return _onError('email', err.message)
      _onError('email', 'There was an error, please contact support for help.')
      setLocalFetch(false)
    })
  }


  const _onClickForgotPassword = () => {
    setModalState('forgotPassword');
  }

  const _renderSelectGroup = () => {
    return (
      <>
        <UserTypeCard
          careCenter
          title={"I'm a Caretaker"}
          subtitle="FREE"
          selected={userGroup === 'carecenters'}
          onClick={() => setUserGroup('carecenters')}
        />
        <UserTypeCard
          title={"I'm an Entertainer"}
          subtitle="Free"
          selected={userGroup === 'entertainers'}
          onClick={() => setUserGroup('entertainers')}
        />
        <PillButton
          color={colors.babyBlue}
          onClick={_selectGroup}
          label="Confirm" />
      </>
    );
  }

  const _renderSignUp = () => {
    return (
      <>
        <FormWrapper>
          <SocialButtonWrapper href={FBURL}><FbSignUp /></SocialButtonWrapper>
        </FormWrapper>

        <Spacer small />
        <Text bold size="small" color={colors.pink}>or</Text>
        <Spacer small />

        <FormWrapper>
          <TextInput
            name='name'
            placeholder='Name'
            error={!!errors?.name || errors?.all}
            errorText={errors?.name}
            onChange={_onChange} />
          <TextInput
            name='email'
            type="email"
            placeholder='Email'
            error={!!errors?.email || errors?.all}
            errorText={errors?.email}
            onChange={_onChange} />
          <TextInput
            name='password'
            placeholder='Password'
            error={!!errors?.password || errors?.all}
            errorText={errors?.password}
            type="password"
            onChange={_onChange} />
          <TextInput
            name='confirmPass'
            placeholder='Confirm Password'
            error={!!errors?.confirmPass || errors?.all}
            errorText={errors?.confirmPass}
            type="password"
            helperText="Password must contain an uppercase, lowercase and special character"
            onChange={_onChange} />
        </FormWrapper>
        <Checkbox
          onChange={(e) => setAddNewsletter(e.target.value)}
          label="Sign me up for the newsletter." />
        <Spacer />
        <PillButton
          color={colors.babyBlue}
          onClick={_onCreateAccount}
          label="Create Account" />
        <Spacer />

        <BottomWrapper>
          <Body>Already have an account?&nbsp;</Body>
          <TextLink
            onClick={() => setModalState('signIn')}
            size="medium"
            color={colors.blue}
            label="Sign in here." />
        </BottomWrapper>
        <Spacer large />
        <Body size="xsmall">
          By clicking “CREATE ACCOUNT”, I agree to Care Center Stage’s &nbsp;
        <TextLink inline size="small" label="Terms of Service" target="_blank" href='/terms' />
        &nbsp; and&nbsp;
        <TextLink inline size="small" label="Privacy Policy" target="_blank" href='/privacy-policy' />.</Body>
      </>
    );
  };

  const _renderVerification = () => {
    return (<>
      <Wrapper>
        <Body>
          {`One last step. We just sent an email to ${inputs?.email}.  Please insert the 6-digit-code from the email below`}
        </Body>
      </Wrapper>
      <Wrapper>
        <TextInput
          type='number'
          name='confirmationCode'
          placeholder='000000'
          error={!!errors?.confirmationCode || errors?.all}
          errorText={errors?.confirmationCode}
          onChange={_onChange}
          label="Verification Code" />
      </Wrapper>
      <PillButton
        disabled={localFetch}
        color={colors.babyBlue}
        onClick={_onVerify}
        label="Sign In" />
    </>)
  }

  const _renderForgotPasswordVerification = () => {
    return (<>
      <Wrapper>
        <TextInput
          type='number'
          name='confirmationCode'
          placeholder='000000'
          error={!!errors?.confirmationCode || errors?.all}
          onChange={_onChange}
          label="Verification Code" />
          <TextInput
            name='password'
            placeholder='New Password'
            error={!!errors?.pass || errors?.all}
            errorText={errors?.pass}
            type="password"
            onChange={_onChange} />
          <TextInput
            name='confirmPass'
            placeholder='Confirm New Password'
            error={!!errors?.confirmPass || errors?.all}
            errorText={errors?.confirmpass}
            type="password"
            onChange={_onChange} />
      </Wrapper>
      <PillButton
        color={colors.babyBlue}
        onClick={_onVerifyForgotPassword}
        label="Sign In" />
    </>)
  }

  const _signIn = () => {
    if (!validateInputs(inputs, 'signIn', _onError)) return;
    setLocalFetch(true)
    const { email: username, password } = inputs;
    Auth.signIn(username, password).then(async (user) => {
      const at = user.signInUserSession.accessToken.jwtToken
      localStorage.setItem('AUTH_USER_TOKEN_KEY', at);
      dispatch({ at: at, user: user, type: ActionTypes.user.getInit.START})
      onClose();
      if (user.attributes['custom:group'] === 'entertainers') history.push('/settings')
      else window.location.reload(false)
      //navigate to profile page on initial sign in, otherwise, home with logged in state
    }).catch((err) => {
      if (err.code === "UserNotFoundException") return _onError('email', err.message) 
      if (err.code === "NotAuthorizedException") return _onError('pass', err.message)
      if (err.code === "TooManyRequestsException") return _onError('pass', err.message)
      if (err.code === "UserNotConfirmedException") return _resendVerificationCode(true);
      return _onError('email', 'Something went wrong. Please try again later.')
    })
    setLocalFetch(false)
  }

  const _onSubmitForgotPassword = () => {
    
    const { email: username } = inputs;

    Auth.forgotPassword(username).then(()=>setModalState('verifyForgotPassword')).catch((err)=> {
      setErrors('email', 'Invalid email please try again.')
    })
  }


  const _renderSignIn = () => {
    return (
      <>
        <FormWrapper>
          <SocialButtonWrapper href={FBURL}><FbSignIn /></SocialButtonWrapper>
        </FormWrapper>
        <Spacer small />
        <Text bold size="small" color={colors.pink}>or</Text>
        <Spacer small />
        <FormWrapper>
          <TextInput
            name='email'
            type='email'
            placeholder='Email'
            errorText={errors?.email}
            error={!!errors?.email || errors?.all}
            onChange={_onChange} />
          <TextInput
            name='password'
            placeholder='Password'
            errorText={errors?.pass}
            error={!!errors?.pass || errors?.all}
            type="password"
            onChange={_onChange} />
          <TextLink
            onClick={_onClickForgotPassword}
            size="small"
            color={colors.blue}
            label="Forgot Password?" />
        </FormWrapper>
        <Spacer />
        <PillButton
          disabled={!inputs?.email || !inputs?.password || localFetch}
          label="Sign in"
          color={colors.babyBlue}
          onClick={_signIn}
        />
        <Spacer />
        <BottomWrapper>
          <Body>Don't have an account?&nbsp;</Body>
          <TextLink
            onClick={() => setModalState('signUp')}
            size="medium"
            color={colors.blue}
            label="Sign up now." />
        </BottomWrapper>
      </>)
  }

  const _renderForgotPassword = () => {
    return (
      <>
        <FormWrapper>
          <TextInput
            name='email'
            type="email"
            placeholder='Email'
            errorText='heres an error'
            error={errors?.email || errors?.all}
            onChange={_onChange} />
        </FormWrapper>
        <Spacer />
        <PillButton
          disabled={!inputs?.email || localFetch}
          label="Submit"
          color={colors.babyBlue}
          onClick={_onSubmitForgotPassword}
        />
      </>)

  }
  const _signOut = () => {
    Auth.signOut().then(() => {
      localStorage.clear();
      history.go(0)
    })
  };

  const _renderAuthorizedUser = () => {
    return (
      <>
        
        <PillButton
          label="Go Back"
          color={colors.babyBlue}
          onClick={() => onClose()}/>
        <Spacer/>
        <PillButton
          label="Sign Out"
          inverted
          color={colors.pink}
          onClick={_signOut}/>
      </>
    )
  }

  const _clearInputs = () => {
    if (modalState === 'verify' || modalState === 'verifyForgotPassword') return
    setInputs(null)
    setErrors(null)
  }

  useEffect(_clearInputs, [modalState])

  const _getAttributes = () => {
    switch (modalState) {
      case 'signUp':
        return {
          title: 'Create your account.'
        }
      case 'signIn':
        return {
          title: 'Sign in to your account.'
        }
      case 'selectGroup':
        return {
          title: 'Choose a profile type.'
        }
      case 'forgotPassword':
        return {
          title: 'Please enter your email.'
        }
      case 'verify':
        return {
          title: 'Verify your email.'
        }
      case 'verifyForgotPassword':
        return {
          title: 'We sent a code to your email.'
        }
      case 'isAuthenticated':
        return {
          title: 'You are already signed in.',
          subtitle: <Body>If you want to sign in as a different user, you need to log out of the current account first.</Body>
        }
      default: return
    }
  }

  const _renderModal = () => {
    switch (modalState) {
      case 'signUp':
        return _renderSignUp()
      case 'signIn':
        return _renderSignIn()
      case 'selectGroup':
        return _renderSelectGroup()
      case 'verify':
        return _renderVerification()
      case 'verifyForgotPassword':
        return _renderForgotPasswordVerification()
      case 'forgotPassword':
        return _renderForgotPassword()
      case 'isAuthenticated':
        return _renderAuthorizedUser()
      default: return
    }
  }

  return (
    <Modal
      {..._getAttributes()}
      width='474px'
      open={open}
      disableClickExit={modalState === 'selectGroup'}
      hideClose={modalState === 'selectGroup'}
      onClose={onClose}
    >
      <Wrapper>
        {_renderModal()}
      </Wrapper>
    </Modal>
  )
};

Login.propTypes = propTypes;
export default Login;

/**
 *
 * to do:
 *  add meaninful error messages
 *  add functionality if user signs up but doesnt verify and tries to sign up again
 *
 */