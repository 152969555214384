let homeData = {
  hero: {
      headerText: 'Find entertainment for your care community.',
      bodyText: 'Your care community deserves high-quality entertainment. We are working hard to make it easy for you to connect with talented performers from all over the world.',
  },
  newsletter: {
    headerText: 'Sign up for our newsletter to stay in the know.',
    bodyText: 'Want to be the first to sign up and get deals and access to our beta? Leave your email below.',
  }
}

export { homeData };
