import { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { 
  Modal,
  PillButton,
  TextInput, 
  TextArea,
  Loader
} from "components";
import { colors } from "theme";
import ActionTypes from "redux/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { validateMessageEntertainerInputs } from 'utilities';

const propTypes = {
  data: PropTypes.object,
};
const defaultProps = {
};

const Wrapper = styled.div`
  padding-bottom: 32px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 8px;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const ContactEntertainer = (props) => {
  const { open, onClose, data, onSubmit, authenticated, openAuthenticationModal } = props;

  const initialValues = {
    name: authenticated?.attributes.name,
    email: authenticated?.attributes.email,
    dates: undefined,
    message: undefined,
  };
  const dispatch = useDispatch();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [inputs, setInputs] = useState(initialValues);
  useEffect(() => { 
    setInputs(initialValues)
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated] )
  const [inputErrors, setInputErrors] = useState();
  const { sendError, fetching } = useSelector (({ contact })=> {
    return {
      sendError: contact.error?.status,
      fetching: contact.fetching
    }
  })

  const openSignInModal = () => {
    openAuthenticationModal && 
      openAuthenticationModal('signIn');
  }

  const checkAuthenticated = () => {
    if (!authenticated && open) {
      onClose && onClose()
      openSignInModal()
      return;
    }
  }
  useEffect(checkAuthenticated)

  const _onChange = ({target:{name,value}}) => {
    setInputs(state => ({ ...state, [name]:value }), []);
    setInputErrors(state => ({ ...state, [name]:false }), [])
    if (inputErrors?.all) setInputErrors(state => ({ ...state, all:false }), [])
  }
  const _onInputError = (name) =>  setInputErrors(state => ({ ...state, [name]:true }), [])
  const _sendMessage = async () => {
    const at = localStorage.getItem('AUTH_USER_TOKEN_KEY')
    let handleEmail = (!data?.contactEmail || data?.contactEmail.length === 0) ? data.email : data.contactEmail;

    let body = {
      name: inputs.name,
      email: inputs.email,
      dates: inputs.dates,
      message: inputs.message,
      toEmail: handleEmail,
      toUser: data.name,
    }

    dispatch({ at, body, type: ActionTypes.entertainerContact.sendEmail.START })

    if (sendError) return _handleError();
    else _handleSuccess();
  };

  const _handleOnSubmit = async () => {
    onSubmit && onSubmit()
    if (!validateMessageEntertainerInputs(inputs, _onInputError)) return; 
    _sendMessage()
  }

  const _handleSuccess = () => {
      _clearInputs()
      setSuccess(true)
  }

  const _handleError = () => {
      setError(true)
  }

  const _clearInputs = () => {
    setInputs(null)
  }

  const _handleErrorText = () => {
    return 'You must fill out this field to send a message.'
  }

  const _renderForm = () => {
    return (
      <>
        <FormWrapper>
          <InputWrapper>
            <TextInput
              error={inputErrors?.name || inputErrors?.all}
              disabled={error}
              defaultValue={authenticated?.attributes.name}
              errorText={error && _handleErrorText()}
              name="name"
              placeholder='name'
              onChange={_onChange}/>
          </InputWrapper>
          <InputWrapper>
            <TextInput
              error={inputErrors?.email || inputErrors?.all}
              disabled={error}
              errorText={_handleErrorText()} 
              name="email"
              type="email" 
              defaultValue={authenticated?.attributes.email}
              onChange={_onChange}
              placeholder='youremail@email.com'/>
          </InputWrapper>
          <InputWrapper>
            <TextInput
              error={inputErrors?.dates || inputErrors?.all}
              disabled={error}
              errorText={_handleErrorText()} 
              name="dates"
              placeholder="if no dates in question, please type 'N/A'"
              onChange={_onChange}/>
          </InputWrapper>
          <InputWrapper>
            <TextArea
              error={inputErrors?.message || inputErrors?.all}
              disabled={error}
              errorText={_handleErrorText()} 
              name="message"
              onChange={_onChange}
              placeholder='your message here'/>
          </InputWrapper>
        </FormWrapper>
      </>
    )
  }

  const _handleTitle = () => {
    if (sendError) return 'Oh No! Error: ' + sendError;
    if (success) return 'Success!';
    if (data) return "Message " + data.displayName;
    return "Message Entertainer";
  }
  
  const _handleSubtitle = () => {
    let subtitleText;

    if (sendError) { subtitleText = 'Sorry, something went wrong, please try again later.'; }
    else if (success) { subtitleText = 'Your message to ' + data.displayName + ' has been sent.'; }
    else { subtitleText = 'Fill out the information below to send a message to ' + data.displayName + '.' }
    return subtitleText
  }

  return (
    <>
      <Loader visible={fetching}/>
      <Modal 
        noOverflowY={success}
        titleColor={sendError ? colors.error : null}
        title={_handleTitle()}
        subtitle={_handleSubtitle()}
        open={open} 
        onClose={onClose}>
      <Wrapper>
        {!success && _renderForm()}
      <PillButton
        disabled={success ? false : !inputs || sendError}
        type="submit"
        label={success ? "Go Back" : "Send message"}
        color={success ? colors.pink : colors.babyBlue} 
        onClick={success ? () => onClose() : _handleOnSubmit} />
      </Wrapper>
    </Modal>
    </>
  )
};

ContactEntertainer.propTypes = propTypes;
ContactEntertainer.defaultProps = defaultProps;

export default ContactEntertainer;
