import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import MainRoutes from "routing";
import { Helmet } from "react-helmet";
import Amplify from 'aws-amplify';
import { ScrollToTop, ViewportManager } from "utilities";
import { GlobalNav, Footer, LoginModal } from 'modules';
import { ContentWell, DonateButton } from 'components';
import aws_exports from './aws-exports';
import { selectData } from './pages/home/data';
import ActionTypes from 'redux/actionTypes';
import usePageTracking from './analytics/usePageTracking'

Amplify.configure(aws_exports);

function App() {
  const [authenticated, setAuthenticated] = useState();
  const [authenticationModal, setAuthenticationModal] = useState();
  const dispatch = useDispatch();
  // Google Analytics
  usePageTracking();
/**
 * MODALS
 */
 const _renderAuthenticationModal = () => {
  return (
    <LoginModal
      modalState={authenticationModal}
      setModalState={setAuthenticationModal}
      open={authenticationModal}
      onClose={() => setAuthenticationModal(null)}/>
  )
}

const _openAuthenticationModal = (type ) => setAuthenticationModal(!authenticated ? type :  'isAuthenticated')


  const checkAuthenticationStatus = () => {
    Amplify.Auth.currentAuthenticatedUser()
    .then(async (user)=>{ 
      const at = user.signInUserSession.accessToken.jwtToken;
      localStorage.setItem('AUTH_USER_TOKEN_KEY', at);
      dispatch({at: at, user: user, type: ActionTypes.user.getInit.START});
      if (authenticated) return;
      if(user.attributes['custom:accountStatus'] === 'selectGroup') {
        setAuthenticationModal('selectGroup')

      }

      // if(!user.attributes['custom:accountStatus']){// if this user came from facebook, this condition happens
      //   await Amplify.Auth.updateUserAttributes(user, {
      //     'custom:accountStatus': 'selectGroup',
      //     'email_verified': true,
      //   });
      //  setAuthenticationModal('selectGroup');
      // }
      return setAuthenticated(user)
    })
    .catch((err)=>{
      console.log(err, 'error')
      if (!authenticated) return;
      return setAuthenticated(null);
    })
  }

  useEffect(checkAuthenticationStatus);

  

  return (
    <>
   {_renderAuthenticationModal()}
    <div className="App">
      <ViewportManager/>
      <Helmet>
        <title>Care Center Stage | Connecting Entertainers</title>
        <meta property="og:title" content="Care Center Stage" />
        <meta property="og:description" content="Connecting entertainment to those who need it, all around the world." />
        <meta property="og:url" content="carecenterstage.com" />
        <meta property="og:image" content="https://images.carecenterstage.com/root/social-card-preview.jpg" />
        <meta property="og:image:secure_url" content="https://images.carecenterstage.com/root/social-card-preview.jpg" />
        <meta property="og:type" content="website" />
      </Helmet>
      <GlobalNav 
        categoryData={selectData}
        openAuthenticationModal={_openAuthenticationModal} 
        authenticated={authenticated}/> 
      <ContentWell>
        <ScrollToTop/>
        <MainRoutes openAuthenticationModal={_openAuthenticationModal} authenticated={authenticated}/>
      </ContentWell>
      <Footer
        openAuthenticationModal={_openAuthenticationModal}
        authenticated={authenticated}/>
      <DonateButton/>
    </div>
  </>
  );
}

export default App;
