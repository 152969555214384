import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import styled from "styled-components"
import { PillButton, Text, TextBlock, TextInput, TextLink, Loader, Modal, Body, Checkbox } from 'components';
import { colors, gradients } from 'theme'
import { SettingsNav } from 'modules';
import { entertainerSectionData, careCenterSectionData } from './data';
import { withManager } from 'utilities';
import { useSelector, 
  useDispatch
 } from 'react-redux';
import ActionTypes from 'redux/actionTypes'
import { Auth } from 'aws-amplify';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`;

const PageWrapper = styled.div`
  display: flex;
  width: ${({ viewport }) =>  viewport === 'desktop' ? 'calc(100vw - 32px)' : viewport === 'tablet' ? 'calc(100vw - 16px)' : 'calc(100vw - 8px)'};
  margin: 4px 0 8px 0;
  border-radius: 10px;
  align-items: flex-start;
  background: ${({ isEntertainer }) => isEntertainer ? gradients.blueGradient : gradients.lightBlueGradient};
  height: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: ${({ viewport }) => viewport !== 'desktop' ? '80px' : '64px'} 16px 64px 16px;
  flex-direction: ${({ viewport }) => viewport !== 'desktop' ? 'column' : 'row'};
  max-width: 1440px;
  margin: auto;
  min-height: 80vh;
`;

const NavColumn = styled.div`
  display: flex;
  align-items: flex-start;
  width: ${({ viewport }) => viewport !== 'desktop' ? '100%' : '25%'};
  margin-top: ${({ viewport }) => viewport !== 'desktop' ? '-64px' : '0'};
`;

const NavFixWrapper = styled.div`
  position: ${({ viewport }) => viewport !== 'desktop' ? 'fixed' : 'sticky'};
  top: ${({ viewport }) => viewport !== 'desktop' ? null : '157px'};
  z-index: 99;
  display: flex;
  width: ${({ viewport }) => viewport !== 'desktop' ? '100%' : null};
  flex-direction: ${({ viewport }) => viewport !== 'desktop' ? 'row' : 'column'};
`;

const ContentColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: ${({ viewport }) => viewport !== 'desktop' ? '100%' : '75%'};;
  padding-left: ${({ viewport }) => viewport !== 'desktop' ? '0' : '16px'};
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  background-color: ${colors.white};
  padding: ${({ viewport }) => viewport !== 'desktop' ? '16px' : '32px'};
  margin-bottom: 16px;
  border-radius: 10px;

  h5 {
    margin-bottom: 8px;
  }
`;

const Spacer = styled.div`
  display: flex;
  width: 100%;
  height: ${({ height }) => height ?? '32px'};
  border-top: ${({ border }) => border ? '1px solid ' + colors.grayLight : 'transparent'};
`;

const VerifyButtonWrapper = styled.div`
  display: flex;
`;


const AccountSettings = (props) => {
  const { viewport, authenticated } = props;
  const { user, fetching } = useSelector (({user})=> {
    return {
      user: user.data,
      fetching: user.fetching
    }
  })
  const dispatch = useDispatch();
  let isEntertainer = user?.group === "entertainers" || authenticated?.user?.attributes['custom:group'] === 'entertainers';

  const [newEmail, setNewEmail] = useState();
  const [currentEmail, setCurrentEmail] = useState();
  const [code, setCode] = useState();
  const [codeError, setCodeError] = useState();
  const [isFacebookUser, setIsFacebookUser] = useState();
  const [emailError, setEmailError] = useState();
  const [localFetch, setLocalFetching] = useState();
  const [openModal, setOpenModal ] = useState();
  const [deleteAccount, setDeleteAccount] = useState();
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState();
  const history = useHistory();

  const checkStatus = async () => {
    const userAuth = await Auth.currentAuthenticatedUser()
    setCurrentEmail(userAuth.attributes.email)
    if (userAuth.username.includes('Facebook')) {
      setIsFacebookUser(true)
    }
  };
    
  useEffect(() => {
    checkStatus()
  })

  const _scrollToTop = () => {
    window.scrollTo({
      top: 0,})
  }

  useEffect(_scrollToTop)

  const _onEmailChange = (e) => {
    setNewEmail(e.target.value);
  }

  const _onVerificationChange = (e) => {
    setCode(e.target.value);
    setEmailError(null)
  }

  const _updateEmail = async () => {
    if(!newEmail) return;
    setLocalFetching(true)
    const userAuth = await Auth.currentAuthenticatedUser()
    await Auth.updateUserAttributes(userAuth, { email: newEmail })
    .then(() => {
      setLocalFetching(false)
      setOpenModal(true)
    })
    .catch((err) => {
      setEmailError(err)
    })
  }

  const _emailVerification = async () => {
    const userAuth = await Auth.currentAuthenticatedUser()
    await Auth.verifyCurrentUserAttributeSubmit("email", code)
    .then(async () => {
        localStorage.setItem('AUTH_USER_TOKEN_KEY', userAuth.signInUserSession.accessToken.jwtToken);
        await Auth.updateUserAttributes(userAuth, { 'email_verified': true })
        .then(() => setOpenModal(false))
        .catch((err) =>setCodeError(err))
    })
    .catch((err) => {
      console.log('ERROR: ', err)
      setCodeError(err)
    })
  }

  const _resendVerificationCode = async () => {
    setEmailError(null)
    const userAuth = await Auth.currentAuthenticatedUser()
    setLocalFetching(true)
    await Auth.updateUserAttributes(userAuth, { email: newEmail })
    .then(async () => {
      setLocalFetching(false)
      setOpenModal(true)
    }) 
    .catch((err) => console.log('ERROR: ', err))
  }

  const _renderVerificationModal = () => {
    return (
      <Modal 
        title="Verify your updated email."
        width='474px'
        open={openModal}
        onClose={() => setOpenModal(false)}>
      <Wrapper>
        <Body>
          {`One last step. We just sent an email to ${newEmail}. Please insert the 6-digit-code from the email below.`}
        </Body>
      </Wrapper>
      <Wrapper>
        <TextInput 
          type='number'           
          name='confirmationCode'
          placeholder='000000' 
          error={codeError}
          errorText={codeError?.message}
          onChange={_onVerificationChange} 
          label="Verification Code"/>
      </Wrapper>
      <VerifyButtonWrapper>
        <PillButton 
          disabled={!code}
          color={colors.babyBlue} 
          onClick={_emailVerification} 
          label="Sign In" /><div style={{width: '16px'}}></div>
        <TextLink disabled={!newEmail} label="Resend Code" onClick={_resendVerificationCode}/>
      </VerifyButtonWrapper>
      </Modal>
    )
  }

  const _deleteAccount = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const at = localStorage.getItem('AUTH_USER_TOKEN_KEY');
    let body = { id: user.attributes.sub }
    
    // delete from db
    dispatch({ body, at, type: ActionTypes.user.deleteUser.START });
    
    // delete from cognito
    user.deleteUser((error, data) => {
      if (error) {
        throw error;
      }
      setOpenDeleteAccountModal(false)
      Auth.signOut().then(() => {
        localStorage.clear();
        history.go(0)
      })
    });
  }

  const _renderDeleteAccountModal = () => {
    return (
      <Modal 
        title="Sorry to see you go!"
        width='474px'
        open={openDeleteAccountModal}
        onClose={() => setOpenDeleteAccountModal(false)}>
      <Wrapper>
        <Body>By clicking the button below, your account and all information associated with it will be deleted.
        </Body>
      </Wrapper>
      <Spacer/>
      <PillButton 
        color={colors.babyBlue} 
        onClick={_deleteAccount} 
        label="Delete Account" />
      </Modal>
    )
  }

  const _renderAccountSettings = () => (
    <ContentColumn viewport={viewport}>
      {viewport !== 'desktop' && <Spacer height='64px' />}
      <TextBlock
        maxWidth='572px'
        color={colors.white}
        primitive="h2"
        titleSize='xlarge'
        title='Account Settings'
        subtitle='Edit your account settings.' />

      <Spacer height='32px' />

      <SectionWrapper viewport={viewport}>
        <Text bold primitive='h5' size="small" color={colors.pink}>Account Information</Text>
        <TextInput
          disabled={isFacebookUser}
          error={emailError}
          errorText={emailError?.message}
          defaultValue={currentEmail}
          onChange={(e) => _onEmailChange(e)}
          helperText=""
          name="accountEmail"
          label="Account Email" />
        <Body size="xsmall">This is the email associated with your account, you use this email to log into your account.</Body>
        {isFacebookUser && 
          <>
            <Spacer height="8px"/> 
            <Body size="xsmall" color={colors.error}>Your account was activated using Facebook, please contact Facebook to change your account email address.</Body>
          </>
        }
        <Spacer/>
        <PillButton disabled={!newEmail || (newEmail === currentEmail) || isFacebookUser} onClick={_updateEmail} color={colors.pink} size='large' label='Verify New Email' />
      </SectionWrapper>

      <SectionWrapper viewport={viewport}>
        <Text bold primitive='h5' size="small" color={colors.pink}>Delete Account</Text>
        <Body size="xsmall">Deleting your account will delete all of your information.</Body>
        <Spacer/>
        <Checkbox
            name='deleteAccount'
            onChange={(e) => setDeleteAccount(e.target.value)}
            label="I want to delete my account and all of the information associated with it." />
        <Spacer/>
        <PillButton 
          disabled={!deleteAccount} 
          onClick={() => setOpenDeleteAccountModal(true)} 
          color={colors.pink} 
          size='large' 
          label='Delete Account'/>
      </SectionWrapper>
    </ContentColumn>
  );

  let newData = isEntertainer ? entertainerSectionData : careCenterSectionData;
  return (
    <Wrapper>
      {_renderVerificationModal()}
      {_renderDeleteAccountModal()}
      <Loader visible={fetching || localFetch} />
      <PageWrapper viewport={viewport} isEntertainer={isEntertainer}>
        <InnerWrapper viewport={viewport}>

          <NavColumn viewport={viewport}>
            <NavFixWrapper viewport={viewport}>
              <SettingsNav entertainer={isEntertainer} data={newData} />
            </NavFixWrapper>

          </NavColumn>
            {_renderAccountSettings()}
        </InnerWrapper>
      </PageWrapper>
    </Wrapper>

  )
}

export default withManager(AccountSettings);