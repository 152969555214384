import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { 
  Modal,
  PillButton,
  Text,
  TextArea,
  Body,
  RatingBadge,
  Loader
} from "components";
import { colors } from "theme";
import ActionTypes from "redux/actionTypes";
import { useParams } from "react-router";

const Wrapper = styled.div`
  padding-bottom: 32px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const InnerWrapper = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex; 
  flex-direction: row;
  align-items: center;
`;

const LeaveReview = (props) => {
  const { 
    open, 
    onClose, 
    profile, 
    user, 
  } = props;
  const params = useParams()
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState('review');
  const [inputs, setInputs] = useState();
  const [inputErrors, setInputErrors] = useState();

  const { errorPostingReview, postingReview } = useSelector (({ profile })=> {
    return {
      errorPostingReview: profile.error?.status,
      postingReview: profile.postingReview
    }
  })


  const _onChangeReview = ({target:{name,value}}) => {
    if (inputErrors?.review) setInputErrors();
    setInputs(state => ({ ...state, [name]:value }), []);
  }

  const _submitReview = async () => {
    const at = localStorage.getItem('AUTH_USER_TOKEN_KEY')

    /**
     * need to add more profile data here like , reviews.overall
     */
    let body = {
      reviewer : {
        name: user.displayName || user.name,
        id: user.id,
      },
        entertainerId: profile.id,
        rating: inputs.rating,
        review: inputs.review,
        reviewDate: new Date()
    }
    dispatch({ at, body, type: ActionTypes.profile.addReview.START })
    setInputs()

  };

  
  useEffect (()=>{
    setModalState('review')
    //eslint-disable-next-line
  }, [params.id])


  useEffect (()=>{
    if (postingReview || postingReview === undefined) return;
    else if (errorPostingReview) setModalState('error')
    else if (user.reviewed.includes(profile.id)) setModalState('success')
    else setModalState('review')
    //eslint-disable-next-line
  }, [postingReview])


  const _onClickLeaveReview = async () => {
    if(!inputs) return setInputErrors(state => (
      { 
        ...state, 
        rating: true,
        review: true
      }
      ), [])
    
    if (!inputs.rating || !inputs.review) return setInputErrors(state => (
      { 
        ...state, 
        rating: !inputs.rating,
        review: !inputs.review
      }
      ), [])

    _submitReview()
  }

  const _handleRatingsChange = (rating) => {
    setInputs(state => ({ ...state, rating:rating }), []);
  }

  const _renderForm = () => {
    return (
      <>
        <FormWrapper>
          <InnerWrapper>
            <Text style={{paddingRight: '12px'}} bold size="xsmall" color={colors.pink}>Reviewer Name:</Text>
            <Body>{user.displayName}</Body>
          </InnerWrapper>
          <InnerWrapper>
            <RatingBadge interactive onChange={_handleRatingsChange}/>
            {inputErrors?.rating && inputErrors.rating && <Text size = 'xsmall' color={colors.error}> Please select a rating</Text>}
          </InnerWrapper>
          <InputWrapper>
            <TextArea
              error={inputErrors?.review}
              errorText="Please write your review in this section to submit."
              name="review"
              onChange={_onChangeReview}
              placeholder='Tell us about your experience'/>
          </InputWrapper>
        </FormWrapper>
      </>
    )
  }
  

  const _onCloseModal = () => {
    onClose && onClose();
    modalState !== 'review' && setModalState('review');
  }

  const _renderModalTitle = () => {
    switch(modalState){
      case 'review':
        return `Leave ${profile.displayName} a review.`; 
      case 'success':
        return 'Success!';
      case 'error':
        return `Oh No! There has been an error`;
        default: return '';
    }
  }
  
  const _renderModalSubtitle = () => {
    switch(modalState){
      case 'review':
        return `Fill out the information below to review ${profile.displayName}.`
      case 'success':
        return `Thanks for leaving ${profile.displayName} a review!`;
      case 'error':
        return `Sorry, something went wrong reviewing ${profile.displayName}. Please try again later.`;
        default: return '';
    }
  }

  return (
    <>
      <Loader visible={postingReview}/>
      <Modal 
        titleColor={modalState === 'error'  ? colors.error : null}
        title={_renderModalTitle()}
        subtitle={_renderModalSubtitle()}
        open={open} 
        onClose={_onCloseModal}>
      <Wrapper>
        {modalState === 'review' && _renderForm()}
      <PillButton
        disabled={!inputs && modalState !== 'success' }
        type="submit"
        label={modalState === 'success' ? "Go Back" : "Leave review"}
        color={modalState === 'success'  ? colors.pink : colors.babyBlue} 
        onClick={modalState === 'success' || modalState === 'error' ? _onCloseModal : _onClickLeaveReview} />
      </Wrapper>
    </Modal>
    </>
  )
};

export default LeaveReview;
