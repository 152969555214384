import { useEffect, useState } from 'react';
import styled from 'styled-components';
import cuid from 'cuid';
import { Body } from 'components';
import PropTypes from 'prop-types';
import { colors } from 'theme';

const propTypes = {
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    textColor: PropTypes.string,
}

const defaultProps = {
    label: 'RadioButton Text',
    selected: false,
    textColor: colors.gray
}

const OuterIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 20px;
    height: 20px;
    border: 2px solid ${colors.pink + '4D'} ;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 6px;
`;

const InnerCircle = styled.div`
    height: 16px;
    width: 16px;
    background-color: ${({ selected }) => selected ? colors.pink : 'transparent'};
    border-radius: 50%;
    cursor: pointer;
`;

const HiddenInput = styled.input`
    cursor: pointer;
    clip: rect(0, 0, 0, 0);
    opacity: 0;
    position: absolute;
    ${({ clicked }) => !clicked && `
        &:focus:not(:hover) + label {
            ${OuterIconWrapper} {
                &::before {
                    display: block;
                    content: "";
                    position: absolute;
                    width: calc(100% + 6px);
                    height: calc(100% + 6px);
                    border-radius: 50%;
                    border: 1px dashed ${colors.pink};
                }
            }
        }
    `};
`;

const RadioButtonWrapper = styled.div``;


const LabelWrapper = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    &:hover {
        ${OuterIconWrapper} {
            border-color: ${colors.pink};
        }
        ${InnerCircle} {
            background-color: ${({ selected }) => selected ? colors.pink : colors.pink + '4D'};
        }
    }
`;

const RadioButton = props => {
    const { label, selected, textColor } = props;
    const [clicked, setClicked] = useState(false);
    let radioId = cuid();

    const checkForTabFocus = e => {
        if (e.keyCode === 9) {
            setClicked(false);
        };
    }

    useEffect(() => {
        document.addEventListener('keydown', checkForTabFocus);
        return () => document.removeEventListener('keydown', checkForTabFocus);
    })

    const blurOnChange = e => {
        if (e.clientX !== 0) {
            setClicked(true);
        } else {
            setClicked(false);
        }
    }
    return (
        <RadioButtonWrapper>
            <HiddenInput {...props} id={radioId} onClick={blurOnChange} clicked={clicked} type="radio" role="radio" />
            <LabelWrapper htmlFor={radioId} selected={selected}>
                <OuterIconWrapper>
                    <InnerCircle selected={selected} />
                </OuterIconWrapper>
                <Body color={textColor} size='small'>
                    {label}
                </Body>
            </LabelWrapper>
        </RadioButtonWrapper>
    )
}

RadioButton.propTypes = propTypes;
RadioButton.defaultProps = defaultProps;

export default RadioButton;