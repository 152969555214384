import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { titleConfig } from '../typeConfig';
import { colors } from 'theme';
import { withManager } from 'utilities';

const propTypes = {
  primitive: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'article', 'span']),
  size: PropTypes.oneOf(['xxlarge', 'xlarge', 'large', 'medium', 'small', 'xsmall', 'xxsmall', 'xxxsmall', 'body', 'bodySmall']),
  children: PropTypes.any,
  bold: PropTypes.bool,
  color: PropTypes.string,
  align: PropTypes.string,
},
  defaultProps = {
    primitive: 'p',
    size: 'xxlarge',
    align: 'left',
    color: colors.blue,
  };

const Wrapper = styled(props => {
  return React.createElement(props.primitive, props, props.children);
})`
  line-height: ${({ fontStyle }) => fontStyle.lineHeight};
  font-family: dunbar-tall, Helvetica, sans-serif;
  font-size: ${({ fontStyle }) => fontStyle.fontSize};
  letter-spacing: ${({ fontStyle }) => fontStyle.letterSpacing};
  font-weight: ${({ $bold }) => $bold ? 'bold' : 'normal'};
  text-align: ${({ align }) => align};
  color: ${({ color }) => color};
  margin: 0;
  text-decoration: none;
`;

const Text = (props) => {
  const {
    children,
    size,
    viewport,
    bold,
    ...rest
  } = props;

  const fontStyle = titleConfig[size][viewport];

  return (
    <Wrapper
      {...rest}
      $bold={bold}
      size={size}
      fontStyle={{ ...fontStyle }}>
      {children}
    </Wrapper>
  )
};

Text.propTypes = propTypes;
Text.defaultProps = defaultProps;
export default withManager(Text);