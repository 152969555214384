import { useEffect, useState } from "react";
import styled from "styled-components";
import cuid from "cuid";
import { Body, Icon } from "components";
import PropTypes from "prop-types";
import { colors } from "theme";

const propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool,
};

const defaultProps = {
  label: "Checkbox Text",
  selected: false,
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 21px;
  height: 21px;
  border: 2px solid ${colors.pink + "4D"};
  background-color: ${({ checked }) => (checked ? colors.pink : colors.white)};
  border-radius: 6px;
  cursor: pointer;
  margin-right: 8px;
	box-sizing: border-box;
	padding: 2px;
`;

const HiddenInput = styled.input`
  cursor: pointer;
  clip: rect(0, 0, 0, 0);
  opacity: 0;
  position: absolute;
    
  &:focus:not(:hover) + label {
    ${IconWrapper} {
      &::before {
        display: block;
        content: "";
        position: absolute;
        left: -4px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border-radius: 6px;
        border: 1px dashed ${colors.pink};
      }
    }
  }
;
`;

const CheckboxWrapper = styled.div``;
const Empty = styled.div`
	width: 13px;
`;

const LabelWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`;

const Checkbox = (props) => {
  const { label, onChange, name, defaultValue } = props;
  const [checked, setChecked] = useState(defaultValue);
  let checkboxId = cuid();
  const _onChange = (e) => {
    setChecked(!checked)
    onChange({ target: { name, value: !checked } })
  }
  useEffect(()=>{
    setChecked(defaultValue)
  },[defaultValue])

  return (
    <CheckboxWrapper>
      <HiddenInput
        onClick={(e) => e.currentTarget.blur()}
        {...props}
        onChange={_onChange}
        defaultChecked={defaultValue}
        id={checkboxId}
        name={name}
        type="checkbox"
      />
      <LabelWrapper
        htmlFor={checkboxId}>
        <IconWrapper
          checked={checked}>
          {checked ? <Icon size="medium" name="check" color={colors.white} /> : <Empty />}
        </IconWrapper>
        <Body color={colors.gray} size="small">
          {label}
        </Body>
      </LabelWrapper>
    </CheckboxWrapper>
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
