import styled from 'styled-components';
import { colors } from 'theme';
import PropTypes from 'prop-types';

const propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
},
  defaultProps = {
    color: colors.blue,
    size: 'medium'
  };

const BORDER_RADIUS = 10

const Text = styled.span`
  font-family: dunbar-tall, Helvetica, sans-serif;
  font-size: ${({ size }) => size === 'small' ? '14px' : '16px'};
  letter-spacing: 0.72px;
  font-weight: ${({ $bold }) => $bold ? '700' : '500'};
  line-height: ${({ size }) => size === 'small' ? '18px' : '21px'};
  color: ${({ color }) => color};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
`;

const Wrapper = styled.span`
  position: relative;
  display: ${({ inline }) => inline ? 'inline-block' : 'flex'};
  width: fit-content;
  border-bottom: none;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  transition: all .3s ease-in-out;
  text-decoration: none;
  outline: none;
  ${({ disabled, color }) => !disabled && `
  ${Text} {
    transition: all 0.3s ease-in-out;
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: ${color};
    transform: scaleX(0);
    transform-origin: top left;
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    ${Text} {
      color: ${color === colors.white ? colors.white : colors.pink};
      transition: all 0.3s ease-in-out;
    }

    &::before {
      opacity: 1;
      transform: scaleX(1);
      background-color: ${color === colors.white ? colors.white : colors.babyBlue};
      transition: all 0.3s ease-in-out;
    }
  }
  
  &:focus {
    &::after {
      position: absolute;
      content: '';
      z-index: 100;
      top: 50%;
      left: 50%;
      bottom: 50%;
      right: 50%;
      width: calc(100% + 6px);
      height: calc(100% + 6px);
      border: 1px dashed black;
      transform: translate(-50%,-50%);
      border-radius: ${BORDER_RADIUS}px;
    }
  }
  
  `};
`;

const StyledLink = styled.a`
  text-decoration: none;
  outline: none;
  text-align: left;
  pointer-events: ${({ disabled }) => disabled && 'none'};
  touch-action: ${({ disabled }) => disabled && 'none'};
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  text-align: left;
`;

const TextLink = (props) => {
  const { label, color, href, bold, size, onClick, disabled } = props;

  let ConditionalType = href ? StyledLink : StyledButton;

  return (
    <Wrapper inline={props.inline} color={color} disabled={disabled}>
      <ConditionalType {...props} onClick={onClick} href={href}>
        <Text
          size={size}
          $bold={bold}
          color={disabled ? colors.darkGray : color}
          disabled={disabled}>
          {label}
        </Text>
      </ConditionalType>
    </Wrapper>
  )
};

TextLink.propTypes = propTypes;
TextLink.defaultProps = defaultProps;
export default TextLink