import { RatingBadge, Text, PillButton, Body, TextLink } from 'components';
import styled from 'styled-components';
import { colors } from 'theme';
import { withManager } from 'utilities';

const ComponentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const BottomPadding = styled.div`
	display: flex;
	align-items: center;
	padding-bottom: ${({ padding }) => padding ?? 16}px;
`;

const FlexCol = styled.div`
	display: flex;
	flex-direction: column;
`;

const RatingWrapper = styled.div`
	padding-left: 8px;
`;

const FlexRow = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
`;


const Reviews = props => {
	const { 
		displayName,
		user,
		onClickLeaveReview, 
		reviews, 
    profileId,
		viewport 
	} = props;
	const reviewLength = reviews?.data?.length.toString();
	const reviewText = reviewLength > 1 ? `(${reviewLength} Reviews)` : reviewLength === '1' ? `(1 Review)` : `(No Reviews)`;
	
    const _renderSmallerRatings = () => {
			let sorted = reviews.data.sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0))
      return sorted.map((comment, index) => {
        const { rating, date, review, reviewerName } = comment;
        const format = {year: 'numeric', month: 'long', day: 'numeric' };
        const newDate = new Date(date).toLocaleDateString("en-US", format)

        return (
          <FlexCol key={index}>
            <BottomPadding padding={8}>
            {viewport !== 'desktop' &&
              <FlexCol>
                <BottomPadding padding={8}>
                  <RatingBadge rating={rating} />
                </BottomPadding>
									<FlexRow>
										<Text color={colors.navyBlue} size='xxsmall'>{newDate}</Text>
										<Text color={colors.pink} size='xxsmall'>・</Text>
										<Text color={colors.navyBlue} size='xxsmall'>{reviewerName}</Text>
									</FlexRow>
              </FlexCol>}
              {viewport === 'desktop' &&
                <>
                  <RatingBadge rating={rating} />
									<Text color={colors.pink} size='xxsmall'>・</Text>
									<Text color={colors.navyBlue} size='xxsmall'>{newDate}</Text>
									<Text color={colors.pink} size='xxsmall'>・</Text>
									<Text color={colors.navyBlue} size='xxsmall'>{reviewerName}</Text>
                </>}
            </BottomPadding>
            <BottomPadding padding={32}>
              <Body color={colors.gray}>{review}</Body>
             </BottomPadding>
          </FlexCol >)
      })
		}




		const _renderReviewButton = () => {
			/**add login logic to this text link */
		  if (!user) return (<TextLink size='small' label='Carecenters, sign in to leave a review'/>)
			if (user?.group === 'entertainers' || user?.reviewed?.includes(profileId)) return 
			return(
				<PillButton label="WRITE A REVIEW" onClick={onClickLeaveReview} />
			)
		}

		const _renderReviews = () => {
			const roundedOverallRating = Math.round(reviews.overallRating * 100) / 100;
			return (
					<>
					<BottomPadding padding={reviews.overallRating ? 32 : 16}>
						<Text color={colors.pink} bold size='small'>{roundedOverallRating}</Text>
						<RatingWrapper>
							<RatingBadge rating={reviews.overallRating} hideNumber />
						</RatingWrapper>
						<Body size='xsmall' color={colors.darkGray}>{reviewText}</Body>
						<div style={{paddingLeft: '20px'}}>{_renderReviewButton()}</div>
					</BottomPadding>
					<ComponentWrapper>
						<BottomPadding>
							<Text size='small' color={colors.navyBlue} bold> Read Reviews </Text>
						</BottomPadding>
						{_renderSmallerRatings()}
					</ComponentWrapper>
					</>
			)
		}



		const _renderPlaceholder = () => {
			let text, subtext;
			if (!user){
				text = `${displayName} hasn't been reviewed yet.`;
				subtext = <Text size='xsmall'>Carecenters, sign in to leave a review.</Text>
			} else if (user?.group === 'entertainers') {
				text = `${displayName} hasn't been reviewed yet.`
				subtext = <Text color={colors.pink} size='xsmall'>Entertainer reviews can only be left by Carecenters.</Text>
			} else {
				text = <PillButton label="WRITE A REVIEW" onClick={onClickLeaveReview} />
			}

			return (
				<>
				<BottomPadding padding={32}>
          <Body color={colors.gray}>{text}</Body>
        </BottomPadding>
					<FlexCol>
						{subtext}
					</FlexCol>
					</>
			)
		}
    return (
      <ComponentWrapper>
        <BottomPadding>
          <Text bold size='medium' color={colors.blue}>{`${displayName}'s Reviews`}</Text>
        </BottomPadding>
				{reviews && _renderReviews()}
				{!reviews && _renderPlaceholder()}
        </ComponentWrapper>
    )
}

export default withManager(Reviews);