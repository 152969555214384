import styled from 'styled-components';
import cuid from "cuid";
import PropTypes from "prop-types";
import { Body, TextLink } from 'components';
import { colors } from 'theme'
import { useEffect, useState } from 'react';

const propTypes = {
  label: PropTypes.string, 
  type: PropTypes.string, 
  labelColor: PropTypes.string, 
  error: PropTypes.bool, 
  errorText: PropTypes.string, 
  helperText: PropTypes.string, 
  placeholder: PropTypes.string, 
  width: PropTypes.string, 
  helperLink: PropTypes.string,
  helperLinkHref: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool
};

const defaultProps = {
  onChange: () => { },
  disabled: false
};
const Wrapper = styled.div`
  display: flex;
  padding-bottom: ${({ error, errorText }) => error && errorText ? '4px' : '16px'};
  flex-direction: column;
  width: ${({ width }) => width ?? '100%'};
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
`;

const Label = styled.label`
  width: 100%;
  padding-bottom: 8px;
  display: ${({ hide }) => hide && 'none'};
`;

const TextWrapper = styled.div`
  width: 100%;
  `

const Input = styled.input`
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 18px;
  font-weight: normal;
  font-family: dunbar-text;
  font-weight: normal;
  color: ${({ error, disabled }) => disabled ? colors.darkGray : error ? colors.error : colors.babyBlue};
  padding: 0px 16px 0px 16px;
  border-radius: 10px;
  border: 2px solid ${({ error }) => error ? colors.error : colors.grayLight};
  box-sizing: border-box;
  transition: all 0.3s ease-in;
  ${({ disabled }) => {
    if (disabled) {
      return `
      touch-action: none;
      pointer-events: none;
    `;
    } else {
      return `
        &:hover{
          outline: none;
          padding: 0px 16px 0px 15px;
          border: 3px solid ${colors.grayLight};
        }
        &:focus{
          outline: none;
          padding: 0px 16px 0px 15px;
          border: 3px solid ${colors.babyBlue};
        }`;
    }
  }}
  ::placeholder {
    color: ${colors.grayLight};
  }

`;

const TextInput = (props) => {
  const {
    label,
    type,
    labelColor: labelColorProp,
    error,
    errorText,
    helperText,
    placeholder,
    width,
    helperLink,
    helperLinkHref,
    onChange,
    defaultValue,
    name,
    disabled,
    inputRef
  } = props;
  let inputId = cuid();
  const labelColor = disabled ? colors.darkGray : labelColorProp;

  const [dv, setDefaultValue ] = useState();

  //allows default value change to cause a rerender by applying this value to the key
  useEffect(()=>{
    setDefaultValue(defaultValue)
  }, [defaultValue])

  return (
    <Wrapper
      width={width}
      error={error}
      errorText={errorText}
      helperText={helperText}>
      <Label
        htmlFor={inputId}
        hide={placeholder && !label}>
        <Body
          size="small"
          bold
          color={labelColor ?? colors.blue}>{placeholder && !label ? placeholder : label}
        </Body>
        {helperLink &&
          <TextWrapper>
            <TextLink
              size='small'
              color={colors.pink}
              label={helperLink}
              target='_blank'
              href={helperLinkHref} />
          </TextWrapper>}
      </Label>
      <Input
        {...props}
        ref={inputRef}
        key={dv}
        name={name}
        id={inputId}
        defaultValue={dv}
        onChange={onChange}
        placeholder={placeholder}
        error={error}
        errorText={errorText}
        type={type}
        disabled={disabled} />
      {(error && errorText) &&
          <TextWrapper>
            <Body
              size="xsmall"
              color={colors.error}>
              {errorText}
            </Body>
          </TextWrapper>}
      {helperText &&
        <TextWrapper>
          <Body
            size="xsmall"
            color={colors.gray}>{helperText}
          </Body>
        </TextWrapper>}

      {(props.currentLocationLink) &&
        <TextWrapper>
            <TextLink
              label="Use Current Location"
              onClick={props.currentLocationClick}
              size='small'
              disabled={disabled}
            />
        </TextWrapper>}
        
    </Wrapper>
  )
};

TextInput.propTypes = propTypes;
TextInput.defaultProps = defaultProps;

export default TextInput