import styled from "styled-components";
import PropTypes from "prop-types";
import { TextBlock, PillButton } from "components";
import { colors, gradients } from "theme";
import { useHistory } from "react-router-dom";

const propTypes = {
  imageSource: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  category: PropTypes.string,
  hideButton: PropTypes.bool
};

const defaultProps = {};

const
  PADDING_TOP = '64px',
  PADDING_SIDES = '32px',
  BORDER_RADIUS = '10px';


const ComponentWrapper = styled.div`
  padding: ${PADDING_TOP} 0;
	border-radius: ${BORDER_RADIUS};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  min-height: 300px;
  box-sizing: border-box;
  background: ${gradients.triColorGradient};
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
	margin-bottom: ${({ hideButton }) => hideButton ? 0 : 32}px;
  padding: 0 ${PADDING_SIDES};
`;

const JoinHero = (props) => {
  const history = useHistory();
  let
    joinHeader = "Join our growing network.",
    joinBody = "Entertainers and care center community leaders come together at Care Center Stage. Sign up now to make meaningful connections, find all-new opportunities, and celebrate high-quality entertainment.";

  return (
    <ComponentWrapper>
      <ContentWrapper>
        <TextBlock
          align="center"
          maxWidth="572px"
          color={colors.white}
          primitive="h3"
          titleSize="xlarge"
          title={joinHeader}
          subtitle={joinBody}
        />
      </ContentWrapper>
      <PillButton color={colors.pink} onClick={() => {history.push('/sign-up')}} label="LEARN MORE" />
    </ComponentWrapper>
  );
};

JoinHero.propTypes = propTypes;
JoinHero.defaultProps = defaultProps;

export default JoinHero;
