import { takeLatest } from "redux-saga/effects";
import ActionTypes from '../actionTypes';
import { putInit, getInit, getSettings, saveSettings, setAvatar, deleteImage, saveImage, deleteUser } from './user.saga'
import { getProfile, getFeatured, searchProfiles } from './profile.saga';
import { sendEmailToEntertainer } from './contact.saga'
import { addReview } from './reviews.saga'

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* watcherSaga() {

  /**
   * profile
   */
  yield takeLatest(ActionTypes.profile.getOne.START, getProfile);
  yield takeLatest(ActionTypes.profile.getFeatured.START, getFeatured);
  yield takeLatest(ActionTypes.profile.search.START, searchProfiles);


  /**
   * user login
  */

  //  yield takeLatest(ActionTypes.auth.login.START, getFeatured);
  //  yield takeLatest(ActionTypes.auth.signUp.START, getFeatured);
  //  yield takeLatest(ActionTypes.auth.signOut.START, getFeatured);

  /**
   * entertainer authenticated
   */
  yield takeLatest(ActionTypes.user.putInit.START, putInit);
  yield takeLatest(ActionTypes.user.getInit.START, getInit);
  yield takeLatest(ActionTypes.user.getSettings.START, getSettings);
  yield takeLatest(ActionTypes.user.saveSettings.START, saveSettings);
  yield takeLatest(ActionTypes.user.setAvatar.START, setAvatar);
  yield takeLatest(ActionTypes.user.deleteImage.START, deleteImage);
  yield takeLatest(ActionTypes.user.saveImage.START, saveImage);
  yield takeLatest(ActionTypes.user.deleteUser.START, deleteUser);
  /**
   * carecenter authenticated
   */
  yield takeLatest(ActionTypes.entertainerContact.sendEmail.START, sendEmailToEntertainer);
  yield takeLatest(ActionTypes.profile.addReview.START, addReview);
}