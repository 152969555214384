import styled from "styled-components";
import React, { useRef, useState, useEffect, useMemo } from "react";
import { ProfileCard } from "modules";
import throttle from "lodash/throttle";
import { withManager } from "utilities";

const ScrollBox = styled.div`
  display: flex;
  position: relative;
  width: 99vw;
  overflow: hidden;
  touch-action: pan-x;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  cursor: grab;
  touch-action: pan-x;
  pointer-events: ${({ isDragging }) => (isDragging ? "none" : undefined)};
  padding: ${({ viewport }) => viewport !== "desktop" ? '32px 16px' : '32px 64px 64px 64px'};

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled.div`
  height: 100%;
  display: inline-flex;
  touch-action: pan-x;
  pointer-events: ${({ isDragging }) => (isDragging ? "none" : undefined)};
  /* .firstChild {
    margin-left: ${({ viewport }) => (viewport !== "desktop" ? "0" : "1.5%")}; */
  }
`;

const CardWrapper = styled.div`
  padding-right: ${({ viewport }) => (viewport !== "desktop" ? "16px" : "32px")};
`;

const timing = (1 / 60) * 1000;
const decay = (v) => -0.1 * ((1 / timing) ^ 4) + v;

const CardSlider = (props) => {
  const { data, viewport } = props;
  const scrollWrapperRef = useRef();
  const [clickStartX, setClickStartX] = useState();
  const [scrollStartX, setScrollStartX] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [direction, setDirection] = useState(0);
  const [momentum, setMomentum] = useState(0);
  const [lastScrollX, setLastScrollX] = useState(0);
  const [speed, setSpeed] = useState(0);
  const scrollWrapperCurrent = scrollWrapperRef.current;
  

  const handleThrottleX = useMemo(
    () => throttle((screenX) => {
      setLastScrollX(screenX)
    }, [timing]),
    []
  );

  const handleMomentum = useMemo(
    () => throttle((nextMomentum) => {
      setMomentum(nextMomentum);
      scrollWrapperRef.current.scrollLeft =
        scrollWrapperRef.current.scrollLeft + nextMomentum * timing * direction;
    }, timing),
    [direction]
  );

  useEffect(() => {
    if (direction !== 0) {
      if (momentum > 0.1 && !isDragging) {
        handleMomentum(decay(momentum));
      } else if (isDragging) {
        setMomentum(speed);
      } else {
        setDirection(0);
      }
    }
  }, [momentum, isDragging, speed, direction, handleMomentum]);

  useEffect(() => {
    if (scrollWrapperRef.current) {
      const handleDragStart = (e) => {
        setClickStartX(e.screenX);
        setScrollStartX(scrollWrapperRef.current.scrollLeft);
        setDirection(0);
      };
      const handleDragMove = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (clickStartX !== undefined && scrollStartX !== undefined) {
          const touchDelta = clickStartX - e.screenX;
          scrollWrapperRef.current.scrollLeft = scrollStartX + touchDelta;

          if (Math.abs(touchDelta) > 1) {
            setIsDragging(true);
            setDirection(touchDelta / Math.abs(touchDelta));
            setSpeed(Math.abs((lastScrollX - e.screenX) / timing));
            handleThrottleX(e.screenX);
          }
        }
      };
      const handleDragEnd = () => {
        if (isDragging && clickStartX !== undefined) {
          setClickStartX(undefined);
          setScrollStartX(undefined);
          setIsDragging(false);
        }
      };

      if (scrollWrapperRef.current.ontouchstart === undefined) {
        scrollWrapperRef.current.onmousedown = handleDragStart;
        scrollWrapperRef.current.onmousemove = handleDragMove;
        scrollWrapperRef.current.onmouseup = handleDragEnd;
        scrollWrapperRef.current.onmouseleave = handleDragEnd;
      }
    }
  }, [
    scrollWrapperCurrent,
    clickStartX,
    isDragging,
    scrollStartX,
    handleThrottleX,
    lastScrollX,
  ]);

  return (
    <ScrollBox>
      <Wrapper ref={scrollWrapperRef} viewport={viewport}>
        <Container isDragging={isDragging} role="list" viewport={viewport}>
          {data?.map((item, index) => {
            if (!item.categories) return null;
            return (
                <CardWrapper role="listitem" key={index} className={index === 0 ? "firstChild" : null} viewport={viewport}>
                  <ProfileCard
                    id={item.id}
                    imageSource={item.avatarUrl}
                    name={item.displayName}
                    categories={item.categories}
                    location={item.location}
                    tags={item.tags}
                    profileId={item.id}
                    virtual={item.location?.virtual}
                    rating={item.reviews?.overallRating}
                  />
                </CardWrapper>
            );
          })}
        </Container>
      </Wrapper>
    </ScrollBox>
  );
};

export default withManager(CardSlider);
