import ActionTypes from '../actionTypes';
const initialState = {};

export default function reduceEntertainerContact(state = initialState, action) {
  const { type } = action;
  if (!type) return state;
  switch (type) {
    case ActionTypes.entertainerContact.sendEmail.START: 
      return {
        ...state,
        error: null,
        success: null,
        fetching: true,
        body: action
      }
    case ActionTypes.entertainerContact.sendEmail.ERROR:
      return {
        ...state,
        error: action.error,
        success: null,
        fetching: false
      }
    case ActionTypes.entertainerContact.sendEmail.SUCCESS:
      const { response } = action;
      return {
        ...state,
        error: null,
        fetching: null,
        success: true,
        body: response,
        action: action
      }
    default:
      return state;
  }
}