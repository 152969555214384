import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from "styled-components"
import { useSelector, useDispatch } from 'react-redux'
import { CardSlider, PillButton, TextBlock } from 'components';
import { colors } from 'theme'
import { JoinHero, HomeHero } from 'modules';
import { homeData, selectData } from './data';
import { withManager } from 'utilities';
import ActionTypes from 'redux/actionTypes';

const Homepage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`;

const SectionWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 400px;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor ?? backgroundColor};
  padding: ${({ noPadding, viewport }) => noPadding ? '0' : viewport === 'mobile' ? '16px 8px' : '32px 16px'};
  flex-direction: ${({ column, viewport }) => viewport !== 'desktop' ? 'column' : column ? 'column' : 'row'};
  max-width: 1160px;
  box-sizing: border-box;
`;

const SpaceWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  height: ${({ height }) => height ?? '32px'};
`;

const IntroImage = styled.img`
  display: flex;
  width: 50%;
  margin-right: 32px;
  max-width: 500px;
  margin-top:  ${({ viewport }) => viewport !== 'desktop' ? '0' : '-96px'};
  position: relative;
  z-index: ${({viewport}) => viewport !== 'desktop' ? 1 : 3};
`;

const ThirdColumn = styled.div`
  width: ${({ viewport }) =>
    viewport === 'desktop' ? '32.75%'
      : viewport === 'tablet' ? '49%'
        : '100%'};
  margin-bottom: 8px;
`;

const CategoryButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 32px 0;
`;

const Home = (props) => {
  const { viewport, openAuthenticationModal } = props;
  const { hero, intro, categories, entertainers } = homeData;
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = viewport !== 'desktop';
  const featured = useSelector((state) => state.profile.featured)

  const _getFeatured = () => {
    dispatch({ params, type: ActionTypes.profile.getFeatured.START })
  }
  useEffect(_getFeatured, [params, dispatch])

  return (
    <Homepage viewport={viewport}>
      <HomeHero data={hero} openAuthenticationModal={openAuthenticationModal} selectData={selectData} onSearch={(item) => {
        history.push({
          pathname: './search',
          search: `?category=${item.target.value.toLowerCase()}`
        });
      }} />

      <SectionWrapper viewport={viewport}>
        <IntroImage viewport={viewport} alt="woman playing piano" src="https://images.carecenterstage.com/root/care-center-stage-intro-slide-1-600x600.png" />
        <TextBlock
          maxWidth='542px'
          align={isMobile ? 'center' : null}
          color={colors.blue}
          primitive="h2"
          titleSize='xlarge'
          title={intro.title}
          subtitle={intro.text} />
      </SectionWrapper>

      <SpaceWrapper />
      <TextBlock
        align="center"
        maxWidth='572px'
        color={colors.pink}
        primitive="h3"
        titleSize='large'
        title={entertainers.title}
        subtitle={entertainers.text} />
      <CardSlider viewport={viewport} data={featured} />
      <PillButton
        color={colors.pink}
        label="Find More Entertainers"
        onClick={() => {
          history.push('./search');
        }}
      />
      <SpaceWrapper height="64px" />

      <div style={{ width: '99vw', backgroundColor: '#fafafa', display: 'flex', justifyContent: 'center' }}>
        <SectionWrapper>
          <TextBlock
            align="center"
            maxWidth='600px'
            color={colors.pink}
            primitive="h3"
            titleSize='large'
            title={categories.title}
            subtitle={categories.text} />
          <CategoryButtonWrapper>
            {selectData?.map((item, index) => {
              return (
                <ThirdColumn key={index} viewport={viewport}>
                  <PillButton
                    size="jumbo"
                    onClick={() => {
                      history.push({
                        pathname: './search',
                        search: `?category=${item.children.toLowerCase()}`
                      });
                    }}
                    color={colors.babyBlue}
                    label={item.children + 's'} />
                </ThirdColumn>
              )
            })}
          </CategoryButtonWrapper>
          <PillButton
            color={colors.pink}
            label="Search for Entertainers"
            onClick={() => history.push('./search')} />
        </SectionWrapper>
      </div>
      <SectionWrapper>
        <JoinHero />
      </SectionWrapper>

      <SectionWrapper>
        <TextBlock
          align="center"
          maxWidth='600px'
          color={colors.blue}
          primitive="h3"
          titleSize='large'
          title="Our Affiliates" />
          <a target="_blank" rel="noreferrer" href="https://www.karaoke-version.com/?aff=1034">
            <img alt="karaoke version logo" src="https://images.carecenterstage.com/root/care-center-stage-affiliate-karaoke.png"/>
          </a>
      </SectionWrapper>
      <SpaceWrapper height="64px" />

    </Homepage>
  )
}

export default withManager(Home);