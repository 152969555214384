let signUpData = {
  hero: {
    title: 'Find entertainment for your care community.',
    text: 'Your care community deserves high-quality entertainment. We make it easy for you to connect with talented performers from all over the world.',
  },
  ctaCareCenter: {
    title: 'I’m a Caretaker.',
    text: 'I need to find entertainers, book performances, and bring live acts to my care center group.',
    price: 'FREE'
  },
  ctaEntertainer: {
    title: 'I’m an Entertainer.',
    text: 'I want to promote my act and get gigs at some of the biggest care centers in the world.',
    price: 'FREE'
  },
  intro: {
    title: 'Welcome to a one-of-a-kind care community.',
    text: 'Care Center Stage brings care centers and entertainers together so they can enjoy live entertainment in real time—online or in person. Together, we’re creating opportunities to celebrate life, health, and happiness in care communities all across America.',
  },
  careCenterInfo: {
    title: 'Set up the perfect performance.',
    text: 'See who’s performing. Build your own playbill. Create the most memorable experience.',
    card1: {
      title: 'Purpose-built',
      text: 'Access the first and only world-wide database of entertainers that perform exclusively for the care community.'
    },
    card2: {
      title: 'Community-driven',
      text: 'Find or hire entertainers who will personalize performances for your care community.'
    },
    card3: {
      title: 'Easy-to-use',
      text: 'Access resources that will help you deliver the best experience at your care community.'
    }
  },
  entertainerInfo: {
    title: 'Find a gig anywhere in the world.',
    text: 'Join our network. Promote your act. Play your set. Make a name for yourself!',
    card1: {
      title: 'Go big',
      text: 'Join the world’s biggest and most comprehensive database of entertainers who perform for the care community.'
    },
    card2: {
      title: 'Make connections.',
      text: 'Access an endless supply of outlets and opportunities that will help you get exposure, expand your business, and get to know industry pros.'
    },
    card3: {
      title: 'Brand better',
      text: 'Leverage the marketing power of Care Center Stage so you can showcase your skills for more people than ever before.'
    }
  },
};

export { signUpData };
