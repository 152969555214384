import PropTypes from "prop-types";
import styled, { keyframes } from 'styled-components'
import { colors } from "theme";
import {ReactComponent as LogoIcon} from 'assets/specialtyIcons/care-center-stage-logoIcon.svg';
import Portal from '../modals/portal'

const propTypes = {
  visible: PropTypes.bool,
};

const defaultProps = {
};

const PulseAnimation = keyframes`
  0% {
      opacity: 1;
      transform: scale(1);
  }
  80% {
      opacity: 0;
      transform: scale(2);
  }
  100% {
      opacity: 0;
      transform: scale(3);
  }
}`;

const LoadingComponent = styled.div`
  margin:100px;
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: ${colors.white};
  cursor: pointer;
  position:relative;

	&:after {
		content:"";
		display:block;
		width: 84px;
		height: 84px;
		border-radius: 50%;
		border: 3px solid ${colors.pink};
		position:absolute;
		top:-5px;
		left:-5px;
		animation: ${PulseAnimation} 2s ease 0s infinite;
	}

	&:before {
		content:"";
		display:block;
		width: 84px;
		height: 84px;
		border-radius: 50%;
		border: 3px solid ${colors.babyBlue};
		position:absolute;
		top:-5px;
		left:-5px;
		animation: ${PulseAnimation} 2s ease 1s infinite;
	}
`;

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
	z-index: 100000;
`;

const Loader = (props) => {
  const { id, visible } = props;

  if (!visible || visible === "false") {
    return null;
  }
	let modalId;
  if (id) modalId = id;
  else modalId = 'modal'

  return visible ? (
			<Portal
				portalId={modalId}>
				<Overlay aria-busy="true">
					<LoadingComponent>
						<LogoIcon/>
					</LoadingComponent>
				</Overlay>
			</Portal>
  ) : null;
};

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;

export default Loader;
