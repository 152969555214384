import styled from 'styled-components';
import { Modal, Body, TextLink } from "components";
import { colors } from "theme";

const Spacer = styled.div`
  display: flex;
  width: 100%;
  height: ${({ height }) => height ?? '32px'};
  border-top: ${({ border }) => border ? `1px solid ${colors.grayLight}` : 'transparent'};
`;
const WelcomeModal = ({open, onClose}) => {
  return (
    <Modal
      noOverflowY={true}
      align='center'
      open={open}
      title="Welcome to the beta!"
      subtitle="Welcome and congratulations on being an early adopter to our new one-of-a-kind community."
      onClose={onClose}>
        <div style={{maxWidth: '450px', margin: '0 auto'}}>
          <Body align='center' bold color={colors.pink}>To have your profile display, please make sure you have AT LEAST the following information filled out:</Body>
          <Body align='center'>• Avatar Image</Body>
          <Body align='center'>• Category</Body>
          <Body align='center'>• Location</Body>
          <Spacer height="16px"/>
          <Body align='center' color={colors.navyBlue}>If you run into any issues, please send an email with a description of the problem to:</Body>
          <Spacer height="4px"/>
        <div style={{width: '100%', margin: '0 auto', display: 'flex', justifyContent: 'center'}}>
          <TextLink href="mailto:tom@carecenterstage.com" label="tom@carecenterstage.com"/>
        </div>
        </div>
    </Modal>
  );
}

export default WelcomeModal