import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { bodyConfig } from '../typeConfig';
import { colors } from 'theme';
import { withManager } from 'utilities';

const propTypes = {
  primitive: PropTypes.oneOf(['p', 'article', 'span', 'li']),
  size: PropTypes.oneOf(['medium', 'small', 'xsmall']),
  children: PropTypes.oneOfType([ PropTypes.string, PropTypes.object]),
  bold: PropTypes.bool,
  color: PropTypes.string,
  align: PropTypes.string,
},
  defaultProps = {
    primitive: 'p',
    size: 'medium',
    align: 'left',
    color: colors.gray,
  };

const Wrapper = styled(props => {
  return React.createElement(props.primitive, props, props.children);
})`
  line-height: ${({ fontStyle }) => fontStyle.lineHeight};
  font-family: dunbar-text, Helvetica, sans-serif;
  font-size: ${({ fontStyle }) => fontStyle.fontSize};
  letter-spacing: ${({ fontStyle }) => fontStyle.letterSpacing};
  font-weight: ${({ $bold }) => $bold ? 'bold' : 'normal'};
  text-align: ${({ align }) => align};
  color: ${({ color }) => color ? color : colors.gray};
  margin: 0;
  text-decoration: none;

  &::marker {
    color: ${colors.babyBlue}
  }
`;

const Body = (props) => {
  const {
    children,
    size,
    viewport,
    bold,
    ...rest
  } = props;

  const fontStyle = bodyConfig[size][viewport];

  return (
    <Wrapper
      {...rest}
      $bold={bold}
      size={size}
      fontStyle={{ ...fontStyle}}>
      {children}
    </Wrapper>
  )
};

Body.propTypes = propTypes;
Body.defaultProps = defaultProps;
export default withManager(Body);