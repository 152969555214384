import {
  call,
  put
} from "redux-saga/effects";
import ActionTypes from "../actionTypes";

export function* getProfile(action) {

  try {
    const { Item } = yield call(() => {
      const { id } = action.params
      return global.API.get('PROFILE_BY_ID', {
        routeParams: {
          id
        }
      })
    });

    yield put({
      id: action.params.id,
      type: ActionTypes.profile.getOne.SUCCESS,
      response: Item
    });

  } catch (error) {
    yield put({
      type: ActionTypes.profile.getOne.ERROR,
      error
    });
  }
};

export function* getFeatured(action) {
  const { id } = action.params
  try {
    const { Items } = yield call(() => {
      return global.API.get('PROFILE_FEATURED', {
        routeParams: {
          id
        }
      })
    });


    yield put({
      id: action.params.id,
      type: ActionTypes.profile.getFeatured.SUCCESS,
      response: Items
    });

  } catch (error) {
    yield put({
      type: ActionTypes.profile.getFeatured.ERROR,
      error
    });
  }
};

export function* searchProfiles(action) {
  const { query } = action;  

  try {
    let { Items } = yield call(() => {

    return global.API.get('PROFILE_SEARCH', {
          query: query ? query : ''
        })
      })

    yield put({
      type: ActionTypes.profile.search.SUCCESS,
      response: Items
    });

  } catch (error) {
    yield put({
      type: ActionTypes.profile.search.ERROR,
      error
    });
  }
};