  /**
   * This validation function could probably be moved to input component
   * Validates that there is a Name
   * Validates that the password contains an Upper, Lower and a number
   * Validates that the password matches the confirm password field
   * Validates that the email is in the proper format. the actual address will be confirmed via registration email
   * Validates a user group was selected
   */
   export const validateInputs = (inputs, type, errorCb) => {
    let canContinue = true;

    if (!inputs){
      errorCb('all')
    return false
    }
    const { password, confirmPass, name, email } = inputs;
    const uppercase = /[A-Z]/.test(password);
    const lowercase = /[a-z]/.test(password);
    const number = /[0-9]/.test(password);
    const emailAddress = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);


    if(!name && type === 'signUp') {
      errorCb('name', 'A name is required to continue.')
      canContinue = false
    }
    if(!(uppercase && lowercase && number && password.length > 7)) {
      errorCb('password', 'Password does not meet requirements, please try again.')
      canContinue = false
    }
    if (password !== confirmPass && type === 'signUp') {
      canContinue = false
      errorCb('confirmPass', 'The passwords provided do not match.')
    };
    if (!emailAddress) {
      canContinue = false
      errorCb('email', 'Please provide an email address.')
    };

    return canContinue
  }

export const validateMessageEntertainerInputs = (input, errorCb) => {
  let formIsValid = true;
    if (!input){
      errorCb('all')
      return false;
    }
    const { name, email, dates, message } = input;
    const emailAddress = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

    if (!name) {
      formIsValid = false;
      errorCb('name', 'A name is required to continue.')
    }
    if (!emailAddress) {
      formIsValid = false;
      errorCb('email', 'Please provide an email address.')
    }
    if (!dates) {
      formIsValid = false;
      errorCb('dates', 'Please enter a date. If dates are unknown, type "N/A".');
    }
    if (!message) {
      formIsValid = false;
      errorCb('message', 'A message is required for sending.');
    }

    return formIsValid;
}

export const validateSubmitReviewInputs = (input, rating, errorCb) => {
  let formIsValid = true;
    if (!input && !rating){
      errorCb('all')
      return false;
    }
    const { message } = input;

    if (!rating) {
      formIsValid = false;
      errorCb('rating');
    }

    if (!message) {
      formIsValid = false;
      errorCb('message');
    }

    return formIsValid;
}

export const validateUrl = (url) => {
  const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
  return regex.test(url);
};
